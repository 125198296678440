/**
 * This object must be put here otherwise the js bundler will fail
 * due to a loop between currencyUtils and localeUtils.
 */
const currenciesSymbols = {
  CDF: { code: "CDF", symbol: "CDF" },
  CHF: { code: "CHF", symbol: "CHF" },
  DZD: { code: "DZD", symbol: "DZD" },
  EGP: { code: "EGP", symbol: "EGP" },
  EUR: { code: "EUR", symbol: "€", position: "postfix" },
  GBP: { code: "GBP", symbol: "£", position: "prefix" },
  GNF: { code: "GNF", symbol: "FG" },
  HUF: { code: "HUF", symbol: "Ft" },
  JPY: { code: "JPY", symbol: "¥" },
  MAD: { code: "MAD", symbol: "MAD" },
  MGA: { code: "MGA", symbol: "MGA" },
  MXN: { code: "MXN", symbol: "$" },
  PHP: { code: "PHP", symbol: "₱" },
  RMB: { code: "RMB", symbol: "¥" },
  RUB: { code: "RUB", symbol: "₽" },
  TND: { code: "TND", symbol: "TND" },
  USD: { code: "USD", symbol: "$", position: "prefix" },
  XAF: { code: "XAF", symbol: "F.CFA" },
  XOF: { code: "XOF", symbol: "F.CFA" },
  XPF: { code: "XPF", symbol: "F" },
}

const defaultLocaleCode = "en-EU"

const defaultLocale = {
  delimiters: {
    thousands: ",",
    decimal: ".",
  },
  decimalPlace: 2,
  currency: currenciesSymbols.EUR,
}

/**
 * Formatted following BCP 47 language tags
 */
const localesParameters = {
  "de-DE": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "es-ES": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    useGrouping: true,
  },
  "en-GB": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.GBP,
    decimalPlace: 2,
  },
  "en-US": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.USD,
    decimalPlace: 2,
    dateFormat: "MM-DD-YYYY",
  },
  "en-EU": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "en-BE": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "en-EI": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.EUR,
    decimalPlace: 2,
  },
  "en-LT": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    dateFormat: "YYYY-MM-DD",
  },
  "lt-LT": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    dateFormat: "YYYY-MM-DD",
  },
  "fr-FR": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    weekStartDay: 1,
    dateFormat: "DD/MM/YYYY",
  },
  "fr-TN": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.TND,
    decimalPlace: 3,
  },
  "en-FR": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    weekStartDay: 1,
    dateFormat: "DD/MM/YYYY",
  },
  "fr-BE": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
    weekStartDay: 1,
    dateFormat: "DD/MM/YYYY",
  },
  "fr-GN": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.GNF,
    decimalPlace: 2,
  },
  "fr-CG": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.CDF,
    decimalPlace: 2,
  },
  "fr-PF": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.XPF,
    decimalPlace: 0,
  },
  "it-IT": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "nl-NL": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "nl-BE": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    decimalPlace: 2,
    currency: currenciesSymbols.EUR,
  },
  "ru-RU": {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    currency: currenciesSymbols.RUB,
    decimalPlace: 2,
  },
  "fr-CH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.CHF,
    decimalPlace: 2,
  },
  "it-CH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.CHF,
    decimalPlace: 2,
  },
  "de-CH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.CHF,
    decimalPlace: 2,
  },
  "en-CH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.CHF,
    decimalPlace: 2,
  },
  "hu-HU": {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    currency: currenciesSymbols.HUF,
    decimalPlace: 2,
  },
  "en-HU": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.HUF,
    decimalPlace: 2,
  },
  "fr-HU": {
    delimiters: {
      thousands: " ",
      decimal: ",",
    },
    currency: currenciesSymbols.HUF,
    decimalPlace: 2,
  },
  "en-PH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.PHP,
    decimalPlace: 2,
  },
  "es-PH": {
    delimiters: {
      thousands: ",",
      decimal: ".",
    },
    currency: currenciesSymbols.PHP,
    decimalPlace: 2,
  },
  "fr-MG": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.MGA,
    decimalPlace: 2,
  },
  "ar-DZ": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.DZD,
    decimalPlace: 2,
    rtl: true,
  },
  "ar-TN": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.TND,
    decimalPlace: 3,
    rtl: true,
  },
  "ar-EG": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.EGP,
    decimalPlace: 2,
    rtl: true,
  },
  "ar-MA": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.MAD,
    decimalPlace: 2,
    rtl: true,
  },
  "fr-MA": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.MAD,
    decimalPlace: 2,
    rtl: false,
  },
  "mx-MX": {
    delimiters: {
      thousands: ".",
      decimal: ",",
    },
    currency: currenciesSymbols.MXN,
    decimalPlace: 2,
    rtl: false,
  },
}

/**
 * @param {string} locale A locale following the BCP 47 language tags format. For instance fr-FR (language subtag-region subtag).
 * @returns The region code (subtag) of the locale. In the example, it would be FR.
 */
function getLocaleRegionCode(locale) {
  if (!locale || typeof locale !== "string") return
  if (locale.indexOf("-") === -1) return locale
  return locale.split("-")?.[1]
}

/**
 * @param {string} locale
 * @returns An object describing the parameters applicable for the given locale.
 */
function getLocaleParameters(locale) {
  const localeParameters = localesParameters[locale] || defaultLocale
  if (localeParameters) localeParameters.decimalPlace = localeParameters.decimalPlace ?? 2
  return localeParameters
}

function getDecimalSymbol(locale) {
  return getLocaleParameters(locale).delimiters?.decimal
}

/**
 * @param {string} locale In the form "fr-FR"
 * @returns Returns the code of the currency corresponding to the locale, for instance "EUR".
 */
function getCurrencyCode(locale) {
  return getLocaleParameters(locale).currency.code
}

export {
  currenciesSymbols,
  defaultLocale,
  defaultLocaleCode,
  getCurrencyCode,
  getDecimalSymbol,
  getLocaleParameters,
  getLocaleRegionCode,
  localesParameters,
}
