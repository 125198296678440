import axios from "axios"
import debounce from "lodash.debounce"

import { getLocale, loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { isOffline } from "@/_services/offlineService"
import { getOptions } from "@/_services/userConfiguration"
import { isTenantUseStandard, localStorageKeys } from "@/_services/utils"

const state = {
  listCache: {},
  debouncedListNames: [],
  // When getListsValues is called very closely after getList with the handleLoaded argument, with the same list(s),
  // it must wait for handleLoaded to be called by fetchList.
  // This variable allows getListsValues to know whether or not it must wait.
  listPromises: {},
}

function registerList(list) {
  list.labels = {}
  list.items = {}

  for (let i = 0; i < list.values?.length; i++) {
    let item = list.values[i]

    if (typeof item !== "object") {
      item = list.values[i] = { value: item }
    }

    if (item.label && item.isTranslated !== false) item.label = loc(item.label)
    list.labels[item.value] = item.label || item.value
    list.items[item.value] = item
    if (!item.label) list.values[i].label = item.value
  }

  state.listCache[list.name] = list
}

function reset({ itemsToReset = [] } = {}) {
  const nbOfItemsToReset = itemsToReset.length
  if (nbOfItemsToReset) {
    for (let i = 0; i < nbOfItemsToReset; i++) {
      deregister(itemsToReset[i])
    }
  } else {
    state.listCache = {}
  }
}

function deregister(name) {
  delete state.listCache[name]
  localStorage.removeItem(`${localStorageKeys.OFFLINE_MODE.LIST_PREFIX}${name}`)
}

function deregisterAll() {
  state.listCache = {}
  for (let i = 0; i < localStorage.length; i++) {
    const localStorageKey = localStorage.key(i)
    if (localStorageKey.startsWith(localStorageKeys.OFFLINE_MODE.LIST_PREFIX)) {
      localStorage.removeItem(localStorageKey)
    }
  }
}

async function fetchList(url) {
  const isDirectCall = url

  let listsName = []
  if (!isDirectCall) {
    listsName = [...state.debouncedListNames]
    state.debouncedListNames = []
    url = `/api/script/lists?valuesOnly=true&locale=${getLocale()}&lists=${listsName.join(",")}`
  }

  axios
    .get(url)
    .then(({ data }) => {
      const loadingLists = []
      if (isDirectCall && Array.isArray(data)) {
        // when data is an array, we assume it is a list of values
        const loadingList = state.listCache[url]
        if (loadingList?.handleLoadedCallbacks) loadingLists.push(loadingList)
        registerList({ name: url, values: data })
      } else {
        // DO NOT CHANGE ANYTHING UNLESS YOU KNOW WHAT YOU ARE DOING !!
        // here this is tricky because in case of list we can have lots of hooks to call
        // first replace all loading list (with callbacks) with their loaded version (without callbacks)
        for (let i = 0; i < data.length; i++) {
          const listName = listsName[i]
          const values = data[i]
          const loadingList = state.listCache[listName]
          if (loadingList?.handleLoadedCallbacks) loadingLists.push(loadingList)

          const list = { name: listName, values }
          if (values[0] === "ERROR") {
            displayError(loc`List not found ${listName}`)
            list.values = []
          }

          registerList(list)
        }
      }

      // then execute callbacks
      for (const loadingList of loadingLists) {
        const loadedList = state.listCache[loadingList.name]
        loadingList.handleLoadedCallbacks.forEach(handleLoaded => handleLoaded(loadedList))
        if (state.listPromises[loadingList.name]) state.listPromises[loadingList.name].resolve()
      }
    })
    .catch(error => {
      if (error.response?.status === 499) {
        addNotification(loc(error.response?.data?.message), "warning")
      } else {
        addOops(error)
      }
    })
}

async function fetchListsSync() {
  fetchList()
}

const fetchListsDebounced = debounce(fetchListsSync, 300)

function getList(name, handleLoaded, options = {}) {
  const sortLists = getOptions("sortLists")
  if (Array.isArray(name)) {
    if (["string", "number"].includes(typeof name[0])) name = name.map(value => ({ value }))
    name.forEach(it => {
      if (!it.label) it.label = it.value
    })
    return { values: name }
  }
  if (typeof name !== "string") {
    addOops("Not a list name")
    return
  }

  let list = []

  if (state.listCache[name]) {
    list = state.listCache[name]
    if (handleLoaded && list.handleLoadedCallbacks) {
      // list is loading, add callback
      if (list.handleLoadedCallbacks?.length < 100) {
        // 100 max in case there is an infinite loop caused by some bug
        list.handleLoadedCallbacks.push(handleLoaded)
      }
    }
  } else {
    registerList({
      name,
      values: [],
      handleLoadedCallbacks: (handleLoaded && [handleLoaded]) || [],
      promise: new Promise(resolve => (state.listPromises[name] = { resolve })),
    })

    if (name.startsWith("/")) {
      fetchList(name)
    } else {
      state.debouncedListNames.push(name)
      fetchListsDebounced()
    }
    list = state.listCache[name]
  }

  if (options?.sorted || sortLists) {
    list.values?.sort((a, b) => {
      return (a.isTranslated === false ? a.label : loc(a.label))?.localeCompare(b.isTranslated === false ? b.label : loc(b.label))
    })
  }

  return list
}

function getValues(name, handleLoaded, options = {}) {
  const list = getList(name, handleLoaded, options)
  if (list) return list.values
  return undefined
}

function getRichValues(name, handleLoaded) {
  const list = getList(name, handleLoaded)
  if (list) return list.values.map(it => ({ value: it.value, label: it.label + " (" + it.value + ")" }))
  return undefined
}

function getLabel(listName, value, handleLoaded) {
  if (!value) return value
  if (Array.isArray(listName)) {
    const array = listName
    if (array[0]?.value) {
      let item = array.find(it => it.value === value)
      return item?.label || value
    } else {
      return value
    }
  }
  const list = getList(listName, handleLoaded)
  return list ? list.labels[value] || value : value
}

function getRichLabel(listName, value, handleLoaded) {
  if (!value) return value
  if (Array.isArray(listName)) {
    const array = listName
    if (array[0]?.value) {
      let item = array.find(it => it.value === value)
      return item?.label ? item.label + " (" + value + ")" : value
    } else {
      return value
    }
  }
  const list = getList(listName, handleLoaded)
  return list?.labels[value] ? list.labels[value] + " (" + value + ")" : value
}

function getItem(listName, value, handleLoaded) {
  if (!value) return value
  const list = getList(listName, handleLoaded)
  return list ? list.items[value] || value : value
}

function getListFromLocalStorage(name) {
  try {
    return JSON.parse(localStorage.getItem(`${localStorageKeys.OFFLINE_MODE.LIST_PREFIX}${name}`))
  } catch (err) {
    console.log(err)
    return {}
  }
}

function storeLocallyForOfflineUse(list) {
  return localStorage.setItem(`${localStorageKeys.OFFLINE_MODE.LIST_PREFIX}${list.name}`, JSON.stringify(list))
}

/**
 * This function does the same as getList but without using callbacks :
 * - getList is more suited for use in this app where we can trigger a new render when the callbacks are called
 * - getListsValues is more suited for use withing implementation scripts
 * @param {string} listNames The names of the lists to fetch, separated by a comma
 * @returns A a list of values or an array of such depending on whether one or several are provided.
 */
async function getListsValues(listNames, options) {
  const { sorted, storeForOfflineUse } = options || {}
  listNames = listNames.split(",")

  const listsValues = []
  const listsToFetch = []
  for (const listName of listNames) {
    if (isOffline()) {
      const storedList = getListFromLocalStorage(listName)
      if (storedList) {
        listsValues.push(storedList.values)
        continue
      }
    }

    if (state.listCache[listName]) {
      if (state.listCache[listName].promise) {
        await state.listCache[listName].promise
      }
      listsValues.push(state.listCache[listName].values)
    } else {
      listsToFetch.push(listName)
      listsValues.push(listName)
    }
  }

  const sortLists = getOptions("sortLists")
  if (listsToFetch.length) {
    const { data } = await axios.get(`/api/script/lists?valuesOnly=true&locale=${getLocale()}&lists=${listsToFetch.join(",")}`)
    for (const [index, listName] of listsToFetch.entries()) {
      const listValues = data[index]

      const listIndex = listsValues.findIndex(list => list === listName)
      if (listIndex >= 0) {
        for (const listValue of listValues) {
          if (!listValue.label) continue

          listValue.label = listValue.isTranslated === false ? listValue.label : loc(listValue.label)
          listValue.labelValue = `${listValue.label} (${listValue.value})`
        }

        if (sorted || sortLists) listValues?.sort((a, b) => a.label?.localeCompare(b.label))

        listsValues[listIndex] = listValues
        const list = { name: listName, values: listValues }
        if (listValues[0] === "ERROR") {
          displayError(loc`List not found ${listName}`)
          list.values = []
        }

        registerList(list)

        if (storeForOfflineUse) storeLocallyForOfflineUse(list)
      }
    }
  }

  return listNames.length === 1 ? listsValues[0] : listsValues
}

function displayError(message) {
  // this is experimental: support a tenant with useStandard false, meaning we do not have any list by default
  // in that situation we don't want popping addOops() message
  if (isTenantUseStandard()) {
    addOops(message)
  } else {
    console.error(message)
  }
}

async function getListValueLabel(listName, value) {
  return (await getListsValues(listName)).find(listItem => listItem.value === value)?.label
}

export {
  deregisterAll,
  deregister as deregisterList,
  getItem,
  getLabel,
  getList,
  getListsValues,
  getListValueLabel,
  getRichLabel,
  getRichValues,
  getValues,
  reset as resetLists,
}
