import React, { PureComponent } from "react"

import FormInput from "@/_components/FormInput"

class DateEditor extends PureComponent {
  handleChange = patch => {
    this.props.onChange(patch.value)
  }

  render() {
    const { value } = this.props
    return <FormInput inArray type="date" obj={{ value }} field="value" onSetState={this.handleChange} />
  }
}

export default DateEditor
