/**
 * @param {string} xmlInput
 * @param {object} options
 * @returns Returns a nicely looking XML string.
 */
function formatXml(xmlInput, options = {}) {
  const stringTimesN = (n, char) => Array(n + 1).join(char)

  const {
    indent: indentOption = 2,
    newline: newlineOption = `
`,
  } = options
  const indentString = stringTimesN(indentOption, " ")

  let formatted = ""
  const regex = /(>)(<)(\/*)/g
  const xml = xmlInput.replace(regex, `$1${newlineOption}$2$3`)
  let pad = 0
  xml.split(/\r?\n/).forEach(l => {
    const line = l.trim()

    let indent = 0
    if (line.match(/.+<\/\w[^>]*>$/)) {
      indent = 0
    } else if (line.match(/^<\/\w/)) {
      if (pad !== 0) pad -= 1
    } else if (line.match(/^<\w([^>]*[^/])?>.*$/)) {
      indent = 1
    } else {
      indent = 0
    }

    const padding = stringTimesN(pad, indentString)
    formatted += padding + line + newlineOption
    pad += indent
  })

  return formatted.trim()
}

export { formatXml }
