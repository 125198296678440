import get from "lodash.get"
import { YYYYMMDD } from "./dateUtils.mjs"
import { flattenObject } from "./objectUtils.mjs"

/**
 * Takes a URL and an object as input, replaces URL path params with corresponding values from that object, URI encodes the values.
 * @param {string} url
 * @param {object} params
 * @returns {string} The modified URL
 */
function replaceUrlPathParams(url, params = {}) {
  if (!url.includes("{") && !url.includes(":")) return url

  let [newUrl, query] = url.split("?")

  const flatParams = flattenObject(params)
  for (const key in flatParams) {
    const value = encodeURIComponent(flatParams[key])
    newUrl = newUrl.replaceAll(`:${key}`, value).replaceAll(`{${key}}`, value)
  }

  if (query) return `${newUrl}?${query}`
  return newUrl
}

/**
 * Takes a URL and an object as input, checks if the URL contains query parameters and if so, replaces or removes them based on the provided object.
 * @param {string} url
 * @param {object} params
 * @returns {string} The modified URL
 */
function replaceOrRemoveUrlQueryParams(url, params = {}) {
  if (!url.includes("{") && !url.includes(":")) return url

  const [newUrl, queryString] = url.split("?")
  if (!queryString) return newUrl

  const newQueryString = []
  for (const queryParam of queryString.split("&")) {
    let [key, value] = queryParam.split("=")
    if (!value) continue

    if (value?.includes("{")) {
      value = value.replace("{", "").replace("}", "")
      value = get(params, value)
    }

    if (value?.includes(":")) {
      value = value.replace(":", "")
      value = get(params, value)
    }

    if (value) newQueryString.push(`${key}=${value}`)
  }

  if (newQueryString.length) return `${newUrl}?${newQueryString.join("&")}`
  return newUrl
}

/**
 * Takes a URL and an object as input, replaces URL path params with corresponding values from that object, URI encodes the values,
 * checks if the URL contains query parameters and if so, replaces or removes them based on the provided object.
 * @param {string} url
 * @param {object} params
 * @returns {string} The modified URL
 */
export function replaceUrlParams(url, params) {
  return replaceOrRemoveUrlQueryParams(replaceUrlPathParams(url, params), params)
}

/**
 * Takes a URL string, extracts the query parameters from it and returns them as an object.
 * @param {string} url A string representing a URL that may contain query parameters.
 * @returns {object} An object representing the query parameters in the given URL.
 */
export function getUrlQueryParams(url) {
  const params = {}
  if (!url) return params

  const queryString = url.split("?")[1]
  if (!queryString) return params

  for (let param of queryString.split("&")) {
    let [key, value] = param.split("=")

    key = decodeURIComponent(key)
    value = value.length > 1 ? decodeURIComponent(value) : ""

    if (value === "true") value = true
    else if (value === "false") value = false

    params[key] = value
  }

  return params
}

/**
 * Converts an object to a query string.
 * @param {Object} params - The input object to be transformed.
 * @returns {string} - The resulting query string.
 */
export function toQueryString(params) {
  const fields = Object.keys(params || {})
  if (!fields.length) return ""

  const queryString = fields
    .map(field => {
      let value = params[field]

      if (value === undefined || value === null) return
      if (typeof value === "string" && !value.trim()) return
      if (Array.isArray(value) && value.length === 0) return

      if (Array.isArray(params[field])) value = value.map(v => encodeURIComponent(v)).join(",")
      else if (value instanceof Date) value = YYYYMMDD(value)
      else if (typeof value === "string") value = encodeURIComponent(value.trim())

      return `${field}=${value}`
    })
    .filter(param => param)
    .join("&")

  return queryString ? `?${queryString}` : queryString
}
