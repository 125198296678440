import { getUriAndCacheResponse } from "@/_services/cache"
import { addOops } from "@/_services/notification"

export async function getTaxes() {
  let taxes = []
  try {
    taxes = await getUriAndCacheResponse("/api/core/taxes")
  } catch (error) {
    addOops(error)
  }
  return taxes
}

export async function getIndexes() {
  let indexes = []
  try {
    indexes = await getUriAndCacheResponse("/api/core/indexes")
  } catch (error) {
    addOops(error)
  }
  return indexes
}

export async function getFunds() {
  let funds = []
  try {
    funds = await getUriAndCacheResponse("/api/financing/funds")
  } catch (error) {
    addOops(error)
  }
  return funds
}

export async function getCalendars() {
  let calendars = []
  try {
    calendars = await getUriAndCacheResponse("/api/script/runs/calendars")
  } catch (error) {
    addOops(error)
  }
  return calendars
}

export function getBankAccountLabel(bankAccount) {
  if (!bankAccount) return bankAccount
  return `${bankAccount.bankIban} ${bankAccount.name && bankAccount.name !== bankAccount.bankIban ? `(${bankAccount.name})` : ""}`
}

export function toBankAccountOptions(bankAccounts) {
  return (bankAccounts || []).map(bankAccount => ({
    value: bankAccount.bankIban,
    label: getBankAccountLabel(bankAccount),
    data: bankAccount,
  }))
}

export async function getTaxRate(taxCode) {
  const taxes = await getTaxes()
  let rate = taxes?.find(tax => tax.code === taxCode)?.value
  // no rate means no tax
  if (!rate) rate = 0
  return rate
}
