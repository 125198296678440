import axios from "axios"
import firebase from "firebase/app"
import "firebase/messaging"

import consoleService from "@/_services/console"
import { addNotification } from "@/_services/notification"
import { getUsername, hasProfile } from "@/_services/userConfiguration"
import { localStorageKeys } from "@/_services/utils"

const isSupported = firebase.messaging.isSupported()
if (consoleService.showFirebaseLog) console.log("Firebase: firebaseMessaging.isSupported=", isSupported)

let config
async function getConfig() {
  if (config) return config
  config = (await axios.get("/api/notification/client-configuration")).data
  return config
}

let messaging
async function getFirebaseMessaging() {
  if (!isSupported) return
  if (messaging) return messaging

  const firebaseConfig = await getConfig()

  try {
    // use this line to force creating again the app
    // if (firebase.apps.length) await firebase.app().delete()

    messaging = firebase.initializeApp(firebaseConfig).messaging()
    messaging.usePublicVapidKey(firebaseConfig.publicVapidKey)
    return messaging
  } catch (error) {
    console.log(error)
  }
}

async function getFirebaseToken() {
  if (!isSupported) return

  try {
    const messaging = await getFirebaseMessaging()
    if (!messaging) return

    await messaging.requestPermission()
    console.log("Firebase: Notification permission allowed")
    return messaging.getToken()
  } catch (error) {
    if (consoleService.showFirebaseLog) console.error("Firebase: Error occured when requesting notification permission", error)
    throw error
  }
}

export async function subscribe() {
  let token
  try {
    token = await getFirebaseToken()
  } catch (error) {
    console.log(error)
  }
  if (!token) return

  const hasSubscribed = await _subscribe(token)
  return hasSubscribed
}

export default async function firebaseMessaging() {
  try {
    if (!isSupported) return
    if (consoleService.showFirebaseLog) console.log("Firebase: Launch messaging subscribtion")

    const hasSubscribed = await subscribe()
    if (!hasSubscribed) return

    const messaging = await getFirebaseMessaging()
    if (!messaging) return

    messaging.onTokenRefresh(() => {
      if (consoleService.showFirebaseLog) console.log("Firebase: onTokenRefresh")
      messaging
        .getToken()
        .then(token => _subscribe(token))
        .catch(error => console.log("Firebase: Error occured while updating the token", error))
    })

    messaging.onMessage(payload => {
      if (consoleService.showFirebaseLog) console.log("Firebase: onMessage.payload=", payload)
      const { data = {}, notification, fcmOptions = {} } = payload
      const { username, profile } = data

      const sameUsername = username && username === getUsername()
      const sameProfile = profile && hasProfile(profile)

      if (sameUsername || sameProfile) {
        const { link } = fcmOptions
        if (notification) {
          const { title, body, icon, vibrate } = notification
          notify(title, { body, icon, vibrate }, link)
        }
      }
    })
  } catch (error) {
    console.log(error)
  }
}

function notify(title, options, link) {
  if (Notification.permission === "granted") {
    const notification = new Notification(title, options)
    if (link) notification.onclick = () => (window.location.href = link)

    addNotification(`${title} | ${options.body || ""}`)
  }
}

async function _subscribe(token) {
  const username = getUsername()
  if (!username) return

  let user
  try {
    user = (await axios.get(`/api/core/users/${username}?projection=username,isNotificationsDisabled`)).data
  } catch (error) {
    console.error("Firebase: Error getting user details")
  }
  if (!user) return
  if (user.isNotificationsDisabled) return

  const firebaseToken = localStorage.getItem(`${localStorageKeys.FIREBASE_TOKEN_PREFIX}${username}`)
  if (firebaseToken === token) return

  try {
    await axios.post("/api/notification/subscribe", { token })
    localStorage.setItem(`${localStorageKeys.FIREBASE_TOKEN_PREFIX}${username}`, token)
    return true
  } catch (error) {
    console.error("Firebase: Error subscribing user", error)
  }
}

export function resetFirebaseToken() {
  const username = getUsername()
  if (username) localStorage.removeItem(`${localStorageKeys.FIREBASE_TOKEN_PREFIX}${username}`)
}

export function cleanLocalStorage() {
  Object.keys(localStorage).forEach(key => key.startsWith(localStorageKeys.FIREBASE_TOKEN_PREFIX) && localStorage.removeItem(key))
}
