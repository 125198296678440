import React from "react"
import moment from "moment"
import { ControlLabel } from "react-bootstrap"
import FormInput from "./FormInput"
import Datetime from "react-datetime"

class FormInputDatetime extends React.Component {
  constructor(props) {
    super(props)
    const { timeFormat, value } = props

    this.state = {
      timeFormat: timeFormat === true ? "HH:mm" : timeFormat,
      value: this.sanitizeValue(value),
    }
  }

  // Value is sometimes false, sometimes "", sometimes a moment, sometimes a datetime. Either make it either a datetime or false.
  sanitizeValue(value) {
    let valueSanitize = value ? value : false
    return valueSanitize._isAMomentObject && valueSanitize.isValid() ? valueSanitize.toDate() : valueSanitize
  }

  componentDidUpdate(prevProps) {
    const { value, timeFormat } = this.props

    if (timeFormat !== prevProps.timeFormat || value !== prevProps.value) {
      this.setState({
        timeFormat: timeFormat === true ? "HH:mm" : timeFormat,
        value: this.sanitizeValue(value),
      })
    }
  }

  handleHourChange = timeValue => {
    const { value, timeFormat } = this.state
    const { onChange } = this.props

    // If the value doesn't match the intended format, do not update the datetime as it will break and let the user finish his typing
    if (moment(timeValue, timeFormat, true).isValid()) {
      const newDate = moment(value).clone()
      newDate.hour(moment(timeValue, timeFormat).hour())
      newDate.minute(moment(timeValue, timeFormat).minute())

      onChange(newDate)
    }
  }

  render() {
    const { value, timeFormat } = this.state
    const { splitTimeControl, inputProps, ControlLabelWithDebug } = this.props

    if (splitTimeControl) {
      return (
        <>
          <div className="form-input-datetime">
            <div>
              <ControlLabelWithDebug />
              <Datetime {...this.props} timeFormat={false} />
            </div>
            <TimeSelect
              label={splitTimeControl.label || " "}
              timeFormat={timeFormat}
              onChange={this.handleHourChange}
              value={value ? moment(value).format(timeFormat) : false}
              interval={splitTimeControl?.minutesInterval || 30}
              timeSlots={splitTimeControl?.timeSlots || []}
              creatable={splitTimeControl?.creatable || false}
            />
          </div>
        </>
      )
    }

    return <Datetime {...this.props} inputProps={{ ...inputProps, placeholder: `${inputProps.placeholder} ${timeFormat}` }} />
  }
}

export default FormInputDatetime

class TimeSelect extends React.Component {
  constructor(props) {
    super(props)
    const { value, interval, timeSlots } = props

    this.state = {
      value,
      interval,
      timeSlots,
    }
  }

  componentDidUpdate(prevProps) {
    const { value, interval, timeSlots } = this.props

    if (value !== prevProps.value || interval !== prevProps.interval || timeSlots !== prevProps.timeSlots)
      this.setState({ value, interval, timeSlots })
  }

  allHours = () => {
    const { value, interval, timeSlots } = this.state
    const { timeFormat } = this.props

    if (value === false) return []

    const options = []
    const endOfDay = moment().endOf("day")
    const currentTime = moment().startOf("day")
    while (currentTime.isBefore(endOfDay)) {
      // Check if the current time is within any of the provided time slots
      if (
        !timeSlots ||
        !timeSlots.length ||
        timeSlots.some(slot => currentTime.isBetween(moment(slot.from, timeFormat), moment(slot.to, timeFormat), null, "[]"))
      ) {
        const option = currentTime.format(timeFormat)
        options.push({ value: option, label: option })
      }
      currentTime.add(interval, "minutes")
    }
    return options
  }

  handleChange = newValue => {
    const { onChange, timeFormat } = this.props
    const { timeSlots } = this.state
    const newHour = newValue?.value
    if (newHour) {
      const newHourMoment = moment(newHour, timeFormat)
      // If the new value has been manually typed, check if it is a valid hour according to the used timeformat,
      // and also verify if it is within the provided time slots.
      if (
        newHourMoment._isValid &&
        (!timeSlots?.length ||
          timeSlots.some(slot => newHourMoment.isBetween(moment(slot.from, timeFormat), moment(slot.to, timeFormat), null, "[]")))
      ) {
        onChange(newHour)
        this.setState({ value: newHour })
      }
    }
  }

  render() {
    const { value } = this.state
    const { creatable, label } = this.props

    return (
      <div>
        <ControlLabel>{label}</ControlLabel>
        <FormInput onSelect={this.handleChange} creatable={creatable} select={this.allHours()} obj={{ value }} field="value" label="" />
      </div>
    )
  }
}
