import React, { PureComponent } from "react"

import FormInput from "@/_components/FormInput"

class SelectEditor extends PureComponent {
  handleChange = patch => {
    this.props.onChange(patch.value)
  }

  render() {
    const { value, select } = this.props
    return <FormInput inArray select={select} obj={{ value }} field="value" onSetState={this.handleChange} />
  }
}

export default SelectEditor
