import axios from "axios"
import { checkErrors, validationRegExps } from "basikon-common-utils"
import React from "react"
import { Col, Modal, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"

import PersonComponent from "@/person/PersonComponent"
import PersonRoleComponent from "@/person/PersonRoleComponent"
import PersonTypeComponent from "@/person/PersonTypeComponent"

import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { getOptions, getTenant, hasPermission } from "@/_services/userConfiguration"
import { handleAccessibleOnKeyDown } from "@/_services/utils"

class PersonQuickAddModal extends React.Component {
  constructor(props) {
    super(props)

    let roles = props.roles?.map(it => (typeof it === "string" ? { role: it } : it))

    if (props.role && !roles) roles = [{ role: props.role }]

    this.state = {
      person: {
        type: props.type || "C",
        roles,
        registration: props.registrationType,
        isModal: true,
      },
      user: {},
      detailed: props.detailed, // if set to true and the toggle button is hidden then the view is permanently detailed
    }
  }

  handleClose = async isSave => {
    const { person, user } = this.state
    const { onClose, profiles, partnerRegistration, trigram, registrationType, entityName } = this.props
    if (isSave) {
      if (checkErrors(user, this.handleSetUserState) || checkErrors(person, this.handleSetPersonState)) return

      // reuse trigram of parent company
      if (registrationType === "AUT" && trigram && trigram !== "SIR") person.registration = trigram
      if (
        person.registrations?.[0]?.type &&
        person.registration?.substring(0, 3) &&
        person.registrations[0].type !== person.registration.substring(0, 3)
      ) {
        person.registrations[0].type = person.registration.substring(0, 3)
      }

      try {
        let returnedPerson = (await axios.post(`/api/person/${entityName === "Prospect" ? "prospects" : "persons"}`, person)).data
        if (user.username) {
          user.lastName = returnedPerson.lastName
          user.firstName = returnedPerson.firstName
          user.email = returnedPerson.email
          user.profiles = profiles
          user.locale = getLocale()
          user.personRegistration = returnedPerson.registration
          user.partnerRegistration = partnerRegistration
          const syncPersonOption = getOptions("syncPerson")
          const createdUser = await axios.post(`/api/core/users${syncPersonOption !== false ? "?syncPerson=1" : ""}`, user)
          // the script sync-user-person can update potentially the associated person
          if (createdUser.personRegistration && syncPersonOption !== false) {
            returnedPerson = await axios.post(`/api/person/${entityName === "Prospect" ? "prospects" : "persons"}/${createdUser.personRegistration}`)
          }
        }
        onClose(returnedPerson)
      } catch (error) {
        addOops(error)
        return
      }
    }
    onClose()
  }

  handleSetPersonState = patch => {
    if (!patch) return

    const person = { ...this.state.person, ...patch }
    this.setState({ person })
  }

  handleUsernameBlur = async () => {
    const user = { ...this.state.user }
    const { username } = user
    const users = username && (await axios.get("/api/core/users?username=" + username)).data
    user.props_username = !users || users.length === 0 ? null : { errorFormat: loc`Username already exists` }
    if (users) {
      const person = { ...this.state.person }
      const { emailRegExp } = validationRegExps
      if (emailRegExp.test(username) && !person.email) person.email = username
      this.setState({ user, person })
    }
  }

  handleSetUserState = patch => {
    if (!patch) return

    const user = { ...this.state.user, ...patch }
    this.setState({ user })
  }

  render() {
    const { person, user, detailed } = this.state
    const { isUser, entityName, usePersonLayout, useProspectLayout, cardName, descendantRelation } = this.props
    const showTogglePersonButton = usePersonLayout && person.props_toggleButton?.hidden !== true
    const canCreateUser = hasPermission("write /api/core/users")

    return (
      <Modal show={true} onHide={() => this.handleClose()} bsSize="large" backdrop="static" className="person-quick-add-modal">
        <Modal.Header closeButton>
          <Modal.Title>
            {loc`Add`}
            {showTogglePersonButton && (
              <span
                className="switch-accessible-wrapper pull-right"
                onKeyDown={event => {
                  handleAccessibleOnKeyDown({ event, fn: () => this.setState({ detailed: !this.state.detailed }) })
                }}
              >
                <CustomButton
                  pullRight
                  bsSize="xs"
                  bsStyle="primary"
                  className="mr-theme"
                  fill={detailed}
                  onClick={() => this.setState({ detailed: !detailed })}
                  onKeyDown={event => {
                    handleAccessibleOnKeyDown({ event, fn: () => this.setState({ detailed: !detailed }) })
                  }}
                >
                  <i className="icn-xs icn-ellipsis-h" />
                  <span className="hidden-xs ml-5px">{loc(detailed ? "See less" : "See more")}</span>
                </CustomButton>
              </span>
            )}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {isUser !== false && canCreateUser && person.type === "I" && (
            <>
              <Row>
                <Col xs={12} sm={6}>
                  <FormInput
                    obj={user}
                    field="username"
                    placeholder={loc`email or xxx@` + getTenant()}
                    type="username"
                    onSetState={this.handleSetUserState}
                    onBlur={this.handleUsernameBlur}
                    mandatory={isUser}
                  />
                </Col>
              </Row>
              <legend>{loc`Person`}</legend>
            </>
          )}
          <Row>
            <PersonTypeComponent
              colProps={{ xs: 12, sm: 6, md: 3 }}
              person={person}
              onSetState={this.handleSetPersonState}
              disabled={person.registration}
              inModal
            />
            <PersonRoleComponent colProps={{ xs: 12, sm: 6, md: 9 }} person={person} handleSetPersonState={this.handleSetPersonState} />
          </Row>
          <Row>
            <Col xs={12}>
              <PersonComponent
                {...{
                  person,
                  detailed,
                  handleSetPersonState: this.handleSetPersonState,
                  entityName,
                  cardName,
                  usePersonLayout,
                  useProspectLayout,
                  inModal: true,
                  descendantRelation,
                  isProspect: entityName === "Prospect",
                }}
              />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => this.handleClose()}>{loc`Cancel`}</CustomButton>
          <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleClose(true)}>{loc`Save`}</CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonQuickAddModal
