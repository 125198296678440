// source: https://github.com/RokasAnisas/vardas
export default function ltDecline(vardas, linksnis) {
  const linksnioGalūnės = () => {
    switch (linksnis) {
      case 'kilmininkas':
        return [
          {
            vard: 'a',
            link: 'os',
          },
          {
            vard: 'as',
            link: 'o',
          },
          {
            vard: 'ė',
            link: 'ės',
          },
          {
            vard: 'tis',
            link: 'čio',
          },
          {
            vard: 'dis',
            link: 'džio',
          },
          {
            vard: 'is',
            link: 'io',
          },
          {
            vard: 'us',
            link: 'aus',
          },
          {
            vard: 'tys',
            link: 'čio',
          },
          {
            vard: 'dys',
            link: 'džio',
          },
          {
            vard: 'ys',
            link: 'io',
          },
        ];
      case 'galininkas':
        return [
          {
            vard: 'a',
            link: 'ą',
          },
          {
            vard: 'as',
            link: 'ą',
          },
          {
            vard: 'ė',
            link: 'ę',
          },
          {
            vard: 'is',
            link: 'į',
          },
          {
            vard: 'us',
            link: 'ų',
          },
          {
            vard: 'ys',
            link: 'į',
          },
        ];
      case 'naudininkas':
        return [
          {
            vard: 'a',
            link: 'ai',
          },
          {
            vard: 'as',
            link: 'ui',
          },
          {
            vard: 'ė',
            link: 'ei',
          },
          {
            vard: 'tis',
            link: 'čiui',
          },
          {
            vard: 'dis',
            link: 'džiui',
          },
          {
            vard: 'is',
            link: 'iui',
          },
          {
            vard: 'us',
            link: 'ui',
          },
          {
            vard: 'tys',
            link: 'čiui',
          },
          {
            vard: 'dys',
            link: 'džiui',
          },
          {
            vard: 'ys',
            link: 'iui',
          },
        ];
      case 'įnagininkas':
        return [
          {
            vard: 'a',
            link: 'a',
          },
          {
            vard: 'as',
            link: 'u',
          },
          {
            vard: 'ė',
            link: 'e',
          },
          {
            vard: 'tis',
            link: 'čiu',
          },
          {
            vard: 'dis',
            link: 'džiu',
          },
          {
            vard: 'is',
            link: 'iu',
          },
          {
            vard: 'us',
            link: 'umi',
          },
          {
            vard: 'tys',
            link: 'čiu',
          },
          {
            vard: 'dys',
            link: 'džiu',
          },
          {
            vard: 'ys',
            link: 'iu',
          },
        ];
      case 'šauksmininkas':
        return [
          {
            vard: 'a',
            link: 'a',
          },
          {
            vard: 'as',
            link: 'ai',
          },
          {
            vard: 'ė',
            link: 'e',
          },
          {
            vard: 'is',
            link: 'i',
          },
          {
            vard: 'us',
            link: 'au',
          },
          {
            vard: 'ys',
            link: 'y',
          },
        ];
      case 'vietininkas':
        return [
          {
            vard: 'a',
            link: 'oje',
          },
          {
            vard: 'as',
            link: 'e',
          },
          {
            vard: 'ė',
            link: 'ėje',
          },
          {
            vard: 'is',
            link: 'yje',
          },
          {
            vard: 'us',
            link: 'uje',
          },
          {
            vard: 'ys',
            link: 'yje',
          },
        ];
      default:
        return null;
    }
  };

  const vardoLinksnioGalūnės = linksnioGalūnės()?.find(
    galūnės => {
      if (vardas.substr(vardas.length - 3, 3) == galūnės.vard) {
        return galūnės;
      } else if (vardas.substr(vardas.length - 2, 2) == galūnės.vard) {
        return galūnės;
      } else if (vardas.substr(vardas.length - 1, 1) == galūnės.vard) {
        return galūnės;
      } else {
        return undefined;
      }
    }
  );

  if (vardoLinksnioGalūnės) {
    const galunesIlgis = vardoLinksnioGalūnės.vard.length;
    const vardasBeGalunes = vardas.substr(
      0,
      vardas.length - galunesIlgis
    );
    const islinksniuotasVardas = vardasBeGalunes.concat(
      vardoLinksnioGalūnės.link
    );

    return islinksniuotasVardas;
  } else {
    // Vardo Kilmė ne Lietuviška
    return vardas;
  }
}
