import axios from "axios"
import { pruneObject, toQueryString, truncate, YYYYMMDD } from "basikon-common-utils"
import React, { createRef, Suspense } from "react"
import { Grid } from "react-bootstrap"

import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import Card from "@/_components/Card"
import CustomButton from "@/_components/CustomButton"
import EntityTransitionsButton from "@/_components/EntityTransitionsButton"
import LayoutCard from "@/_components/LayoutCard"
import PersonLink from "@/_components/PersonLink"
import Table, { mergeColumns } from "@/_components/Table"

import { getAuthorizationToken } from "@/_services/axios"
import { getEntityMapFunction, handleEntitiesPageRowClick, handleEntitiesPageSelectAll } from "@/_services/entity"
import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { getPayerPayeeValues } from "@/_services/personUtils"
import { fetchPinnedEntities, getPageConfig } from "@/_services/userConfiguration"
import {
  applyPageAdvancedSearchConfig,
  applyPageConfigFields,
  getClientRouteKey,
  getEntities,
  hasQueryParamsChanged,
  mergeQueryParams,
  searchEntities,
  searchParamToObject,
} from "@/_services/utils"

const BillingModal = React.lazy(() => import("@/billing/BillingModal.jsx"))

class InvoicesPage extends React.Component {
  constructor(props) {
    super(props)
    const {
      location: { pathname: clientRoute },
    } = props

    const pageConfigKey = getClientRouteKey(clientRoute) || "InvoicesPage"
    const pageConfig = getPageConfig(pageConfigKey)
    const configColumns = pageConfig?.columns
    const defaultColumns = [
      { title: "Registration", name: "registration", linkTo: "/invoice/{registration}" },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Invoice number", name: "invoiceNumber" },
      { title: "Payable/Receivable", name: "pr", select: "invoicePr" },
      { title: "Type", name: "type", select: "invoiceType" },
      { title: "Payment mode", name: "paymentMode", select: "paymentMode" },
      { title: "Status", name: "status", select: "invoiceStatus", badge: true },
      { title: "Name", name: "name" },
      { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
      { title: "Payer", name: "payer" },
      { title: "Payee", name: "payee" },
      { title: "Person", name: "payerOrPayee" },
      { title: "Amount (excl. tax)", name: "amountExclTax", type: "currency", currencyPath: "currency", excelFormat: "currency" },
      { title: "Amount (incl. tax)", name: "amountInclTax", type: "currency", currencyPath: "currency", excelFormat: "currency" },
      { title: "Due date", name: "dueDate", type: "date" },
      { title: "Payment date", name: "paymentDate", type: "date" },
      { title: "Internal reference", name: "internalReference" },
      { title: "External reference", name: "externalReference" },
      { title: "User", name: "_insertUser" },
      { title: "Date", name: "_updateDate", type: "date" },
      { title: <>&#8205;</>, name: "actions", sortable: false },
    ]
    const columns = mergeColumns(defaultColumns, configColumns)

    this.state = {
      invoices: [],
      entityName: "Invoice",
      pinnedData: [],
      showBillingModal: false,
      billingLoading: false,
      selectedEntities: new Set(),
      transitions: [],
      transitionsLoading: false,
      pageConfigKey,
      pageConfig,
      columns,
      filterFunction: getEntityMapFunction(columns),
      isServerSearch: false,
      paginationParams: null,
    }

    this.preventAutoRefresh = createRef()
  }

  componentDidMount() {
    const { pageConfigKey } = this.state
    this.getInvoices()

    applyPageAdvancedSearchConfig(pageConfigKey, invoiceSearchFields)
  }

  componentDidUpdate(prevProps) {
    if (!this.preventAutoRefresh.current && hasQueryParamsChanged(this.props, prevProps)) {
      this.getInvoices()
    }
  }

  getInvoices = async ({ params, isAdvancedSearch, nextPagination } = {}) => {
    const { location, history } = this.props
    const { pageConfig, columns, entityName, invoices: stateEntities } = this.state
    const queryParams = { ...searchParamToObject(location.search), ...(params || {}) }
    const getEntitiesQueryParams = {
      ...pageConfig?.defaultQuery,
      ...pruneObject(queryParams),
      ...(pageConfig?.query || {}),
      projection: pageConfig?.query?.projection || pageConfig?.projection,
    }
    if (columns.find(it => it.name === "organization.name" && !it.hidden)) {
      getEntitiesQueryParams.include = "organizationName"
    }

    this.setState({ loading: true })

    if (nextPagination) {
      const { data: moreEntities, paginationParams } = await getEntities(entityName, getEntitiesQueryParams, { nextPagination })
      this.setState({ invoices: [...stateEntities, ...moreEntities], paginationParams, loading: false })
      this.preventAutoRefresh.current = true
      delete queryParams.range
      history.replace(`${location.pathname}${toQueryString(queryParams)}`)
      this.preventAutoRefresh.current = false
      return
    }

    const [{ data: invoices, paginationParams }, pinnedData] = await Promise.all([
      getEntities(entityName, getEntitiesQueryParams),
      fetchPinnedEntities({ entityName, queryParams: getEntitiesQueryParams }),
    ])

    this.setState({
      invoices: applyPageConfigFields(pageConfig, invoices),
      paginationParams,
      pinnedData: this.formatTableData(applyPageConfigFields(pageConfig, pinnedData)),
      loading: false,
      selectedEntities: new Set(), // Reset selected entities after getting new entities
      isServerSearch: !!getEntitiesQueryParams.search,
    })

    if (params) {
      this.preventAutoRefresh.current = true
      if (isAdvancedSearch) queryParams.page = 1
      history.replace(`${location.pathname}${mergeQueryParams(location.search, queryParams)}`)
      this.preventAutoRefresh.current = false
    }
  }

  handleExecuteBilling = async form => {
    this.setState({ billingLoading: true })

    try {
      const orgaFilter = form.organizations
        ? `&organizations=${typeof form.organizations === "string" ? form.organizations : form.organizations.join(",")}`
        : ""
      const result = (await axios.get(`/api/script/runs/run-billing?date=${YYYYMMDD(form.date)}${orgaFilter}`)).data
      if (Array.isArray(result)) {
        // old style run-billing
        const invoices = result
        addNotification(loc`${invoices.length} invoice(s) created`)
        if (invoices.length > 0) this.getInvoices(true)
      } else {
        // new style: it's a batch
        const batch = result
        addNotification(loc`${batch.nbTotal || 0} invoice(s) created`)
        if (batch.nbTotal > 0) this.getInvoices(true)
      }
    } catch (error) {
      addOops(error)
    }

    this.setState({ billingLoading: false })
  }

  handleInvoiceDownload = async (e, invoice) => {
    e.preventDefault()
    let documents = (await axios.get(`/api/billing/invoices/${invoice.registration}/documents?type=INVOICE`)).data
    if (documents.length > 0) {
      let token = getAuthorizationToken()
      let invoiceUrl = `/api/billing/invoices/${invoice.registration}/documents/${documents[0]._id}/content?contentDisposition=inline&token=${token}`
      window.open(invoiceUrl)
    }
  }

  handleBillingModalClose = form => {
    if (form?.date) {
      this.handleExecuteBilling(form)
    }
    this.setState({ showBillingModal: false })
  }

  formatTableData = data => {
    return data.map(invoice => {
      let { persons = [] } = invoice

      const payer = persons.find(p => p.role === "PAYER") || {}
      const payee = persons.find(p => p.role === "PAYEE") || {}

      const payerLink = <PersonLink registration={payer.personRegistration}>{truncate(payer.name, 17) || payer.personRegistration}</PersonLink>
      const payeeLink = <PersonLink registration={payee.personRegistration}>{truncate(payee.name, 17) || payee.personRegistration}</PersonLink>

      return {
        ...invoice,
        id: invoice.registration,
        payer: payerLink,
        payee: payeeLink,
        payerOrPayee: invoice.pr === "R" ? payerLink : payeeLink,
        totalBalance: invoice.totalBalance ?? invoice.amountInclTax,
        actions: <ButtonWithTooltip className="icn-pdf-download icn-xs" onClick={e => this.handleInvoiceDownload(e, invoice)} tooltip="Download" />,
      }
    })
  }

  render() {
    const {
      entityName,
      invoices = [],
      selectedEntities,
      transitions = [],
      loading,
      billingLoading,
      transitionsLoading,
      showBillingModal,
      pageConfig,
      columns,
      filterFunction,
      pinnedData,
      isServerSearch,
      paginationParams,
    } = this.state
    const { location, history } = this.props

    const title = loc(pageConfig?.title || "Invoices")
    const queryParams = searchParamToObject(location.search)

    const { filter = "" } = queryParams
    const { entities: filteredInvoices, hasSearchResultsOnHiddenFields } = filter?.trim()
      ? filterFunction(invoices, filter, { isServerSearch })
      : { entities: invoices }

    const action = selectedEntities.size ? (
      <EntityTransitionsButton
        entities={invoices}
        entityName={entityName}
        transitions={transitions}
        selectedEntities={selectedEntities}
        transitionsLoading={transitionsLoading}
        handleSelectAll={async selectedEntities => await handleEntitiesPageSelectAll(entityName, this, selectedEntities)}
      />
    ) : (
      <>
        <CustomButton
          fill
          pullRight
          bsSize="small"
          bsStyle="primary"
          className="inline-flex-center"
          hidden={pageConfig?.addButton?.hidden}
          onClick={() => history.push(pageConfig?.addButton?.linkTo || "/invoice")}
        >
          <i className="icn-plus icn-xs mr-5px" />
          {loc(pageConfig?.addButton?.label || "Add")}
        </CustomButton>

        <CustomButton
          bsStyle="primary"
          bsSize="small"
          pullRight
          loading={billingLoading}
          label={loc`Run billing`}
          onClick={() => this.setState({ showBillingModal: true })}
          hidden={pageConfig?.hideRunBillingButton}
        />
      </>
    )

    const data = this.formatTableData(filteredInvoices)

    return (
      <Grid className="invoices-page">
        {!pageConfig?.hideKpis && (
          <LayoutCard noCard rows={[{ type: "content", props: { getContentOnUrlChange: false, name: "invoices-kpi", noCard: false } }]} />
        )}

        <Card title={title} action={action}>
          <Table
            useSearchRowComponent
            exportDataFileName={title}
            getEntities={this.getInvoices}
            searchFields={invoiceSearchFields}
            showRowsCount
            data={data}
            columns={columns}
            loading={loading}
            selectedEntities={selectedEntities}
            onRowClick={!pageConfig?.transitionsButton?.hidden && (async row => await handleEntitiesPageRowClick(entityName, this, row))}
            pinnedData={pinnedData}
            entityName={entityName}
            hasSearchResultsOnHiddenFields={hasSearchResultsOnHiddenFields}
            pageConfig={pageConfig}
            paginationParams={paginationParams}
          />

          {showBillingModal && (
            <Suspense fallback={null}>
              <BillingModal onClose={this.handleBillingModalClose} billingModalConfig={pageConfig?.billingModalConfig} />
            </Suspense>
          )}
        </Card>
      </Grid>
    )
  }
}

export default InvoicesPage

async function getPersons(search) {
  let persons = await searchEntities("person", search, { roles: "-SALES" })
  return getPayerPayeeValues(
    null,
    persons.map(it => ({ personRegistration: it.registration, person: it })),
    { withOrganizations: persons.length === 0, richValuesList: true },
  )
}

export const invoiceSearchFields = [
  [
    { field: "pr", select: "invoicePr", label: loc`Payable/Receivable`, colProps: { xs: 12, sm: 3 } },
    { field: "registration", colProps: { xs: 12, sm: 3 } },
    { field: "invoiceNumber", colProps: { xs: 12, sm: 3 } },
    { field: "name", regex: true, colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "type", type: "multiple", select: "invoiceType", colProps: { xs: 12, sm: 3 } },
    { field: "status", type: "multiple", select: "invoiceStatus", colProps: { xs: 12, sm: 3 } },
    { field: "paymentMode", type: "multiple", select: "paymentMode", label: loc`Mode`, colProps: { xs: 12, sm: 3 } },
    { field: "paymentCondition", type: "multiple", select: "paymentCondition", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "currency", select: "currency", colProps: { xs: 12, sm: 3 } },
    { field: "internalReference", colProps: { xs: 12, sm: 3 } },
    { field: "externalReference", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "generationDate", type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "issueDate", type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "fromDueDate", type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "toDueDate", type: "date", colProps: { xs: 12, sm: 3 } },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      regex: true,
      colProps: { xs: 12, sm: 3 },
    },
    { field: "payer", select: getPersons, placeholder: "Type to search for a payer", colProps: { xs: 12, sm: 3 } },
    { field: "payee", select: getPersons, placeholder: "Type to search for a payee", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "minAmountExclTax", label: loc`Amount (min)`, type: "currency", colProps: { xs: 12, sm: 3 } },
    { field: "maxAmountExclTax", label: loc`Amount (max)`, type: "currency", colProps: { xs: 12, sm: 3 } },
  ],
]
