import React from "react"
import { Row } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import FormInput from "@/_components/FormInput"

import { loc } from "@/_services/localization"

export default class PersonLegal extends React.Component {
  setCompany = patch => {
    const { person, handleSetPersonState } = this.props
    person.company = person.company || {}
    person.company = {
      ...person.company,
      ...patch,
    }

    handleSetPersonState({ company: person.company })
  }

  render() {
    const { person, readOnly, handleSetPersonState, modelPath, show } = this.props
    const { company } = person || {}

    return (
      <PanelInner collapse={false} title="Legal" className={show ? "" : "d-none"}>
        <Row>
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="legalForm"
            select="legalForm"
            onSetState={handleSetPersonState}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="creationDate"
            label={loc`Date of creation`}
            type="date"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="registrationDate"
            label={loc`Date of registration`}
            type="date"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="shareCapital"
            type="currency"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="registrationCountry"
            select="country"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="registrationPlace"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="registrationInfo"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            label={loc`VAT scheme`}
            field="vatScheme"
            select="vatScheme"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={person}
            field="activityCode"
            select="activityCode"
            onSetState={handleSetPersonState}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            label={loc`NACE code`}
            field="naceCode"
            select="naceCode"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="sectorCode"
            select="sectorCode"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="insuranceNumber"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="legalRepresentative"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="administrativeStatus"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
            select="administrativeStatus"
          />
        </Row>

        <Row>
          <FormInput
            readOnly={readOnly}
            obj={company}
            field="yearOfReference"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="companySize"
            select="companySize"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="nbOfEmployees"
            type="number"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="accountingStandards"
            select="accountingStandards"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="totalBalancesheet"
            type="currency"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="totalTurnover"
            type="currency"
            //TODO add currency props
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="legalProceedingsStatus"
            select="legalStatusCode"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />

          <FormInput
            readOnly={readOnly}
            obj={company}
            field="dateLegalProceeding"
            type="date"
            onSetState={this.setCompany}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />
        </Row>
      </PanelInner>
    )
  }
}
