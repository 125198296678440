import React from "react"
import { Row } from "react-bootstrap"

import PanelInner from "@/_components/PanelInner"
import FormInput from "@/_components/FormInput"

export default class PersonWeb extends React.Component {
  setWeb = patch => {
    const { person, handleSetPersonState } = this.props
    person.web = person.web || {}
    person.web = {
      ...person.web,
      ...patch,
    }

    handleSetPersonState({ web: person.web })
  }

  render() {
    const { person, readOnly, modelPath, show } = this.props
    const { web } = person || {}

    return (
      <PanelInner collapse={false} title="Web" className={show ? "" : "d-none"}>
        <Row>
          <FormInput readOnly={readOnly} obj={web} modelPath={modelPath} field="website" onSetState={this.setWeb} colProps={{ xs: 12, sm: 6 }} />
          <FormInput
            readOnly={readOnly}
            obj={web}
            modelPath={modelPath}
            field="linkedIn"
            label="LinkedIn"
            onSetState={this.setWeb}
            colProps={{ xs: 12, sm: 6 }}
          />
          <FormInput readOnly={readOnly} obj={web} modelPath={modelPath} field="facebook" onSetState={this.setWeb} colProps={{ xs: 12, sm: 6 }} />
          <FormInput readOnly={readOnly} obj={web} modelPath={modelPath} field="instagram" onSetState={this.setWeb} colProps={{ xs: 12, sm: 6 }} />
        </Row>
      </PanelInner>
    )
  }
}
