import React from "react"
import { Col, Modal } from "react-bootstrap"

import FormInput from "@/_components/FormInput"
import CustomButton from "@/_components/CustomButton"
import FormInputExtended from "@/_components/FormInputExtended"
import { getUriAndCacheResponse } from "@/_services/cache"
import { generateRows } from "@/_services/utils"
import { loc } from "@/_services/localization"

const defaultRows = [
  [
    "title",
    "firstName",
    "lastName",
    "secondName",
    "position",
    "phone",
    "mobile",
    "email",
    "linkedIn",
    "facebook",
    "instagram",
    "extension",
    "isDefault",
  ],
]

class PersonContactModal extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      prevContact: { ...props.contact },
      contactsExtensionFields: [],
    }
  }

  componentDidMount() {
    this.getPersonContactsExtensionFields()
  }

  getPersonContactsExtensionFields = async () => {
    const { schema } = await getUriAndCacheResponse("/api/person/persons-schema")

    const contactsExtensionFields = []
    for (let fieldName in schema.contacts[0]) {
      // TODO: Flag extension fields in "{serverRoute}-schema" API to differentiate and avoid hard-coding standard fields
      const standardFields = [
        "isDefault",
        "title",
        "position",
        "firstName",
        "lastName",
        "secondName",
        "phone",
        "mobile",
        "email",
        "linkedIn",
        "facebook",
        "instagram",
        "personRegistration",
      ]
      if (standardFields.includes(fieldName)) continue

      const { formInputProps = {}, colProps } = schema.contacts[0][fieldName]

      contactsExtensionFields.push({ field: fieldName, ...formInputProps, colProps: colProps || formInputProps.colProps || { xs: 12 } })
    }

    this.setState({ contactsExtensionFields })
  }

  getField = col => {
    const { name, colProps, formInputProps, label, hidden } = col
    if (hidden) return
    const { contactsExtensionFields } = this.state
    const { contact, readOnly, modelPath = "", handleSetContactState } = this.props

    const props = {
      label,
      readOnly,
      modelPath,
      key: name,
      field: name,
      obj: contact,
      ...formInputProps,
      onSetState: handleSetContactState,
      colProps: formInputProps?.colProps ?? colProps ?? { xs: 12, sm: 6 },
    }

    if (name === "title") return <FormInput select="personTitle" {...props} />

    if (["firstName", "lastName", "secondName"].includes(name)) return <FormInput {...props} />

    if (name === "position") return <FormInput select="contactPosition" mandatory {...props} />

    if (["phone", "mobile"].includes(name)) return <FormInput type="phone" {...props} />

    if (name === "email") return <FormInput type="email" {...props} />

    if (name === "linkedIn") return <FormInput {...props} label={props.label ?? "LinkedIn"} />

    if (["facebook", "instagram"].includes(name)) return <FormInput {...props} />

    if (name === "extension")
      return contactsExtensionFields.map((formInputProps, key) => (
        <FormInputExtended key={key} {...formInputProps} readOnly={readOnly} obj={contact} onSetState={handleSetContactState} />
      ))

    if (name === "isDefault") return <FormInput placeholder={label} type="checkbox" showLabel={false} {...props} />

    if (typeof name === "string")
      return (
        <Col key={name} {...colProps}>
          {name}
        </Col>
      )

    return null
  }

  handleClose = () => {
    const { prevContact } = this.state
    const { onClose } = this.props
    onClose(prevContact)
  }

  render() {
    const { contact } = this.state
    const { readOnly, rows, modelPath, title = "Contact", handleSetContactState, onSave } = this.props

    const rowsContent = generateRows({
      getField: this.getField,
      rows: rows?.length ? rows : defaultRows,
      entity: contact,
      handleSetState: handleSetContactState,
      modelPath,
      readOnly,
    })

    return (
      <Modal show={true} onHide={this.handleClose} backdrop="static" className="person-contact">
        <Modal.Header closeButton>
          <Modal.Title>{loc(title)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{rowsContent}</Modal.Body>
        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={this.handleClose} label={readOnly ? "Close" : "Cancel"} />
          {!readOnly && <CustomButton bsStyle="primary" bsSize="sm" fill onClick={onSave} label="Save" />}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonContactModal
