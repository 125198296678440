import axios from "axios"
import cloneDeep from "lodash.clonedeep"
import memoize from "lodash.memoize"

async function _get(uri) {
  return (await axios.get(uri)).data
}

const memoizedGet = memoize(_get)

async function get(uri) {
  const result = await memoizedGet(uri)
  return cloneDeep(result) // Clone to avoid cache mutation
}

function reset() {
  return memoizedGet.cache.clear()
}

function remove(uri) {
  return memoizedGet.cache.delete(uri)
}

async function runScript(scriptName) {
  return await get(`/api/script/runs/${scriptName}`)
}

export { get as getUriAndCacheResponse, remove as removeCachedUriResponse, reset as resetCache, runScript as runScriptAndCacheResponse }
