import React from "react"
import { formatDecimal, roundAccurately, coerceStringToJsNumber, finance } from "basikon-common-utils"

import FormInput from "@/_components/FormInput"
import ButtonWithTooltip from "@/_components/ButtonWithTooltip"
import MathSheetComponent from "@/_components/MathSheetComponent"

import { copyToClipboard } from "@/_services/utils"
import { loc } from "@/_services/localization"

const { PMT, RATE } = finance

/**
 * @prop {number}         dimensions      The number of dimensions that the table can have, default to 2, meaning the first row and the first column.
 *                                        Each additional dimension will add a column at the beginning.
 *                                        Ex: table with 3 dimensions
 *                                        |    |    | X1 | X2 |
 *                                        | Y1 | Y2 | V1 | V2 |   To get the value V1 we must match X1, Y1 and Y2
 *                                        | Y3 | Y4 | V3 | V4 |   To get the value V4 we must match X2, Y3 and Y4
 * @prop {[["String"]]}   cellFormats     A table of cell formats to format cells based on their row and col indexes
 *                                        (this prop will probably deprecate other props like colFormat, headerRowFormat, headerColFormat, etc..)
 * Ex: cell formats for a 3 dimensions table
 * [
 *   [      "",       "", "Currency"], // The "currency" format will be applied on this 1st row starting from the third column (rowIndex === 0 && colIndex >= 2)
 *   ["String", "Number", "Currency"], // The "string" format will be applied on the 1st column of all rows starting from the 2nd row (rowIndex > 0 && colIndex = 0)
 * ]                                   // The "number" format will be applied on the 2nd column of all rows starting from the 2nd row (rowIndex > 0 && colIndex = 1)
 *                                     // The "currency" format will be applied on all columns starting from the 3rd column of all rows starting from the 2nd row (rowIndex > 0 && colIndex >= 2)
 */
class NumbersTableComponent extends React.PureComponent {
  state = { isEdited: false }

  handleSetSheetState = patch => {
    const { grid } = patch
    const { field, onSetState } = this.props

    // copy lines, removing the last "Actions" column, and the last line
    const numbersTable = []
    for (const row of grid) {
      const values = []
      for (const col of row) {
        if (typeof col.value === "object" && col.value !== null) continue
        col.value = formatValue(col.value, col.format)
        values.push(col.value)
      }

      numbersTable.push(values)
    }

    onSetState({ [field]: numbersTable })
  }

  handleAction = (action, lineIndex) => {
    const { obj, field, onSetState } = this.props

    const numbersTable = obj[field] || []
    const copyNumbersTable = numbersTable.map(line => [...line]) // Copy array to keep prevState

    if (action === "insertColumn") copyNumbersTable.forEach(line => line.push(0))
    else if (action === "deleteColumn") copyNumbersTable.forEach(line => line.splice(line.length - 1, 1))
    else if (action === "deleteLine") copyNumbersTable.splice(lineIndex, 1)
    else if (action === "duplicateLine") copyNumbersTable.splice(lineIndex, 0, numbersTable[lineIndex])

    onSetState({ [field]: copyNumbersTable })
  }

  handleSetEdited = () => {
    const { isEdited } = this.state
    const { obj, type, field, headerRow, headerCol, onSetState, dimensions = 2 } = this.props

    // Construct empty cells based on dimensions
    const emptyCells = [""]
    for (let d = 2; d < dimensions; d++) emptyCells.push("")

    let items = (obj || {})[field]
    if (!items || items.length === 0) {
      if (headerRow && headerCol) {
        items = [[...emptyCells, ...headerRow]]

        const emptyLine = headerRow.map(() => 0)
        for (const col0 of headerCol) items.push([col0, ...emptyLine])
      } else {
        items = [[...emptyCells], [...emptyCells.map(() => 0)]]
      }
      onSetState({ [field]: items })
    }

    this.setState({ isEdited: !isEdited, display: type })
  }

  pasteMathSheet = () => {
    navigator.clipboard.readText().then(str => {
      let { field, onSetState } = this.props
      if (!str) return
      let lines = str.trimEnd().split("\n")
      let numbersTable = lines.map(line => {
        line = line.split("\t").map(it => coerceStringToJsNumber(it))
        line = line.map(value => {
          if (typeof value === "string") {
            value = value.trim()
            if (value === "null" || value === "NULL") return null
            if (value === "undefined") return undefined
          }
          return value
        })
        return line
      })
      onSetState({ [field]: numbersTable })
    })
  }

  copyMathSheet = grid => {
    const { isEdited } = this.state
    const { options } = this.props

    if (!Array.isArray(grid) || !Array.isArray(grid[0])) {
      copyToClipboard("")
      return
    }

    const { locale } = this.state
    const colMin = 0
    const colMax = grid[0].length - 1 - (isEdited ? 1 : 0)
    const rowMin = 0
    const rowMax = grid.length - 1

    let copiedString = ""
    for (let i = rowMin; i <= rowMax; i++) {
      for (let j = colMin; j <= colMax; j++) {
        const { value, format } = grid[i][j]

        if (value !== null) {
          if (format === "number") copiedString += formatDecimal(value, locale, options?.formatNumberOptions)
          else if (format === "percentage") copiedString += formatDecimal(value, locale, options?.formatPercentageOptions)
          else copiedString += value
        }

        copiedString += j === colMax ? "" : "\t"
      }
      copiedString += i === rowMax ? "" : "\r\n"
    }
    copyToClipboard(copiedString)
  }

  render() {
    let {
      obj,
      field,
      type,
      pmtType,
      periodicity = 30,
      readOnly,
      currency,
      format: defaultDataFormat = "number",
      onSetState,
      headerRowFormat,
      headerColFormat,
      residualValueRate = 0,
      hideEditButton = false,
      hideCopyButton = false,
      hidePasteButton = false,
      hideDeleteButton = false,
      colFormat = [],
      dimensions = 2, // check comment at the top of the component
      cellFormats,
      options = {
        formatNumberOptions: {
          minimumFractionDigits: 3, // default is 3 as it seems to be the usual in the leasing world
        },
        formatPercentageOptions: {
          minimumFractionDigits: 2, // default is 2 as it seems to be the usual in the leasing world
        },
      },
    } = this.props
    const { isEdited, display = type } = this.state
    let coeffDecimalPlaces = options?.formatNumberOptions?.minimumFractionDigits

    let items = (obj || {})[field] || []

    const isTwoSided = items?.[0] && (items[0][0] === "" || items[0][0] === null)
    if (isTwoSided) {
      const nbPeriodInYear = periodicity === 30 ? 12 : periodicity === 90 ? 4 : periodicity === 180 ? 2 : 1

      if (type === "ratesTable" && display === "coefficientsTable") {
        items = items.map((line, lIndex) => {
          if (lIndex === 0) return line
          return line.map((cell, cIndex) => {
            if (cIndex === 0 || cell === null) return cell
            let rate = cell
            let nper = line[0]
            let pv = items[0][cIndex]
            let type = pmtType || 1 // in advance by def
            let pmt = -PMT(rate / nbPeriodInYear, nper, pv, -residualValueRate, type)
            let value = (pmt / pv) * 100 // display value without %
            if (coeffDecimalPlaces) value = roundAccurately(value, coeffDecimalPlaces)
            return value
          })
        })
      } else if (type === "coefficientsTable" && display === "ratesTable") {
        items = items.map((line, lIndex) => {
          if (lIndex === 0) return line
          return line.map((cell, cIndex) => {
            if (cIndex === 0) return cell
            let pmt = (items[0][cIndex] * cell) / 100
            let nper = line[0]
            let pv = items[0][cIndex]
            let type = pmtType || 1 // in advance by def
            return RATE(nper, pmt, -pv, -residualValueRate, type) * nbPeriodInYear
          })
        })
      } else if (type === "costTable") {
        items = items.map((line, index) => (index === 0 ? line : line.map(cell => cell)))
      }
    }

    if (display === "ratesTable" || (!display && type === "ratesTable")) defaultDataFormat = "percentage"

    const grid = items?.map((line, rowIndex) =>
      line.map((cell, colIndex) => {
        let headerFormat = "number"
        const isHeader = (isTwoSided && (rowIndex === 0 || colIndex === 0)) || (!isTwoSided && rowIndex === 0)
        if (isHeader) {
          if (isTwoSided && rowIndex === 0) headerFormat = headerRowFormat || "currency"
          if (isTwoSided && colIndex === 0) headerFormat = headerColFormat || "number"
          if (headerRowFormat?.toLowerCase() === "string" && typeof cell === "string") cell = loc(cell)
        }

        let dataFormat = defaultDataFormat
        if (colFormat?.length > 0) {
          const _colIndex = colIndex >= colFormat.length ? colFormat.length - 1 : colIndex
          const cellColFormat = colFormat[_colIndex] && typeof colFormat[_colIndex] === "string" && colFormat[_colIndex]?.toLowerCase()
          if (["string", "number", "percentage", "date", "boolean", "currency"].includes(cellColFormat)) dataFormat = cellColFormat
        }

        let readOnly = isHeader && !isEdited
        if (!readOnly && !isEdited && dimensions > 2) readOnly = colIndex <= dimensions - 2 // Set the additional dimension columns to readOnly

        let format = getCellFormat(cellFormats, rowIndex, colIndex)
        if (!format) format = isHeader ? headerFormat : dataFormat
        else format = format.toLowerCase()

        cell = formatValue(cell, format)

        return {
          value: cell,
          options: isHeader ? undefined : options,
          className: isHeader && "title1",
          readOnly,
          disabled: !isEdited || readOnly,
          format,
        }
      }),
    )

    // Add grid actions
    if (isEdited && !readOnly) {
      for (let i = 0; i < grid.length; i++) {
        if (i === 0) {
          grid[i].push({
            value: (
              <>
                <i className="text-info icn-plus icn-xs" onClick={() => this.handleAction("insertColumn")} />
                <i className="text-danger icn-minus icn-xs" onClick={() => this.handleAction("deleteColumn")} />
              </>
            ),
            className: "title1",
            readOnly: true,
            textAlign: "right",
          })
        } else {
          grid[i].push({
            readOnly: true,
            value: (
              <>
                <i className="icn-duplicate icn-xs text-info" onClick={() => this.handleAction("duplicateLine", i)} />
                <i className="icn-xmark icn-xs text-danger" onClick={() => this.handleAction("deleteLine", i)} />
              </>
            ),
          })
        }
      }
    }
    return (
      <span className="numbers-table-component">
        {hideCopyButton !== true && items?.length > 0 && <ButtonWithTooltip className="icn-copy icn-xs" onClick={() => this.copyMathSheet(grid)} />}
        {hidePasteButton !== true && isEdited && (
          <ButtonWithTooltip className="icn-paste icn-xs" tooltip={loc`Paste (replace whole table)`} onClick={() => this.pasteMathSheet()} />
        )}
        {!readOnly && !hideEditButton && (
          <ButtonWithTooltip className={"icn-xs " + (isEdited ? "icn-check" : "icn-edit")} onClick={this.handleSetEdited} />
        )}
        {!readOnly && items?.length > 0 && !hideDeleteButton && (
          <ButtonWithTooltip className="icn-xmark icn-xs" onClick={() => onSetState({ [field]: [] })} />
        )}
        {grid && (
          <>
            <div className="pull-right mb-2">
              {pmtType && type !== "costTable" && (
                <FormInput
                  bsSize="xs"
                  buttonGroupClassName="min-h-fit mr-0"
                  inArray
                  button
                  obj={{ display }}
                  field="display"
                  select={[
                    { value: "ratesTable", label: loc`Rate` },
                    { value: "coefficientsTable", label: loc`Coeff` },
                  ]}
                  disabled={isEdited && !readOnly}
                  onSetState={patch => this.setState(patch)}
                />
              )}
            </div>
            <MathSheetComponent sheet={{ grid }} onSetSheetState={this.handleSetSheetState} options={{ formatCurrencyOptions: { currency } }} />
          </>
        )}
      </span>
    )
  }
}

export default NumbersTableComponent

function formatValue(value, format = "number") {
  const isNumber = ["number", "percentage", "currency"].includes(format?.toLowerCase())
  if (isNumber && value !== undefined && value !== null && value !== "") return Number(value) // String => Number
  return value
}

function getCellFormat(cellFormats, rowIndex, colIndex) {
  if (!cellFormats || !Array.isArray(cellFormats)) return
  if (cellFormats[rowIndex]?.[colIndex]) return cellFormats[rowIndex][colIndex]

  let rowCellFormats = cellFormats[rowIndex]
  if (rowCellFormats === undefined) {
    const lastRowIndex = cellFormats.length - 1
    rowCellFormats = cellFormats[lastRowIndex]
  }

  if (!rowCellFormats) return

  let cellFormat = rowCellFormats[colIndex]
  if (cellFormat === undefined) {
    const lastColIndex = rowCellFormats.length - 1
    cellFormat = rowCellFormats[lastColIndex]
  }

  if (cellFormat) return cellFormat
}
