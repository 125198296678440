const state = {
  counter: 0,
  component: null,
}

function register(reactComponent) {
  state.component = reactComponent
}

function unregister() {
  state.component = null
}

function show() {
  state.counter++
  state.component?.setState({ spinnerCounter: state.counter })
}

function hide() {
  state.counter--
  state.component?.setState({ spinnerCounter: state.counter })
}

export { register as registerSpinner, unregister as unregisterSpinner, show as showSpinner, hide as hideSpinner }
