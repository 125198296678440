import React from "react"

import { loc } from "@/_services/localization"

/**
 * The only use case expected for this component is when a user has let the application open
 * while a new version of it has been deployed without keeping old js chunks.
 * In this situation the user might navigate to a page where chunks are lazy-loaded for the first-time.
 * By reloading the app, the code handling the installation of a new version of the app
 * should trigger and properly register the new names of the chunks.
 */

const CHUNK_ERROR = "ChunkLoadError"

export class JsChunksErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      hasChunkLoadError: false,
    }
  }

  static getDerivedStateFromError(error) {
    if (navigator.onLine && error.name === CHUNK_ERROR) return { hasChunkLoadError: true }
  }

  async componentDidCatch(error) {
    if (navigator.onLine && error.name === CHUNK_ERROR) {
      console.error(error)
      console.error(
        "If you see this message and after hard reloading the page you still it, " +
          "please report to us at support@basikon.io the error above contact. " +
          "Sorry for the inconvenience.",
      )
    }
  }

  render() {
    const { children } = this.props
    const { hasChunkLoadError } = this.state

    if (hasChunkLoadError) {
      // our custom CSS files might not have been loaded yet when the error occurs
      // so we need to hard-set some styles
      return (
        <div
          style={{
            fontSize: "16px",
            margin: "30px auto 0",
            textAlign: "center",
          }}
        >
          <p>{loc("You are using an outdated version of the application. Refresh the page to continue.")}</p>
          <button className="btn btn-primary btn-fill" onClick={() => window.location.reload()}>
            {loc("Refresh")}
          </button>
        </div>
      )
    }

    return children
  }
}

export default JsChunksErrorBoundary
