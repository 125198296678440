export default {
  "useLongScale": true,
  "baseSeparator": " y ",
  "unitSeparator": "",
  "base": {
    "0": "cero",
    "1": "uno",
    "2": "dos",
    "3": "tres",
    "4": "cuatro",
    "5": "cinco",
    "6": "seis",
    "7": "siete",
    "8": "ocho",
    "9": "nueve",
    "10": "diez",
    "11": "once",
    "12": "doce",
    "13": "trece",
    "14": "catorce",
    "15": "quince",
    "16": "dieciséis",
    "17": "diecisiete",
    "18": "dieciocho",
    "19": "diecinueve",
    "20": "veinte",
    "21": "veintiuno",
    "22": "veintidós",
    "23": "veintitrés",
    "24": "veinticuatro",
    "25": "veinticinco",
    "26": "veintiséis",
    "27": "veintisiete",
    "28": "veintiocho",
    "29": "veintinueve",
    "30": "treinta",
    "40": "cuarenta",
    "50": "cincuenta",
    "60": "sesenta",
    "70": "setenta",
    "80": "ochenta",
    "90": "noventa",
    "100": "cien",
    "200": "doscientos",
    "300": "trescientos",
    "400": "cuatrocientos",
    "500": "quinientos",
    "600": "seiscientos",
    "700": "setecientos",
    "800": "ochocientos",
    "900": "novecientos",
    "1000": "mil"
  },
  "unitExceptions": {
    "1000000": "un millón",
    "1000000000000": "un billón",
    "1000000000000000000": "un trillón",
    "1000000000000000000000000": "un cuatrillones",
    "1000000000000000000000000000000": "un quintillón",
    "1000000000000000000000000000000000000": "un sextillón",
    "1000000000000000000000000000000000000000000": "un septillón",
    "1000000000000000000000000000000000000000000000000": "un octillón",
    "1000000000000000000000000000000000000000000000000000000": "un nonillón",
    "1000000000000000000000000000000000000000000000000000000000000": "un decillón",
    "1000000000000000000000000000000000000000000000000000000000000000000": "un undecillón",
    "1000000000000000000000000000000000000000000000000000000000000000000000000": "un duodecillón",
    "1000000000000000000000000000000000000000000000000000000000000000000000000000000": "un tredecillón",
    "1000000000000000000000000000000000000000000000000000000000000000000000000000000000000": "un cuatordecillón",
    "1000000000000000000000000000000000000000000000000000000000000000000000000000000000000000000": "un quindecillón"
  },
  "units": [
    {
      "singular": "ciento",
      "useBaseInstead": true,
      "useBaseException": [1],
      "useBaseExceptionWhenNoTrailingNumbers": true
    },
    {
      "singular": "mil",
      "avoidPrefixException": [1]
    },
    {
      "singular": "millón",
      "plural": "millones"
    },
    {
      "singular": "billón",
      "plural": "billones"
    },
    {
      "singular": "trillón",
      "plural": "trillones"
    },
    {
      "singular": "cuatrillón",
      "plural": "cuatrillones"
    },
    {
      "singular": "quintillón",
      "plural": "quintillones"
    },
    {
      "singular": "sextillón",
      "plural": "sextillones"
    },
    {
      "singular": "septillón",
      "plural": "septillones"
    },
    {
      "singular": "octillón",
      "plural": "octillones"
    },
    {
      "singular": "nonillón",
      "plural": "nonillones"
    },
    {
      "singular": "decillón",
      "plural": "decillones"
    },
    {
      "singular": "undecillón",
      "plural": "undecillones"
    },
    {
      "singular": "duodecillón",
      "plural": "duodecillones"
    },
    {
      "singular": "tredecillón",
      "plural": "tredecillones"
    },
    {
      "singular": "cuatrodecillón",
      "plural": "cuatrodecillones"
    },
    {
      "singular": "quindecillón",
      "plural": "quindecillones"
    }
  ]
}
