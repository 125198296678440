import FormInput from "@/_components/FormInput"
import React, { Suspense } from "react"

const ScanIbanModal = React.lazy(() => import("person/ScanIbanModal"))

let __inputIndex = 0
class FormInputScanIban extends React.Component {
  state = {
    ibanInputName: "_iban-" + __inputIndex++,
    showScanIbanModal: null,
  }

  handleScanAction = () => {
    const { ibanInputName } = this.state
    document.getElementById(ibanInputName).click()
  }

  handleDocumentUpload = async event => {
    const files = Array.from(event.target.files)
    const file = files?.[0]
    event.target.value = null
    if (!file) return
    this.setState({ showScanIbanModal: file })
  }

  handleScanIbanModalClose = bankInfo => {
    const { ibanInputName, showScanIbanModal } = this.state
    const { field, onSetState, attachIban } = this.props
    if (bankInfo) {
      bankInfo[field] = bankInfo.bankIban
      onSetState(bankInfo)
      attachIban?.(showScanIbanModal)
    }
    document.getElementById(ibanInputName).value = ""
    this.setState({ showScanIbanModal: null })
  }

  render() {
    const { ibanInputName, showScanIbanModal } = this.state
    const {
      debounce,
      readOnly,
      obj,
      creatable = true,
      colProps,
      field,
      label,
      modelPath,
      onSetState,
      select,
      inArray,
      placeholder,
      showMandatoryHint,
      showFormattedIban,
    } = this.props

    return (
      <>
        <FormInput
          showCopy
          obj={obj}
          type="iban"
          field={field}
          label={label}
          select={select}
          inArray={inArray}
          readOnly={readOnly}
          debounce={debounce}
          colProps={colProps}
          creatable={creatable}
          modelPath={modelPath}
          onSetState={onSetState}
          actionIcon="icn-camera icn-sm"
          actionTooltip="Extract IBAN from file"
          actionClassName="c-pointer"
          showMandatoryHint={showMandatoryHint}
          showFormattedIban={showFormattedIban}
          action={!readOnly && creatable && this.handleScanAction}
          placeholder={placeholder}
        />
        {showScanIbanModal && (
          <Suspense fallback={null}>
            <ScanIbanModal file={showScanIbanModal} bankAccount={obj} onClose={this.handleScanIbanModalClose} />
          </Suspense>
        )}
        <input id={ibanInputName} type="file" onChange={e => this.handleDocumentUpload(e)} style={{ display: "none" }} />
      </>
    )
  }
}

export default FormInputScanIban
