import React from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import { loc } from "@/_services/localization"
import { getUsername } from "@/_services/userConfiguration"

class DebugPopover extends React.Component {
  state = {
    showSelectDebug: false,
    selectName: null,
  }

  componentDidMount = () => {
    const { select, debugScriptList } = this.props
    const selectName = typeof select === "string" ? select : debugScriptList
    this.setState({ showSelectDebug: select && selectName, selectName })
  }

  handleConfigLink = event => {
    const { selectName, showSelectDebug } = this.state
    const { debugUrlQuery, debugScriptList, onClick, onMouseDown, history } = this.props

    if (typeof onClick === "function") return onClick(event)
    if (typeof onMouseDown === "function") return onMouseDown(event)
    if (!showSelectDebug) return

    const username = getUsername()
    const debugUrl = `/scripts?${
      debugUrlQuery || `selectedSettingType=${debugScriptList ? "script" : "list"}&name=${selectName}&filter=${selectName}&username=${username}`
    }`
    if (event?.button === 1) {
      window.open(`${window.location.origin}${debugUrl}&all=1`, "_blank")
    } else {
      history.push(debugUrl)
    }
  }

  render() {
    const { selectName, showSelectDebug } = this.state
    const { debug, help, onClick, modelFieldPath, debugScriptList, select, fieldDebugBtn, value, spanClassName = "" } = this.props
    const isProvidedSelectList = typeof select !== "string" && !!select

    if (!debug) return null

    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <Tooltip id="field">
            {help || (
              <>
                <span>{modelFieldPath}</span>
                {showSelectDebug && !debugScriptList && (
                  <span>
                    {!value && ` (${loc`List`}: ${selectName})`}
                    {value && ` (${loc`List`}: ${selectName}; ${loc`Value`}: ${value})`}
                  </span>
                )}
                {showSelectDebug && debugScriptList && <span> {`(${loc`Script`} ${selectName})`}</span>}
                {!showSelectDebug && isProvidedSelectList && (
                  <span className="font-style-italic">
                    {!value && ` (${loc`Custom`})`}
                    {value && ` (${loc`Custom`}; ${loc`Value`}: ${value})`}
                  </span>
                )}
              </>
            )}
          </Tooltip>
        }
      >
        <span
          className={`${spanClassName} field-debug${showSelectDebug ? " field-debug-link" : ""}${fieldDebugBtn ? " field-debug-btn" : ""}${
            help && !onClick ? " field-debug-help" : ""
          }`}
          onClick={() => this.handleConfigLink()}
          onMouseDown={e => this.handleConfigLink(e)}
        >
          ?
        </span>
      </OverlayTrigger>
    )
  }
}

export default withRouter(DebugPopover)
