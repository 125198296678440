import { genericErrors } from "./errorUtils.mjs"
import { toDashed, toPlural, toSingular } from "./miscUtils.mjs"
import { capitalizeFirstLetter, lowerCaseFirstLetter } from "./stringUtils.mjs"

const coreTenants = {
  MASTER: "master",
  SUPPORT: "support",
}

const pseudoEntities = ["login", "totp", "cron", "import", "mail", "database", "dbdump"]

const entityNames = {
  AccessLog: "AccessLog",
  AccessStat: "AccessStat",
  AccountingCatalogItem: "AccountingCatalogItem",
  Agreement: "Agreement",
  Amendment: "Amendment",
  Asset: "Asset",
  AssetCatalogItem: "AssetCatalogItem",
  AssetExpense: "AssetExpense",
  AssetLot: "AssetLot",
  Audit: "Audit",
  Batch: "Batch",
  BankAccount: "BankAccount",
  BankOperation: "BankOperation",
  BankTransaction: "BankTransaction",
  Booking: "Booking",
  Budget: "Budget",
  Campaign: "Campaign",
  CampaignContact: "CampaignContact",
  Camt: "Camt",
  Cashflow: "Cashflow",
  Case: "Case",
  Claim: "Claim",
  Collateral: "Collateral",
  Committee: "Committee",
  Communication: "Communication",
  Contract: "Contract",
  ContractElement: "ContractElement",
  ContractLot: "ContractLot",
  Counter: "Counter",
  CreditLine: "CreditLine",
  Currency: "Currency",
  Deal: "Deal",
  DepreciationSchedule: "DepreciationSchedule",
  Document: "Document",
  Documentation: "Documentation",
  Dtxn: "Dtxn",
  ExternalContract: "ExternalContract",
  ExternalData: "ExternalData",
  FeatureFlag: "FeatureFlag",
  FeatureFlagItem: "FeatureFlagItem",
  FinancingService: "FinancingService",
  Fund: "Fund",
  Funding: "Funding",
  History: "History",
  Import: "Import",
  Index: "Index",
  Indice: "Indice",
  Inventory: "Inventory",
  Invoice: "Invoice",
  KeyValue: "KeyValue",
  Locale: "Locale",
  Location: "Location",
  List: "List",
  Mail: "Mail",
  Maintenance: "Maintenance",
  Metric: "Metric",
  MetricRecord: "MetricRecord",
  Note: "Note",
  Organization: "Organization",
  PaymentFile: "PaymentFile",
  PaymentFileLine: "PaymentFileLine",
  Person: "Person",
  PersonAmendment: "PersonAmendment",
  Voting: "Voting",
  Posting: "Posting",
  PostingEvent: "PostingEvent",
  PrivateEquityAsset: "PrivateEquityAsset",
  PrivateEquityOperation: "PrivateEquityOperation",
  PrivateEquityTransaction: "PrivateEquityTransaction",
  Product: "Product",
  ProductAmendment: "ProductAmendment",
  ProductCatalogItem: "ProductCatalogItem",
  ProductElementCatalogItem: "ProductElementCatalogItem",
  ProductTable: "ProductTable",
  Project: "Project",
  Prospect: "Prospect",
  Question: "Question",
  Quotation: "Quotation",
  Report: "Report",
  Rule: "Rule",
  Score: "Score",
  Script: "Script",
  ScriptLog: "ScriptLog",
  Sheet: "Sheet",
  Sepa: "Sepa",
  SystemEvent: "SystemEvent",
  SystemLog: "SystemLog",
  Task: "Task",
  Tax: "Tax",
  TemporaryCollection: "TmpCollection",
  Tenant: "Tenant",
  Ticket: "Ticket",
  TimeEntry: "TimeEntry",
  User: "User",
  UserPreference: "UserPreference",
  Vote: "Vote",
}

function getEntityClientRoutes() {
  return {
    accessLog: "/access-log",
    accountingCatalogItem: "/accounting-catalog-item",
    agreement: "/agreement",
    amendment: "/amendment",
    asset: "/asset",
    assetCatalogItem: "/asset-catalog-item",
    assetLot: "/asset-lot",
    audit: "/audit",
    batch: "/batch",
    bankAccount: "/bank-account",
    bankOperation: "/bank-operation",
    bankTransaction: "/bank-transaction",
    booking: "/booking",
    campaign: "/campaign",
    camt: "/camt",
    cashflow: "/cashflow",
    case: "/case",
    claim: "/claim",
    collateral: "/collateral",
    committee: "/committee",
    communication: "/communication",
    contract: "/contract",
    contractElement: "/contract-element",
    contractLot: "/contract-lot",
    creditLine: "/credit-line",
    deal: "/deal",
    document: "/document",
    documentation: "/documentation",
    dtxn: "/dtxn",
    externalData: "/external-data",
    featureFlag: "/feature-flag",
    financingService: "/financing-service",
    fund: "/fund",
    funding: "/funding",
    history: "/history",
    inventory: "/inventory",
    invoice: "/invoice",
    keyValue: "/key-value",
    locale: "/locale",
    maintenance: "/maintenance",
    metric: "/metric",
    note: "/note",
    organization: "/organization",
    paymentFile: "/payment-file",
    paymentFileLine: "/payment-file-line",
    person: "/person",
    personAmendment: "/person-amendment",
    posting: "/posting",
    postingEvent: "/posting-event",
    privateEquityAsset: "/private-equity-asset",
    privateEquityOperation: "/private-equity-operation",
    privateEquityTransaction: "/private-equity-transaction",
    product: "/product",
    productAmendment: "/product-amendment",
    productCatalogItem: "/product-catalog-item",
    productElementCatalogItem: "/product-element-catalog-item",
    productTable: "/product-table",
    project: "/project",
    prospect: "/prospect",
    question: "/question",
    report: "/report",
    systemEvent: "/system-event",
    systemLog: "/system-log",
    rule: "/rule",
    score: "/score",
    sepa: "/sepa",
    tenant: "/tenant",
    ticket: "/ticket",
    user: "/user",
    scriptLog: "/script-log",
    voting: "/voting",
    vote: "/vote",
  }
}

function getEntityClientRoute(entityName, isNew) {
  if (!entityName) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'entityName'")
  const clientRoutes = getEntityClientRoutes()
  const lower = entityName.toLowerCase().replace(/( |-)/g, "")
  const key = Object.keys(clientRoutes).find(key => key.toLowerCase().replace(/( |-)/g, "") === lower)
  if (!key) {
    if (entityName.toLowerCase().startsWith("dyno")) {
      let route = "/page/" + toPlural(toDashed(entityName.substring(4)))
      if (isNew) route += "/new"
      return route
    } else {
      throw Error(genericErrors.UNSUPPORTED_ENTITY + entityName)
    }
  }
  return clientRoutes[key]
}

function getEntityServerRoutes() {
  return {
    accessLog: "/api/system/access-logs",
    accountingCatalogItem: "/api/accounting/accounting-catalog-items",
    agreement: "/api/financing/agreements",
    amendment: "/api/financing/amendments",
    asset: "/api/asset/assets",
    assetCatalogItem: "/api/asset/asset-catalog-items",
    assetLot: "/api/asset/asset-lots",
    audit: "/api/core/audits",
    bankAccount: "/api/bank/bank-accounts",
    bankOperation: "/api/bank/bank-operations",
    bankTransaction: "/api/bank/bank-transactions",
    bankStatement: "/api/bank/bank-statements",
    batch: "/api/script/batches",
    booking: "/api/accounting/bookings",
    collateral: "/api/asset/collaterals",
    campaign: "/api/person/campaigns",
    camt: "/api/billing/camts",
    case: "/api/case/cases",
    cashflow: "/api/billing/cashflows",
    claim: "/api/case/claims",
    committee: "/api/support/committees",
    communication: "/api/communication/communications",
    contract: "/api/financing/contracts",
    contractElement: "/api/financing/contract-elements",
    contractLot: "/api/financing/contract-lots",
    counter: "/api/core/counters",
    creditLine: "/api/financing/credit-lines",
    currency: "/api/core/currencies",
    deal: "/api/financing/deals",
    document: "/api/core/documents",
    dtxn: "/api/core/dtxns",
    externalData: "/api/external/external-data",
    featureFlag: "/api/core/feature-flags",
    featureFlagItem: "/api/core/feature-flags",
    financingService: "/api/financing/financing-services",
    fund: "/api/financing/funds",
    funding: "/api/financing/fundings",
    history: "/api/core/histories",
    index: "/api/core/indexes",
    indice: "/api/core/indices",
    inventory: "/api/accounting/inventories",
    invoice: "/api/billing/invoices",
    keyValue: "/api/core/key-values",
    list: "/api/script/lists",
    locale: "/api/core/locales",
    location: "/api/asset/locations",
    maintenance: "/api/asset/maintenances",
    metric: "/api/core/metrics",
    note: "/api/core/notes",
    paymentFile: "/api/billing/payment-files",
    paymentFileLine: "/api/billing/payment-file-lines",
    person: "/api/person/persons",
    personAmendment: "/api/person/person-amendments",
    voting: "/api/support/votings",
    posting: "/api/accounting/postings",
    postingEvent: "/api/accounting/posting-events",
    privateEquityAsset: "/api/private-equity/assets",
    privateEquityOperation: "/api/private-equity/operations",
    privateEquityTransaction: "/api/private-equity/transactions",
    product: "/api/financing/products",
    productAmendment: "/api/financing/product-amendments",
    productCatalogItem: "/api/financing/product-catalog-items",
    productElementCatalogItem: "/api/financing/product-element-catalog-items",
    productTable: "/api/financing/product-tables",
    project: "/api/support/projects",
    budget: "/api/support/budgets",
    prospect: "/api/person/prospects",
    question: "/api/support/questions",
    report: "/api/report/reports",
    rule: "/api/case/rules",
    score: "/api/scoring/scores",
    script: "/api/script/scripts",
    scriptLog: "/api/script/logs",
    sepa: "/api/billing/sepas",
    systemLog: "/api/system/system-logs",
    systemEvent: "/api/system/system-events",
    task: "/api/core/tasks",
    tax: "/api/core/taxes",
    tenant: "/api/core/tenants",
    ticket: "/api/support/tickets",
    timeEntry: "/api/support/time-entries",
    user: "/api/core/users",
    userPreference: "/api/core/users",
    vote: "/api/support/votes",
  }
}

/**
 * @param {string} entityName
 * @returns The relative URL of the API managing the given entity. For example providing "contract" would return "/api/financing/contracts".
 */
function getEntityServerRoute(entityName) {
  if (!entityName) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'entityName'")
  const serverRoutes = getEntityServerRoutes()
  const lower = entityName.toLowerCase().replace(/ /g, "")

  // ignore pseudo entities
  if (pseudoEntities.includes(lower)) return

  const key = Object.keys(serverRoutes).find(key => key.toLowerCase().replace(/ /g, "") === lower)
  if (!key) {
    if (lower.startsWith("dyno")) {
      return "/api/dyno/" + toDashed(toPlural(entityName.substring(4)))
    } else {
      throw Error(genericErrors.UNSUPPORTED_ENTITY + entityName)
    }
  }
  return serverRoutes[key]
}

function getEntityStatusListName(entityName) {
  if (!entityName) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'entityName'")
  return `${lowerCaseFirstLetter(entityName)}Status` // "Asset" => "assetStatus"
}

function getEntityName({ clientRoute }) {
  if (!clientRoute) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'clientRoute'")

  if (clientRoute.startsWith("/page/")) {
    // /page/external-persons => DynoExternalPerson
    return (
      "Dyno" +
      capitalizeFirstLetter(
        toSingular(
          clientRoute
            .substring(6)
            .split("-")
            .map(it => capitalizeFirstLetter(it))
            .join(""),
        ),
      )
    )
  }

  clientRoute = toSingular(clientRoute)

  const clientRoutes = getEntityClientRoutes()
  for (const entityName in clientRoutes) if (clientRoutes[entityName] === clientRoute) return capitalizeFirstLetter(entityName)
}

function getEntityDocumentTypeListName(entityName) {
  if (!entityName) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'entityName'")
  return `${lowerCaseFirstLetter(entityName)}DocumentType`
}

function getEntityHistoryTypeListName(entityName) {
  if (!entityName) throw Error(genericErrors.MISSING_FIELD_IN_ARGS + "'entityName'")
  return `${lowerCaseFirstLetter(entityName)}HistoryType`
}

export {
  coreTenants,
  entityNames,
  getEntityClientRoute,
  getEntityClientRoutes,
  getEntityDocumentTypeListName,
  getEntityHistoryTypeListName,
  getEntityName,
  getEntityServerRoute,
  getEntityServerRoutes,
  getEntityStatusListName,
  pseudoEntities,
}
