import React from "react"
import { Panel } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import { getUiState, setUiState } from "@/_services/uiState"
import { getUserConfiguration } from "@/_services/userConfiguration"
import { localStorageKeys } from "@/_services/utils"

class PanelOuter extends React.Component {
  constructor(props) {
    super(props)
    this.panelOuterRef = React.createRef()
    this.state = {}
  }

  componentDidMount() {
    const userConfig = getUserConfiguration()
    if (userConfig?.options?.saveOuterPanelsExpandedState !== true) {
      this.setState({ isExpanded: this.props.expanded })
      return
    }

    const {
      match: { path },
      onToggle,
    } = this.props
    const nodeDomPath = this.getDomPath(this.panelOuterRef.current.nextSibling)
    const expandedFlag = getUiState(`${localStorageKeys.UI_STATE.PANELS_PREFIX}${path}.outer.${nodeDomPath}.isExpanded`)
    this.setState({ path, nodeDomPath })
    if (onToggle && expandedFlag !== undefined && this.props.expanded !== expandedFlag) onToggle()
  }

  componentDidUpdate() {
    if (this.props.expanded !== this.state.isExpanded) {
      this.setState({ isExpanded: this.props.expanded })
      if (this.state.nodeDomPath)
        setUiState(`${localStorageKeys.UI_STATE.PANELS_PREFIX}${this.state.path}.outer.${this.state.nodeDomPath}.isExpanded`, this.props.expanded)
    }
  }

  getDomPath(el) {
    const stack = []
    while (el.parentNode !== null) {
      let sibCount = 0
      let sibIndex = 0
      for (let i = 0; i < el.parentNode.childNodes.length; i++) {
        const sib = el.parentNode.childNodes[i]
        if (sib.nodeName === el.nodeName) {
          if (sib === el) sibIndex = sibCount
          sibCount++
        }
      }

      if (el.hasAttribute("id") && el.id !== "") {
        stack.unshift(el.nodeName.toLowerCase() + "#" + el.id)
      } else if (sibCount > 1) {
        stack.unshift(el.nodeName.toLowerCase() + ":eq(" + sibIndex + ")")
      } else {
        stack.unshift(el.nodeName.toLowerCase())
      }

      el = el.parentNode
    }

    // slice 1 to remove the html element
    return stack.slice(1).join(">")
  }

  render() {
    const { children, expanded, match, path, location, history, staticContext, ...panelProps } = this.props // eslint-disable-line
    const { isExpanded } = this.state

    return (
      <>
        {/* 
          We need to put the ref on another element because the bootstrap Panel component doesn't forward refs.
          Another possible technique is to create a unique attribute in the Panel component and use it to find the element in the DOM
          once it is mounted but it requires additional work.
        */}
        <span style={{ display: "none" }} ref={this.panelOuterRef}></span>
        <Panel {...panelProps} expanded={isExpanded} className="panel-outer">
          {/* 
            when the isExpanded is not yet assigned, don't display anything 
            to avoid an initial flickering between the local storage state and the one provided in the props
          */}
          {isExpanded !== undefined && (
            <Panel.Collapse>
              <Panel.Body>{children}</Panel.Body>
            </Panel.Collapse>
          )}
        </Panel>
      </>
    )
  }
}

export default withRouter(PanelOuter)
