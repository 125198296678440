import { setOffline } from "@/_services/offlineService"

export const isLocalhost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/),
)

const onReady = config => {
  if (typeof config.onReady === "function") config.onReady()
}

/**
 * To test in localhost the service worker, comment the condition below that requires NODE_ENV to be production.
 * Then put a file named sw.js in the public folder. This file is generated by building the app or you can use one from an existing build.
 * In the Chrome browser, go to chrome://flags/#unsafely-treat-insecure-origin-as-secure and add http://localhost:3000 then restart the browser.
 * Finally in offlineService in isActivated() use the OR condition that checks that we are running in localhost
 *
 * Alternatively you can bind your localhost to an OS DNS like self.hyperfront.io [...].
 * Read more about this technique in the R&D documentation.
 */
const register = config => {
  // if ("serviceWorker" in navigator) { // use this line to test in localhost
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href)
    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on. This might happen if a CDN is used to
      // serve assets see https://github.com/facebook/create-react-app/issues/2374
      onReady(config)
      return
    }

    window.addEventListener("load", () => {
      const swUrl = `${process.env.PUBLIC_URL}/sw.js`

      if (isLocalhost) {
        // This is running on localhost. Let's check if a service worker still exists or not.
        checkValidServiceWorker(swUrl, config)
      } else {
        // Is not localhost. Just register the service worker.
        registerValidSW(swUrl, config)
      }
    })

    window.addEventListener("offline", () => setOffline(true))
    window.addEventListener("online", () => setOffline(false))
  } else {
    onReady(config)
  }
}

const registerValidSW = async (swUrl, config) => {
  try {
    const registration = await navigator.serviceWorker.register(swUrl)
    // we manually trigger the update to know immediately if there is a new service worker to install or not
    // if there is, then we don't render the app (no call to the onReady function) and reload the window
    // so that users do not see the interface flickering and must always use the latest service worker
    const updatedServiceWorker = await registration.update()
    const installingWorker = updatedServiceWorker.installing
    if (installingWorker) {
      console.debug("A new service worker has been detected.")
      installingWorker.onstatechange = () => {
        if (installingWorker.state === "installed") {
          const newWorker = registration.waiting
          newWorker.postMessage({ type: "SKIP_WAITING" })
          console.debug("The new service worker has been installed, application will now reload.")
          window.location.reload()
        }
      }
    } else {
      console.debug("You are using the latest service worker.")
      onReady(config)
    }
  } catch (error) {
    console.error("Error during service worker registration:", error)
    onReady(config)
  }
}

const checkValidServiceWorker = async (swUrl, config) => {
  try {
    // Check if the service worker can be found. If it can't reload the page.
    const response = await fetch(swUrl)
    // Ensure service worker exists, and that we really are getting a JS file.
    const contentType = response.headers.get("content-type")
    if (response.status === 404 || (contentType != null && contentType.indexOf("javascript") === -1)) {
      // No service worker found. Probably a different app. Reload the page.
      navigator.serviceWorker.ready.then(registration => {
        registration.unregister().then(() => window.location.reload())
      })
    } else {
      // Service worker found. Proceed as normal.
      registerValidSW(swUrl, config)
    }
  } catch (err) {
    console.log("No internet connection found. App is running in offline mode.")
    onReady(config)
  }
}

const unregister = () => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready.then(registration => registration.unregister())
  }
}

export { register, unregister }
