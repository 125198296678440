import axios from "axios"
import { formatAddress, objectToSearchParam } from "basikon-common-utils"
import cloneDeep from "lodash.clonedeep"

import { getLocale } from "@/_services/localization"
import { runScriptLocally } from "@/_services/scripts"
import { getOptions } from "@/_services/userConfiguration"

/**
 * When showing to the user addresses suggestions, to avoid being billed
 * for each request, Google offers the possibility to "group" requests
 * belonging to the same "session". Such sessions open when a user begins
 * searching for an address (calling the "autocomplete" API)
 * and are closed when an address is picked (calling the "details" API).
 * Read more here: https://developers.google.com/maps/documentation/places/web-service/session-tokens
 */
let sessionToken = null
function getSessiontoken() {
  if (!sessionToken) sessionToken = Date.now()
  return sessionToken
}

function resetSessiontoken() {
  sessionToken = null
}

export async function getAddressOptions({ search, provider, language, region, country }) {
  if (!provider) provider = getOptions("addressSearch")
  if (provider === "None") return []
  return (
    await axios.post(
      "/api/external/pivot/addresses" +
        objectToSearchParam({
          search,
          provider,
          language: language ?? getLocale(),
          country,
          sessiontoken: getSessiontoken(),
          region,
        }),
    )
  ).data
}

export async function getAddressDetails({ placeId, provider, extraInfo, language, region, country }) {
  if (!provider) provider = getOptions("addressSearch")
  if (provider === "None") return []
  const { detailFromCoordinates } = getOptions("address") || {}
  const addressDetails = (
    await axios.post(
      "/api/external/pivot/addresses" +
        objectToSearchParam({
          placeId,
          provider,
          language: language ?? getLocale(),
          sessiontoken: getSessiontoken(),
          extraInfo,
          region,
          country,
          detailFromCoordinates,
        }),
      {
        config: getOptions("address"),
      },
    )
  ).data

  resetSessiontoken()
  return addressDetails
}

function getAddressProvider() {
  return getOptions("addressSearch")
}

export function canGetAddressFromCoordinates({ provider }) {
  if (!provider) provider = getAddressProvider()
  if (!provider || ["None", "GouvFr"].includes(provider)) return false // "GouvFr" does not support getting an address from its coordinates
  return true
}

export async function getAddressFromCoordinates({ coordinates, extraInfo, provider, language }) {
  if (!provider) provider = getAddressProvider()
  if (!canGetAddressFromCoordinates({ provider })) return []

  const address = (
    await axios.post(
      "/api/external/pivot/addresses" +
        objectToSearchParam({
          coordinates,
          provider,
          language: language ?? getLocale(),
          sessiontoken: getSessiontoken(),
          extraInfo,
        }),
      {
        config: getOptions("address"),
      },
    )
  ).data

  resetSessiontoken()
  return address
}

// Find first matching address,
export async function findAddress({ search, provider, language, extraInfo, country }) {
  if (!provider) provider = getOptions("addressSearch")
  if (provider === "None") return {}
  const addresses = await getAddressOptions({ search, provider, language, country })
  let address = addresses?.[0]
  if (address) address = await getAddressDetails({ placeId: address.value, provider, language, extraInfo })
  return address
}

function getZipcode(zipcodeAndCity) {
  if (zipcodeAndCity) zipcodeAndCity = zipcodeAndCity.trim()
  if (!zipcodeAndCity) return

  return zipcodeAndCity.split(" ").find(part => Number(part))
}

function getCity(zipcodeAndCity) {
  if (zipcodeAndCity) zipcodeAndCity = zipcodeAndCity.trim()
  if (!zipcodeAndCity) return

  const zipcode = getZipcode(zipcodeAndCity)
  if (zipcode) return zipcodeAndCity.replace(zipcode, "").trim()
  return zipcodeAndCity
}

function isZipcode(zipcode) {
  if (zipcode) zipcode = zipcode.trim()
  if (!zipcode) return false

  return Number(zipcode) && zipcode.length === 5
}

function getCountryCode({ country, countries = [] }) {
  if (country) country = country.trim()
  if (!country) return

  return countries.find(c => c.label?.toUpperCase() === country?.toUpperCase())?.value || country
}

export async function normalizeAddress(_address, defaultType = "MAIN") {
  if (_address.addresses?.length) {
    for (const index in _address.addresses) {
      _address.addresses[index] = await normalizeAddress(_address.addresses[index], defaultType)
    }
    return _address
  }
  let address = cloneDeep(_address)
  if (!address) return

  address.address1 = await addressToString(address, "address1", false)
  address.addressLine = await addressToString(address, "addressLine")

  if (address.addressLine && !address.type && defaultType) address.type = defaultType

  // Set undefined fields to null in order to override existing values when changing address
  // if (!address._id) address._id = null
  // if (!address.type) address.type = null
  // if (!address.addressLine) address.addressLine = null
  if (!address.city) address.city = null
  if (!address.state) address.state = null
  if (!address.zipcode) address.zipcode = null
  if (!address.cityCode) address.cityCode = null
  if (!address.country) address.country = null
  if (!address.address1) address.address1 = null
  if (!address.address2) address.address2 = null
  if (!address.location) address.location = null
  if (!address.streetName) address.streetName = null
  if (!address.streetType) address.streetType = null
  if (!address.streetNumber) address.streetNumber = null
  if (!address.door) address.door = null
  if (!address.floor) address.floor = null
  if (!address.block) address.block = null
  if (!address.division) address.division = null
  if (!address.subDivision) address.subDivision = null
  if (!address.zones) address.zones = null
  if (!address.epciCode) address.epciCode = null
  if (!address.epciLabel) address.epciLabel = null
  // if (!address.taxAreaId) address.taxAreaId = null
  // if (!address.asOfDate) address.asOfDate = null
  // if (!address.confidenceIndicator) address.confidenceIndicator = null
  // if (!address.invalidAddress) address.invalidAddress = null
  // if (!address.invalidAddressDate) address.invalidAddressDate = null
  const addressConfig = getOptions("address")
  if (addressConfig?.fields?.length)
    addressConfig.fields.forEach(f => {
      if (!address[f.name]) address[f.name] = null
    })

  return address
}

function logAndReturn(addressLine, address) {
  console.log("parseAddress(): input:", addressLine)
  console.log("parseAddress(): output:", JSON.stringify(address, null, 2))
  return address
}

// 55 Rue du Faubourg Saint-Honoré, 75008 Paris
export async function parseAddress({ addressLine, countries = [], defaultType }) {
  if (addressLine) addressLine = addressLine.trim()
  if (!addressLine) return

  const _address = await stringToAddress(addressLine, "addressLine")
  if (_address) return logAndReturn(addressLine, { type: defaultType, ..._address })

  if (addressLine.includes(",")) {
    const result = addressLine.split(",").map(part => part.trim())

    // 55 Rue du Faubourg Saint-Honoré, Palais de l'Élysée, 75008 Paris, France
    // 55 Rue du Faubourg Saint-Honoré, Palais de l'Élysée, Paris 75008, France
    if (result.length === 4) {
      const address = await normalizeAddress(
        {
          address1: result[0],
          address2: result[1],
          zipcode: getZipcode(result[2]),
          city: getCity(result[2]),
          country: getCountryCode({ country: result[3], countries }),
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    }
    // 55 Rue du Faubourg Saint-Honoré, 75008 Paris, France
    // 55 Rue du Faubourg Saint-Honoré, Paris 75008, France
    // 55 Rue du Faubourg Saint-Honoré, Palais de l'Élysée, 75008 Paris
    // 55 Rue du Faubourg Saint-Honoré, Palais de l'Élysée, Paris 75008
    else if (result.length === 3) {
      const hasAddress2 = result[1].split(" ").filter(part => isZipcode(part)).length === 0
      if (hasAddress2) {
        const address = await normalizeAddress(
          {
            address1: result[0],
            address2: result[1],
            zipcode: getZipcode(result[2]),
            city: getCity(result[2]),
          },
          defaultType,
        )
        return logAndReturn(addressLine, address)
      }

      const address = await normalizeAddress(
        {
          address1: result[0],
          zipcode: getZipcode(result[1]),
          city: getCity(result[1]),
          country: getCountryCode({ country: result[2], countries }),
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    }
    // 55 Rue du Faubourg Saint-Honoré, 75008 Paris
    // 55 Rue du Faubourg Saint-Honoré, Paris 75008
    else if (result.length === 2) {
      const address = await normalizeAddress(
        {
          address1: result[0],
          zipcode: getZipcode(result[1]),
          city: getCity(result[1]),
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    } else {
      const address = await normalizeAddress(
        {
          address1: addressLine,
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    }
  } else {
    const result = addressLine.split(" ").map(part => part.trim())
    let [streetNumberOrZipcode, zipcode] = result.map(part => (Number(part) ? part : undefined)).filter(p => p)

    // 55 Rue du Faubourg Saint-Honoré 75008
    // 55 Rue du Faubourg Saint-Honoré 75008 Paris
    if (streetNumberOrZipcode && zipcode?.length > 4) {
      const address = await normalizeAddress(
        {
          address1: addressLine.substring(0, addressLine.indexOf(zipcode)).trim(),
          zipcode,
          city: addressLine.substring(addressLine.indexOf(zipcode) + zipcode.length, addressLine.length).trim(),
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    }

    // Rue du Faubourg Saint-Honoré 75008
    // Rue du Faubourg Saint-Honoré 75008 Paris
    if (streetNumberOrZipcode && streetNumberOrZipcode.length > 4) {
      zipcode = streetNumberOrZipcode

      const address = await normalizeAddress(
        {
          address1: addressLine.substring(0, addressLine.indexOf(zipcode)).trim(),
          zipcode,
          city: addressLine.substring(addressLine.indexOf(zipcode) + zipcode.length, addressLine.length).trim(),
        },
        defaultType,
      )
      return logAndReturn(addressLine, address)
    }

    // Rue du Faubourg Saint-Honoré
    // 55 Rue du Faubourg Saint-Honoré
    // Rue du Faubourg Saint-Honoré Paris
    const address = await normalizeAddress({ address1: addressLine }, defaultType)
    return logAndReturn(addressLine, address)
  }
}

async function stringToAddress(string, key) {
  if (!getOptions("address")?.useStringToAddressScript) return
  let address
  await runScriptLocally({
    scriptName: "string-to-address",
    dynArgs: {
      req: {
        body: {
          key,
          string,
        },
      },
      res: {
        json: response => (address = response?.address),
      },
    },
    acceptMissing: true,
  })
  return address
}

async function addressToString(address, key, withDefault = true) {
  let addressString = withDefault ? formatAddress(address) : address[key]
  if (!getOptions("address")?.useAddressToStringScript) return addressString
  await runScriptLocally({
    scriptName: "address-to-string",
    dynArgs: {
      req: {
        body: {
          key,
          address,
        },
      },
      res: {
        json: response => (addressString = response?.addressString),
      },
    },
    acceptMissing: true,
  })
  return addressString
}
