import get from "lodash.get"
import set from "lodash/set"

import { localStorageKeys } from "@/_services/utils"

// roots keys are URLs' matched path, for instance /contracts/:id
// then inside each you can have anything that describes the user interface state of the rendered route
const uiState = {}

const initUiState = () => {
  try {
    Object.assign(uiState, JSON.parse(localStorage.getItem(localStorageKeys.UI_STATE.ROOT)))
  } catch (err) {
    // do nothing
  }
}

const getUiState = path => {
  return get(uiState, path)
}

const setUiState = (path, value) => {
  set(uiState, path, value)
  localStorage.setItem(localStorageKeys.UI_STATE.ROOT, JSON.stringify(uiState))
}

export { initUiState, getUiState, setUiState }
