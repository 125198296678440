import React from "react"
import ReactDOM from "react-dom"
import { BrowserRouter, Route, Switch } from "react-router-dom"

import "@/_styles/index.scss"

import { setNavigationRef } from "@/_services/utils"
import { setupAxiosClient } from "@/_services/axios"
import { register as registerServiceWorker } from "@/_services/serviceWorker"
import App from "@/App.js"

const onReady = () => {
  ReactDOM.render(
    <BrowserRouter ref={setNavigationRef}>
      <Switch>
        <Route path="/" component={App} />
      </Switch>
    </BrowserRouter>,
    document.getElementById("root"),
  )
}

setupAxiosClient(window.location.origin)
registerServiceWorker({ onReady })
