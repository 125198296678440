import React from "react"
import { Row, Col, Panel } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import { applyClasses, customEvents, localStorageKeys, handleAccessibleOnKeyDown } from "@/_services/utils"
import { loc } from "@/_services/localization"
import { getUiState, setUiState } from "@/_services/uiState"

function getInitialState(props) {
  const {
    match: { path },
    title = "",
    collapse,
    cache = true,
  } = props

  let id
  if (cache) {
    id = typeof title === "string" ? (title || "").toLowerCase().replace(/ /g, "-").replace(/\(/g, "").replace(/\)/g, "") : Date.now().toString()

    const expandedFlag = getUiState(`${localStorageKeys.UI_STATE.PANELS_PREFIX}${path}.inner.${id}.isExpanded`)
    if (expandedFlag !== undefined) {
      return {
        id,
        expanded: expandedFlag,
      }
    }
  }

  return {
    id,
    expanded: collapse !== undefined ? !collapse : true,
  }
}

class PanelInner extends React.Component {
  constructor(props) {
    super(props)
    this.state = getInitialState(props)
    this.innerPanelRef = React.createRef()
  }

  componentDidMount() {
    this.innerPanelRef.current.addEventListener(customEvents.innerPanel.open, this.openInnerPanel, true)
  }

  componentWillUnmount() {
    this.innerPanelRef.current.removeEventListener(customEvents.innerPanel.open, this.openInnerPanel, true)
  }

  openInnerPanel = () => this.toggle(true)

  toggle = forceExpand => {
    const { id } = this.state
    const expanded = forceExpand || !this.state.expanded
    const {
      match: { path },
      title,
      cache = true,
      onSetExpanded,
    } = this.props

    if (cache && title) setUiState(`${localStorageKeys.UI_STATE.PANELS_PREFIX}${path}.inner.${id}.isExpanded`, expanded)
    onSetExpanded && onSetExpanded(expanded)
    this.setState({ expanded })
  }

  render() {
    const { expanded } = this.state
    const {
      title,
      titleLinkTo,
      titleSideText,
      hidePostTitleWhen,
      buttons,
      children,
      collapse,
      titleClassName,
      sectionProps = {},
      className = "",
    } = this.props
    const isCollapsible = collapse !== undefined
    let hideTitleSideText = false
    if (hidePostTitleWhen === "expanded") hideTitleSideText = expanded
    if (hidePostTitleWhen === "collapsed") hideTitleSideText = !expanded

    const titleClasses = applyClasses({
      "panel-title": true,
      "c-pointer": isCollapsible,
      [titleClassName || ""]: true,
    })

    let titleColSize = 12
    if (isCollapsible) titleColSize = 11
    if (expanded && buttons) titleColSize = 6

    const titleContent =
      title || (!hideTitleSideText && titleSideText) ? (
        <>
          {title && (
            <span
              tabIndex={isCollapsible ? "0" : "-1"}
              className={isCollapsible ? "outline-accessible" : ""}
              onKeyDown={event => handleAccessibleOnKeyDown({ event, fn: () => isCollapsible && this.toggle() })}
            >
              {typeof title === "string" ? `${loc(title)}` : title}
            </span>
          )}
          {!hideTitleSideText && titleSideText && <span className="white-space-nowrap ml-5px">{titleSideText}</span>}
        </>
      ) : null

    const titleCol =
      titleContent || isCollapsible ? (
        <Col xs={titleColSize} className={titleClasses}>
          {titleContent}

          {titleLinkTo && (
            <i
              className="icn-external-link icn-xs text-link ml-5px"
              onClick={event => {
                event.stopPropagation()
                window.open(window.location.origin + titleLinkTo, "_blank")
              }}
            />
          )}
        </Col>
      ) : null

    const buttonsCol =
      (expanded && buttons) || isCollapsible ? (
        <Col xs={12 - titleColSize} className="flex-center justify-content-end">
          {isCollapsible && !(expanded && buttons) && (
            <i className="section-collapse-icon icn-chevron-right icn-xs pull-right" data-is-expanded={expanded} />
          )}
          {expanded && <div onClick={e => e.stopPropagation()}>{buttons}</div>}
        </Col>
      ) : null

    const showTopCol = titleContent || titleCol || buttonsCol || sectionProps.errorFormat

    return (
      // we need to use a real html tag to be able to set a ref
      <div className={"row panel-inner " + className} ref={this.innerPanelRef}>
        {showTopCol && (
          <Col xs={12}>
            <Row
              onClick={() => {
                if (isCollapsible) this.toggle()
              }}
            >
              {titleCol} {buttonsCol}
              {sectionProps.errorFormat ? <span className="validation-error section-error">{sectionProps?.errorFormat}</span> : ""}
            </Row>
          </Col>
        )}
        <Col xs={12}>
          {/* onToggle is added as a hack to shut the console error from react */}
          <Panel expanded={isCollapsible ? expanded : true} onToggle={() => {}}>
            <Panel.Collapse>
              <Panel.Body>{children}</Panel.Body>
            </Panel.Collapse>
          </Panel>
        </Col>
      </div>
    )
  }
}

export default withRouter(PanelInner)
