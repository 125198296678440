import get from "lodash.get"
import set from "lodash/set"
import cloneDeep from "lodash.clonedeep"
import { PROPS_, getFieldProps, getFieldPropsPath } from "basikon-common-utils"

// "addresses[x].addressLine" => "addressLine"
// "persons[x].invoicing.bankAccount.bankIban" => "bankIban"
export function getLeafFieldName(fieldPath) {
  const leafFieldName = fieldPath.split(".").pop()
  return leafFieldName
}

// "addresses[x].addressLine" => "addresses[x]"
// "persons[x].invoicing.bankAccount.bankIban" => "persons[x].invoicing.bankAccount"
// "persons[x].invoicing.addresses[0].bankIban" => "persons[x].invoicing.addresses[0]"
export function getNestedFieldName(fieldPath) {
  const leafFieldName = getLeafFieldName(fieldPath)
  const nestedFieldName = fieldPath.replace(`.${leafFieldName}`, "")
  return nestedFieldName
}

// "addresses[x].addressLine" => "addresses"
// "persons[x].invoicing.bankAccount.bankIban" => "persons"
// "persons[x].invoicing.addresses[0].bankIban" => "persons"
export function getRootFieldName(fieldPath) {
  const rootFieldName = fieldPath.split(".")[0]
  if (rootFieldName.endsWith("]")) return rootFieldName.split("[")[0]
  return rootFieldName
}

// "addresses[0].addressLine" => [ 'addresses', 0 ]
// "persons[0].invoicing.bankAccount.bankIban" => [ 'persons', 0 ]
// "persons[0].invoicing.addresses[0].bankIban" => [ 'persons', 0 ]
export function getRootField(fieldPath) {
  const rootFieldName = fieldPath.split(".")[0]
  if (rootFieldName.endsWith("]")) {
    const [_rootFieldName, rootArrayItemIndex] = rootFieldName.split("[")
    return [_rootFieldName, Number(rootArrayItemIndex.replace("]", ""))]
  }
  return [rootFieldName]
}

// "addresses[0].addressLine" => [ '0', 'addressLine' ]
// "persons[0].invoicing.addresses[0].bankIban" => [ '0', 'invoicing', 'addresses', '0', 'bankIban' ]
export function getSetPath(fieldPath) {
  const setPath = fieldPath.replace(/\[/g, " ").replace(/\]/g, "").replace(/\./g, " ").split(" ")
  setPath.shift() // ["addresses", "x", "addressLine"] => ["x", "addressLine"]
  return setPath
}

/**
 * Create a patch object to update a nested property within an object, preserving the previous state.
 * This function is designed to work with nested objects and arrays.
 *
 * @param {object} obj - The target object to patch.
 * @param {string|string[]} fieldPath - The path to the property to update within the object.
 * @param {any} value - The new value to set at the specified field path.
 * @returns {object} - A patch object containing the changes to apply to the original object.
 *
 * @example
 * const originalObj = { data: { value: 42 } };
 * const patch = toPatch(originalObj, 'data.value', 55);
 * // Returns: { data: { value: 55 } }
 * // Applying the patch to originalObj would result in { data: { value: 55 } }
 */
export function toPatch(obj, fieldPath, value) {
  const isFieldPathArray = Array.isArray(fieldPath)
  const fieldPaths = isFieldPathArray ? fieldPath : [fieldPath]
  const values = isFieldPathArray && !Array.isArray(value) ? [value] : value

  const patch = {}
  let copyRootArray
  let copyRootObject
  for (const [index, fieldPath] of fieldPaths.entries()) {
    const valueToSet = isFieldPathArray ? values[index] : value
    if (fieldPath.startsWith(PROPS_)) {
      patch[fieldPath] = valueToSet
      continue
    }
    const [rootFieldName, rootArrayItemIndex] = getRootField(fieldPath)
    const setPath = getSetPath(fieldPath)

    // Root field is an Array
    if (rootArrayItemIndex >= 0) {
      const rootArray = get(obj, rootFieldName, [])
      if (!copyRootArray) copyRootArray = rootArray.map((it, i) => (i === rootArrayItemIndex ? cloneDeep(it) : it)) // Clone array item to keep prevState
      set(copyRootArray, setPath, valueToSet)

      patch[rootFieldName] = copyRootArray
    }
    // Root field is an Object
    else {
      const rootObject = get(obj, rootFieldName, {})
      if (!copyRootObject) copyRootObject = cloneDeep(rootObject) // Clone object to keep prevState
      set(copyRootObject, setPath, valueToSet)

      patch[rootFieldName] = copyRootObject
    }
  }
  return patch
}

// will set deep value while mutating the pathname
// setWithMutation(x, "toto.tutu.titi", 44) => x.toto(mutated).tutu(mutated).titi(changed): 44
// test code
// let x = {
// toto: {
// tutu: { titi: 33 }, tata: { tete: 55 },
// array: [{ subArray: [{ member: 66 }] }],
// }
// }
// const field = x.toto.tutu
// const field2 = x.toto.tata
// setWithMutation(x, "toto.tutu.titi", "changed_44")
// setWithMutation(x, "toto.array[0].subArray.0", "changed_66")
// setWithMutation(x, "toto.array[0].z", "changed_88")
// console.log(
// JSON.stringify(x, null, 2),
// x.toto.tutu !== field ? "OK" : "KO", // false
// x.toto.tata === field2 ? "OK" : "KO" // true
// )
export function setWithMutation(original, fieldPath, value) {
  const fieldPaths = fieldPath.replace(/\[/g, ".").replace(/\]/g, "").split(".")
  let obj = original
  for (const [index, fieldPath] of fieldPaths.entries()) {
    if (index < fieldPaths.length - 1) {
      if (Array.isArray(obj[fieldPath])) {
        obj[fieldPath] = [...obj[fieldPath]]
      } else {
        obj[fieldPath] = { ...obj[fieldPath] }
      }
    } else {
      obj[fieldPath] = value
    }
    obj = obj[fieldPath]
  }
  return original
}

/**
 * Checks if a value is empty or not.
 * @param {any} value - The value to check for emptiness.
 * @returns {boolean} True if the value is empty, false otherwise.
 */
export function isEmpty(value) {
  return !value && value !== 0
}

export function hasFieldPropsChanged({ obj, field, mandatory, min, max }) {
  const fieldPropsPath = getFieldPropsPath(field)
  const _mandatory = get(obj, `${fieldPropsPath}.mandatory`)
  if (_mandatory !== mandatory) return true
  const _min = get(obj, `${fieldPropsPath}.min`)
  if (_min !== min) return true
  const _max = get(obj, `${fieldPropsPath}.max`)
  return _max !== max
}

// Sync between "props_field" and "this.props" if it is passed via component props (ex: in a layout card)
// "this.props.min" => "props_field.min"
// "this.props.max" => "props_field.max"
// "this.props.mandatory" => "props_field.mandatory"
export function syncFieldProps({ obj, field, mandatory, min, max }) {
  if (!obj || !field) return

  const fieldProps = getFieldProps(obj, field)

  let _mandatory
  if (mandatory && !fieldProps?.mandatory) _mandatory = true
  else if (mandatory === false && fieldProps?.mandatory) _mandatory = false

  let fieldPropsPath
  if (_mandatory !== undefined) {
    fieldPropsPath = getFieldPropsPath(field)
    set(obj, `${fieldPropsPath}.mandatory`, _mandatory)
  }

  if (!isEmpty(min) && isEmpty(fieldProps?.min)) {
    if (!fieldPropsPath) fieldPropsPath = getFieldPropsPath(field)
    set(obj, `${fieldPropsPath}.min`, min)
  }

  if (!isEmpty(max) && isEmpty(fieldProps?.max)) {
    if (!fieldPropsPath) fieldPropsPath = getFieldPropsPath(field)
    set(obj, `${fieldPropsPath}.max`, max)
  }
}
