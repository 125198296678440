import go from "gojs"
import React from "react"

class Gojs extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
    go.Diagram.licenseKey =
      // valid until may 2023
      // "73f947e6b26431b700ca0d2b113f69ed1bb37b309d841ff75e5742f4ef09691c2bcaed2d59848bc0d4a84eaf086dc78d8dc46921c4185138e435d7d84ab283abbb3677b5125d458ba3502491c9f22ea1ab7072f0c5e722a28f2a89f3f9b8c5c90ceef38618cb1cab2a790530497ab04d"
      // valid until may 2026
      "298647e1b2614cc702d90776423d68f919a57e60ce8519a30a0715f6ec09601d719cbd7006d38bc682f81dee0a7a9489dcc5617ac3180638e335d7d513e7d4f0b33374b4410a4289f701749391fe2af6f47b73f590e077f7887d8de0fbae96cc5ae8a18449d81eb8287b042e5261af48"
  }

  componentDidMount = () => {
    go.Shape.defineFigureGenerator("EditFigure", function (shape, w, h) {
      return new go.Geometry().add(
        new go.PathFigure(0, h / 2 - 2, false)
          .add(new go.PathSegment(go.PathSegment.Line, w, h / 2 - 2))
          .add(new go.PathSegment(go.PathSegment.Move, 0, h / 2))
          .add(new go.PathSegment(go.PathSegment.Line, w, h / 2))
          .add(new go.PathSegment(go.PathSegment.Move, 0, h / 2 + 2))
          .add(new go.PathSegment(go.PathSegment.Line, w, h / 2 + 2)),
      )
    })
  }

  render() {
    const { className = "go-js-component" } = this.props
    return <div id="myDiagramDiv" className={className} />
  }
}

export default Gojs
