import React from "react"
import { Col, Modal, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"
import Table from "@/_components/Table"

import { getLocale, loc } from "@/_services/localization"
import { formatDateTime } from "@/_services/utils"

class HistoryModal extends React.Component {
  render() {
    let { obj, field, type, onClose, select, title = "History", columns = [], data } = this.props
    const locale = getLocale()

    if (columns.length === 0) {
      columns.push({ title: "Date", name: "date" }, { title: "User", name: "username" }, { title: "Value", name: "value" })
    }

    if (!data) {
      data = (obj[field] || []).map(it => ({
        date: formatDateTime(it.date, locale),
        username: it.username,
        value: <FormInput inArray value={it.value} type={type} select={select} readOnly />,
      }))
    }

    return (
      <Modal show={true} onHide={onClose} backdrop="static" className="history-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc(title)}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Table columns={columns} data={data} />
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={onClose}>{loc`Close`}</CustomButton>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default HistoryModal
