import axios from "axios"
import { getEntityServerRoute } from "basikon-common-utils"
import React from "react"
import { Col, Dropdown, Row } from "react-bootstrap"
import { withRouter } from "react-router-dom"

import CustomButton from "@/_components/CustomButton"

import { loc } from "@/_services/localization"
import { addNotification, addOops } from "@/_services/notification"
import { onDropdownMenuButtonKeyDown, onDropdownToggleKeyDown } from "@/_services/utils"

class EntityTransitionsButton extends React.Component {
  state = {
    transitionLoading: false,
  }

  executeTransition = async transition => {
    const { location, history, selectedEntities, entityName } = this.props

    this.setState({ transitionLoading: true })

    const serverRoute = getEntityServerRoute(entityName)
    const transitions = Array.from(selectedEntities).map(registration =>
      axios
        .get(`${serverRoute}/${registration}/transitions/${transition.code}`)
        .then(() =>
          addNotification(
            `Transition "${transition.label || transition.code}" executed successfully for ${entityName.toLowerCase()} "${registration}"`,
          ),
        )
        .catch(error => addOops(error)),
    )

    await Promise.all(transitions)

    this.setState({ transitionLoading: false }, () => {
      history.replace(`${location.pathname}?registration=${Array.from(selectedEntities).join(",")}`)
    })
  }

  render() {
    const { transitionLoading } = this.state
    const { entities, transitions = [], selectedEntities, transitionsLoading, handleSelectAll } = this.props
    const loading = transitionLoading || transitionsLoading
    const allEntitiesSelected = selectedEntities.size === entities.length
    const dropdownLabel = loc("Workflow transitions")
    const availableTransitions = transitions.filter(it => !it.hidden)

    return (
      <Row className="entity-transitions-button">
        <Col xs={12} className="flex align-items-center justify-content-end">
          {selectedEntities.size > 0 && handleSelectAll && (
            <CustomButton
              tabIndex="0"
              bsStyle="info"
              bsSize="small"
              fill
              onClick={() => handleSelectAll(allEntitiesSelected ? new Set() : new Set(entities.map(e => e.registration)))}
            >
              {` ${loc(allEntitiesSelected ? "Unselect all" : "Select all")}`}
            </CustomButton>
          )}

          {availableTransitions.length > 0 && (
            <Dropdown
              bsSize="small"
              bsStyle="primary btn-fill"
              className="inline-flex-center"
              disabled={loading}
              onClick={event => {
                const { target } = event
                target.focus()
                target.nextSibling?.click()
              }}
            >
              <CustomButton bsStyle="info" className="grow" tabIndex="-1" label={dropdownLabel} />
              <Dropdown.Toggle bsStyle="info" onKeyDown={onDropdownToggleKeyDown} aria-label={dropdownLabel} />
              <Dropdown.Menu className="br-theme overflow-hidden border-0">
                {availableTransitions.map(transition => (
                  <CustomButton
                    key={transition.code}
                    tabIndex="0"
                    bsStyle="info"
                    bsSize="small"
                    fill
                    className="btn-simple white-space-normal w-100 text-left br-0 m-0 inline-flex align-items-center outline-accessible-inset"
                    onClick={event => {
                      event.stopPropagation()
                      this.executeTransition(transition)
                    }}
                    onKeyDown={onDropdownMenuButtonKeyDown}
                  >
                    {transition.icon && <i className={"icn-xs mr-5px " + transition.icon} />}
                    {` ${loc(transition.label || transition.code)}`}
                  </CustomButton>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Col>
      </Row>
    )
  }
}

export default withRouter(EntityTransitionsButton)
