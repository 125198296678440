import { defaultLocaleCode, getLocaleParameters } from "basikon-common-utils"

import { getUriAndCacheResponse } from "@/_services/cache"
import { getDebugMode, htmlTag, localStorageKeys } from "@/_services/utils"

let currentLocale = ""
let defaultLocalizedStrings = {}
let localizedStrings = {}

export function getLocale() {
  return currentLocale
}

export async function setLocale({ locale, loadStringOverrides = true } = {}) {
  localizedStrings = {}

  let localStorageLocale = localStorage.getItem(localStorageKeys.LOCALE) // values stored locally are always of type string
  if (localStorageLocale === "undefined") localStorageLocale = undefined

  currentLocale = locale ?? localStorageLocale ?? navigator.language
  localStorage.setItem(localStorageKeys.LOCALE, currentLocale)
  htmlTag.setAttribute("data-rtl", currentLocale?.rtl || getLocaleParameters(currentLocale).rtl === true)

  const splittedLocale = currentLocale.split("-")
  const localeBaseCode = splittedLocale[0]
  const localeGeoCode = splittedLocale[1]
  const longLocale = `${localeBaseCode}-${(localeGeoCode || localeBaseCode).toUpperCase()}`

  try {
    await import(`moment/locale/${longLocale}`)
  } catch (error) {
    try {
      await import(`moment/locale/${localeBaseCode}`)
      // eslint-disable-next-line no-empty
    } catch (error) {}
  }

  // To optimize startup time we do the calls in parallel.
  // We need to handle the following scenarios :
  //  - The user is not connected and lands on pages not requiring authentification like login, register-page etc.
  //    In this case we need to load the locale but not the string overrides.
  //    After he logs in we need to load the string overrides only.
  //  - The user lands directly connected : we need to load both.
  //  - The locale is the default one, we need to load on the string overrides if the user is connected.
  const loadLocaleStringsPromise = longLocale === defaultLocaleCode ? {} : getUriAndCacheResponse(`/api/core/locales/${longLocale}`)
  const loadStringOverridesPromise = loadStringOverrides
    ? getUriAndCacheResponse(`/api/script/runs/string-overrides${locale ? `?locale=${locale}` : ""}`)
    : {}

  let localeStrings
  try {
    localeStrings = await loadLocaleStringsPromise
  } catch (err) {
    // whenever an error occurs, fallback to the default locale
    localeStrings = {}
  }

  let stringOverrides
  try {
    stringOverrides = await loadStringOverridesPromise
  } catch (error) {
    // we cannot rely anymore on the useStandard flag because it is now loaded in parallel
    // so we never show an error message => commented out
    // if (isTenantUseStandard()) {
    // addOops(error)
    // }
    stringOverrides = {}
  }

  defaultLocalizedStrings = localeStrings
  localizedStrings = { ...localeStrings, ...stringOverrides }
}

export function resetLocale({ toCurrentLocale } = {}) {
  localizedStrings = defaultLocalizedStrings
  if (toCurrentLocale) {
    setLocale({ locale: currentLocale })
  }
}

export function loc(strings, ...values) {
  if (!strings) return strings

  const key = Array.isArray(strings) ? strings.join("$") : strings.toString()
  const localizedString = localizedStrings?.[key]
  let lstrings = localizedString || key

  const isDefaultLocale = currentLocale === defaultLocaleCode

  if (!localizedString && getDebugMode() && !isDefaultLocale) {
    console.log("Missing locale key: " + key)
    localizedStrings[key] = lstrings // so that console.log() is done only once
  }

  if (typeof lstrings === "string" && lstrings.includes("$")) lstrings = lstrings.split("$")

  if (!lstrings) {
    lstrings = strings
    if (strings.length === 1) lstrings = lstrings[0]
  }

  let lstr
  if (typeof lstrings === "string") lstr = lstrings
  else {
    const array = []
    for (let i = 0; i < values.length; i++) {
      array.push(lstrings[i], values[i])
    }
    array.push(lstrings[values.length])
    lstr = array.join("")
  }

  return lstr
}
