import React from "react"

import FormInput from "@/_components/FormInput"

import { getValues } from "@/_services/lists"
import { getIsAdmin } from "@/_services/userConfiguration"

class PersonTypeComponent extends React.Component {
  render() {
    const { person, disabled, readOnly, colProps, onSetState, inModal } = this.props
    const isAdmin = getIsAdmin()

    // for now only admin can create organization. Add permission ?
    // => show "organization" button only if admin, or if displayed person is an organization

    const organizationType = "O"
    let personTypeValues = getValues("personType", () => this.setState({ loaded: true }))
    personTypeValues = personTypeValues?.filter(
      it => (isAdmin && !person.registration) || it.value !== organizationType || person.type === organizationType,
    )
    return (
      <FormInput
        disabled={disabled}
        onSetState={onSetState}
        obj={person}
        readOnly={readOnly}
        colProps={colProps}
        field="type"
        button={inModal ? undefined : !disabled || person.type !== organizationType}
        select={personTypeValues}
      />
    )
  }
}

export default PersonTypeComponent
