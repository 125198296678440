const consoleMessages = {
  showAxiosInterceptors: false,
  showLocalScriptLog: false,
  showLocalScriptError: false,
  showAgreementLayout: false,
  showAssetLayout: false,
  showContractLayout: false,
  showPersonLayout: false,
  showContractComputation: false,
  showContractAssetsSubcatalogs: false,
  showEntityWorkflowTransitions: false,
  showFirebaseLog: false,
  showOfflineLog: false,
  showPersonsQuickAddRelations: false,
  showEligibility: false,
  showPageRender: false,
}

export const setConsoleMessages = conf => {
  Object.assign(consoleMessages, conf?.consoleMessages || {})
}

export const reset = () => {
  for (const key in consoleMessages) consoleMessages[key] = false
}

export default consoleMessages
