import axios from "axios"

import { logout as authLogout } from "@/_services/authentication"
import { getHostConfig } from "@/_services/theming"
import { localStorageKeys, mergeQueryParams, searchParamToObject } from "@/_services/utils"

/**
 * In our SAML integration, our server receives a POST request from the Identity Provider
 * and then redirects users to our SPA URL with a transient token passed in the query params.
 */
export async function authenticate({ history }) {
  const queryParams = searchParamToObject(window.location.search)
  const { location } = history

  const hostConfig = getHostConfig()
  const saml = queryParams.saml || hostConfig.saml
  if (!saml) return

  // Security measure to prevent logging in with transient tokens here.
  // "Normal" transient tokens usage is handled in PagesLayout.jsx
  const hasStarted = localStorage.getItem(localStorageKeys.SAML.HAS_STARTED)
  if (hasStarted) localStorage.removeItem(localStorageKeys.SAML.HAS_STARTED)

  if (queryParams.token && hasStarted) {
    try {
      await axios.post("/api/core/auth/token/login", { token: queryParams.token })
    } catch (error) {
      await authLogout()
    }
    history.replace(`${location.pathname}${mergeQueryParams(location.search, { ...queryParams, token: undefined })}`)
    return
  }

  // this request is expect to return a redirection
  const {
    data: { loginUrl, logoutUrl },
  } = await axios.get(`/api/core/saml/${saml}`)
  window.open(loginUrl, "_self")

  localStorage.setItem(localStorageKeys.SAML.HAS_STARTED, true)
  localStorage.setItem(localStorageKeys.SAML.LOGOUT_URL, logoutUrl)

  return { isRedirectingToSamlProvider: true }
}

export async function logout() {
  const logoutUrl = localStorage.getItem(localStorageKeys.SAML.LOGOUT_URL)
  localStorage.removeItem(localStorageKeys.SAML.LOGOUT_URL)
  if (logoutUrl) {
    let href = logoutUrl
    window.location.href = href
    return new Promise(resolve => {
      // the redirection is not instantaneous so we need a delay
      // to avoid callers of this function to have to delay their work if they rely on the outcome of this call
      setTimeout(() => resolve(), 400)
    })
  }
}
