import { getEntityServerRoute, labelToName } from "basikon-common-utils"
import React, { Fragment, Suspense } from "react"
import { Col, Row } from "react-bootstrap"

import Card from "@/_components/Card"
import PanelInner from "@/_components/PanelInner"

const ContentComponent = React.lazy(() => import("@/_components/ContentComponent.jsx"))
const DocumentsComponent = React.lazy(() => import("@/_components/DocumentsComponent.jsx"))
const EntityAuditsCard = React.lazy(() => import("@/_components/EntityAuditsCard.jsx"))
const EntityCommunicationCard = React.lazy(() => import("@/_components/EntityCommunicationCard.jsx"))
const EntityMaintenanceCard = React.lazy(() => import("@/_components/EntityMaintenanceCard.jsx"))
const EntityExtension = React.lazy(() => import("@/_components/EntityExtension.jsx" /* webpackChunkName: "precache" */))
const EntityHistoryCard = React.lazy(() => import("@/_components/EntityHistoryCard.jsx"))
const EntityExternalData = React.lazy(() => import("@/_components/EntityExternalData.jsx"))
const EntityNotes = React.lazy(() => import("@/_components/EntityNotes.jsx"))
const EntitySubEntitiesCard = React.lazy(() => import("@/_components/EntitySubEntitiesCard.jsx"))
const EntityTasksCard = React.lazy(() => import("@/_components/EntityTasksCard.jsx"))
const EntityWorkflowCard = React.lazy(() => import("@/_components/EntityWorkflowCard.jsx"))
const FormContent = React.lazy(() => import("@/_components/FormContent.jsx"))
const ImagesCard = React.lazy(() => import("@/_components/ImagesCard.jsx"))
const ImportResults = React.lazy(() => import("@/_components/ImportResults.jsx"))
const NavsCard = React.lazy(() => import("@/_components/NavsCard.jsx"))
const SheetCard = React.lazy(() => import("@/_components/SheetCard.jsx" /* webpackChunkName: "precache" */))

const EntityInvoices = React.lazy(() => import("@/billing/EntityInvoices.jsx"))

const AllPersonsComponent = React.lazy(() => import("@/person/AllPersonsComponent.jsx"))
const PersonsReadOnlyCard = React.lazy(() => import("@/person/PersonsReadOnlyCard.jsx"))

import { loc } from "@/_services/localization"
import EntityAssetsComponent from "@/asset/EntityAssetsComponent"

export const cardNames = {
  ASSETS: "Assets",
  AUDITS: "Audits",
  CHART: "Chart",
  CONTENT: "Content",
  COMMUNICATIONS: "Communications",
  DASHBOARD: "Dashboard",
  DOCUMENTS: "Documents",
  EXTENSION: "Extension",
  EXTERNAL_DATA: "ExternalData",
  HISTORY: "History",
  IMAGES: "Images",
  IMPORT_RESULTS: "ImportResults",
  INVOICES: "Invoices",
  MAINTENANCES: "Maintenances",
  NOTES: "Notes",
  PERSONS: "Persons",
  PERSONS_READONLY: "PersonsReadOnly",
  SHEET: "Sheet",
  SUBENTITIES: "SubEntities",
  NAVS: "Navs",
  TASKS: "Tasks",
  WORKFLOW: "Workflow",
}

export const contentTypes = {
  CALCULATOR: "calculator",
  CAROUSEL: "carousel",
  ECHART: "echart",
  CHARTIST: "chartist",
  CONTRACT_SUMMARY: "contractsummary",
  ERROR: "error",
  FORM: "form",
  HTML: "html",
  KPI: "kpi",
  LEGEND: "legend",
  LINK: "link",
  MAP: "map",
  OPENAPI: "openapi",
  PIVOT: "pivot",
  TABLE: "table",
  TASKS_LIST: "taskslist",
  AI: "ai",
}

const cardNamesArray = Object.keys(cardNames).map(cardName => cardNames[cardName])
const contentTypesArray = Object.keys(contentTypes).map(widgetKey => contentTypes[widgetKey])

class LayoutCard extends React.Component {
  getGenericCard = ({ element, isContentElement, noLayoutCard }) => {
    const {
      entity,
      entityName,
      entityRegistration = entity?.registration,
      serverRoute,
      handlers,
      onSetState,
      workflowName,
      statusList,
      onSave,
      roleList,
      customSearch,
      isProspect,
      onPaste,
      execComputations,
      readOnly,
    } = this.props

    const card = element || this.props.card
    const { props: cardProps = {} } = card || {}
    const cardName = element?.type || card.card
    const { colProps = { xs: 12 }, title, collapse, ...restOfCardsProps } = cardProps

    if (!Object.values(cardNames).includes(cardName) && !isContentElement) return null

    if (cardName === cardNames.CONTENT || cardName === cardNames.DASHBOARD || isContentElement) {
      return (
        <ContentComponent
          debugInfo={card}
          execComputations={element?.props?.execComputations || execComputations}
          handlers={handlers}
          onSetState={onSetState}
          entity={entity}
          serverRoute={serverRoute}
          noCard={isContentElement && card?.card !== cardNames.CONTENT ? element?.noCard ?? noLayoutCard ?? true : undefined}
          // When the card name is Content, it is supposed to pass its props under the key "cardProps.content" or use "cardProps.name".
          // Other content cards pass their props directly.
          content={cardName !== cardNames.CONTENT && element}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.EXTENSION) {
      // to avoid double title
      if (element.title) cardProps.title = ""
      return (
        <EntityExtension
          debugInfo={card}
          entity={entity}
          serverRoute={!serverRoute && entityName ? getEntityServerRoute(entityName) : serverRoute}
          collapse={collapse}
          onSetState={onSetState}
          // when using usePersonlayout = true the readOnly can come from the parent entity (contract, project, etc.)
          readOnly={readOnly ?? entity?.readOnly}
          handlers={handlers}
          title={title}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.COMMUNICATIONS) {
      return <EntityCommunicationCard debugInfo={card} {...cardProps} />
    }

    if (cardName === cardNames.SUBENTITIES) {
      return (
        <EntitySubEntitiesCard
          entity={entity}
          debugInfo={card}
          query={cardProps.query}
          title={cardProps.title}
          noCard={cardProps.noCard}
          columns={cardProps.columns}
          collapse={cardProps.collapse}
          entityName={cardProps.entityName}
        />
      )
    }

    if (cardName === cardNames.MAINTENANCES) {
      return <EntityMaintenanceCard debugInfo={card} {...cardProps} />
    }

    if (cardName === cardNames.TASKS) {
      return (
        <EntityTasksCard
          debugInfo={card}
          entityRegistration={entityRegistration}
          entityName={entityName}
          entity={entity}
          readOnly={entity.readOnly}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.PERSONS) {
      return (
        <AllPersonsComponent
          debugInfo={card}
          entity={entity}
          readOnly={entity.readOnly}
          handleSetEntityState={onSetState}
          isProspect={isProspect}
          customSearch={customSearch}
          roleList={roleList}
          nextAvailableRoles={cardProps.nextAvailableRoles}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.WORKFLOW) {
      return (
        <EntityWorkflowCard
          debugInfo={card}
          title={title}
          entityName={entityName}
          serverRoute={serverRoute}
          workflowName={workflowName}
          statusList={statusList}
          entity={entity}
          onSetState={onSetState}
          onSave={onSave}
          isChanged={entity.isChanged}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.AUDITS) {
      return (
        <EntityAuditsCard
          debugInfo={card}
          title={title}
          entityName={entityName}
          serverRoute={serverRoute}
          workflowName={workflowName}
          statusList={statusList}
          entity={entity}
          onSetState={onSetState}
          isChanged={entity.isChanged}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.HISTORY) {
      return (
        <EntityHistoryCard
          debugInfo={card}
          title={title}
          entityName={entityName}
          serverRoute={serverRoute}
          workflowName={workflowName}
          statusList={statusList}
          entity={entity}
          onSetState={onSetState}
          isChanged={entity.isChanged}
          {...cardProps}
        />
      )
    }

    if (cardName === cardNames.NOTES) {
      if (!entity._id) return null
      return (
        <EntityNotes
          entity={entity}
          debugInfo={card}
          onPaste={onPaste}
          entityName={entityName}
          readOnly={entity.readOnly}
          entityRegistration={entityRegistration}
          includeEntityStatusInInsertPayload={cardProps.includeEntityStatusInInsertPayload}
          {...cardProps}
          handleSetEntityState={onSetState}
        />
      )
    }

    if (cardName === cardNames.SHEET) {
      return (
        <SheetCard
          entity={entity}
          debugInfo={card}
          readOnly={entity.readOnly}
          handleSetEntityState={onSetState}
          sheetTemplate={restOfCardsProps.sheetTemplate}
          {...{ title, collapse, ...restOfCardsProps }}
        />
      )
    }

    if (cardName === cardNames.DOCUMENTS && entityRegistration) {
      return (
        <DocumentsComponent
          debugInfo={card}
          serverRoute={!serverRoute && entityName ? getEntityServerRoute(entityName) : serverRoute}
          entityRegistration={entityRegistration}
          entity={entity}
          title={title || "Documents"}
          entityName={entityName}
          {...this.props}
          {...{ colProps, collapse, ...restOfCardsProps }}
        />
      )
    }

    if (cardName === cardNames.IMAGES) {
      return (
        <ImagesCard
          debugInfo={card}
          serverRoute={serverRoute}
          entityName={entityName}
          entityRegistration={entityRegistration}
          entity={entity}
          title={title || "Images"}
          {...this.props}
          {...{ colProps, collapse, ...restOfCardsProps }}
        />
      )
    }

    if (cardName === cardNames.CHART) {
      return <ContentComponent {...this.props} {...{ colProps, collapse, ...restOfCardsProps }} />
    }

    if (cardName === cardNames.EXTERNAL_DATA) {
      return <EntityExternalData debugInfo={card} entity={entity} {...{ title, collapse, ...restOfCardsProps }} {...this.props} />
    }

    if (cardName === cardNames.PERSONS_READONLY) {
      return (
        <PersonsReadOnlyCard
          debugInfo={card}
          title={cardProps.title}
          role1={cardProps.role1}
          role2={cardProps.role2}
          person1={cardProps.person1}
          person2={cardProps.person2}
          collapse={cardProps.collapse}
          persons={entity?.persons || []}
        />
      )
    }

    if (cardName === cardNames.INVOICES) {
      return (
        <EntityInvoices
          debugInfo={card}
          title={cardProps.title}
          query={cardProps.query}
          entityName={entityName}
          columns={cardProps.columns}
          collapse={cardProps.collapse}
          entityRegistration={entityRegistration}
          invoiceType={cardProps.invoiceType}
        />
      )
    }

    if (cardName === cardNames.NAVS) {
      return <NavsCard navs={cardProps.navs} displayMode={cardProps.displayMode} isFirstNavsCard={cardProps.isFirstNavsCard} />
    }

    if (cardName === cardNames.IMPORT_RESULTS) {
      return (
        <ImportResults
          results={cardProps.results}
          noCard={cardProps.noCard}
          importName={cardProps.importName}
          hidden={cardProps.hidden}
          collapse={cardProps.collapse}
          title={cardProps.title}
        />
      )
    }

    if (cardName === cardNames.ASSETS) {
      return (
        <EntityAssetsComponent
          entity={entity}
          cardName={entityName}
          title={cardProps.title}
          noCard={cardProps.noCard}
          columns={cardProps.columns}
          collapse={cardProps.collapse}
          showCopy={cardProps.showCopy}
          showEdit={cardProps.showEdit}
          showAuto={cardProps.showAuto}
          modelPath={cardProps.modelPath ?? "assets"}
          entityName={cardProps.entityName}
          debugInfo={cardProps.debugInfo}
          catalogName={cardProps.catalogName}
          financingProduct={cardProps.financingProduct}
          showAutoPanel={cardProps.showAutoPanel}
          selectionModes={cardProps.selectionModes}
          isPartExchange={cardProps.isPartExchange}
          useAssetLayout={cardProps.useAssetLayout}
          showAssetsSearch={cardProps.showAssetsSearch}
          execComputations={cardProps.execComputations}
          hideTotalDiscount={cardProps.hideTotalDiscount}
          hideTotalQuantity={cardProps.hideTotalQuantity}
          hideTotalUnitPrice={cardProps.hideTotalUnitPrice}
          handleSetEntityState={onSetState}
          assetExtensionTitle={cardProps.assetExtensionTitle}
          hideTotalSourcePrice={cardProps.hideTotalSourcePrice}
          autoLoadCatalogItems={cardProps.autoLoadCatalogItems}
          hideTotalPriceExclTax={cardProps.hideTotalPriceExclTax}
          hideTotalPriceInclTax={cardProps.hideTotalPriceInclTax}
          allowAssetNameOptions={cardProps.allowAssetNameOptions}
          readOnly={cardProps.readOnly}
        />
      )
    }
  }

  getElement = ({ element, elementKey, noLayoutCard, layout }) => {
    const { handlers, entity, onSetState, execComputations, readOnly, modelPath, mandatory } = this.props

    const isContentElement = [...contentTypesArray, cardNames.CONTENT.toLocaleLowerCase()].includes(element.type?.toLowerCase())
    if (cardNamesArray.includes(element.type) || isContentElement) {
      return (
        <Suspense key={elementKey} fallback={null}>
          {this.getGenericCard({ element, elementKey, isContentElement, noLayoutCard })}
        </Suspense>
      )
    }

    // We need to have a more precise key so that react can properly track changes,
    // otherwise it can recall previous data when changing the presentation of the content.
    // Some projects change dynamically the label of the fields, so this is not a good property to add in the key.
    const key = elementKey + (element?.title || "") + (element?.formInputProps?.field || "") + (element?.buttonProps?.uri || "")

    if (element.formInputProps && element.formInputProps.debounce === undefined) {
      const debounceLayoutCards = layout?.debounce?.layoutCards
      if (debounceLayoutCards === true) {
        element.formInputProps.debounce = debounceLayoutCards

        // When just declaring debounce: true, we apply some default values depending the type of form input,
        // based on our knowledge what types are slow.
        if (element.formInputProps.select && element.formInputProps.debounce === undefined) {
          element.formInputProps.debounce = false
        }
      } else if (typeof debounceLayoutCards === "object") {
        const formInputType = element.formInputProps.type || (element.formInputProps.select ? "select" : "text")
        const formInputTypeDebounce = debounceLayoutCards[formInputType]
        // this weird check is to allow this kind of declaration:
        // { debounce: layoutCards: { select: false } }
        if ([undefined, true].includes(formInputTypeDebounce)) {
          element.formInputProps.debounce = true
        }
      }
    }

    return (
      <FormContent
        key={key}
        field={element}
        handlers={handlers}
        obj={entity}
        onSetState={onSetState}
        execComputations={execComputations}
        readOnly={readOnly}
        modelPath={modelPath}
        mandatory={mandatory}
      />
    )
  }

  render() {
    const { debugInfo, card, layout, entity, modelFieldPath } = this.props
    let {
      rows = [],
      noCard: noLayoutCard,
      collapse: cardCollapse,
      title: cardTitle,
      titleLinkTo: cardTitleLinkTo,
      className,
      titleSideText,
    } = this.props

    // legacy support code for cards available here that were declared directly,
    // like for example { card: "Extension" } instead of { card: "Layout", rows: [{ type: "Extension" }] }
    if (rows.length === 0 && card) {
      noLayoutCard = true
      cardCollapse = undefined
      cardTitle = undefined
      rows[0] = { type: card.card, ...card }
    }

    return (
      <Card
        noCard={noLayoutCard}
        title={loc(cardTitle)}
        titleSideText={titleSideText}
        collapse={cardCollapse}
        debugInfo={debugInfo}
        titleLinkTo={cardTitleLinkTo}
        onSetExpanded={this.onSetExpanded}
        className={`layout-card ${className || ""}`}
        entity={entity}
        modelFieldPath={modelFieldPath || labelToName(this.props.title)}
      >
        {rows?.map((row, key) => {
          if (Array.isArray(row)) {
            return (
              <Row key={key}>
                {row
                  .filter(col => col && !col.hidden)
                  .map((element, elementKey) => {
                    return this.getElement({ element, elementKey, noLayoutCard, layout })
                  })}
              </Row>
            )
          }

          if (Object.keys(row).length > 0) {
            const { title, titleLinkTo, collapse: rowCollapse, rows: nestedRows, key: cardKey, hidden } = row
            const rowCardKey = cardKey ? `root-${cardKey}-${key}` : title ? `root-${title}-${key}` : `root-${key}`
            if (hidden) return null

            const rowRootElement = (
              <Row>
                <Col xs={12}>{this.getElement({ element: row, elementKey: rowCardKey, noLayoutCard, layout })}</Col>
              </Row>
            )

            const subRows = Array.isArray(nestedRows)
              ? nestedRows.map((nestedRow, pKey) => {
                  const elementKey = `${key}-${pKey}`
                  if (Array.isArray(nestedRow)) {
                    return (
                      <Row key={pKey}>
                        {nestedRow
                          .filter(it => it)
                          .map((element, index) => this.getElement({ element, elementKey: `${elementKey}-${index}`, noLayoutCard, layout }))}
                      </Row>
                    )
                  }

                  if (Object.keys(nestedRow).length > 0) {
                    return this.getElement({ element: nestedRow, elementKey, noLayoutCard, layout })
                  }
                })
              : null

            // formInputProps & buttonProps are provided without title
            // They aren't put in a panel so that their layout can controlled finely by configuration.
            return title ? (
              <PanelInner collapse={rowCollapse} title={title} titleLinkTo={titleLinkTo} key={key}>
                {rowRootElement}
                {subRows}
              </PanelInner>
            ) : (
              <Fragment key={key}>
                {rowRootElement}
                {subRows}
              </Fragment>
            )
          }
        })}
      </Card>
    )
  }
}

export const genericCardExists = cardName => cardName === "Layout" || cardNamesArray.includes(cardName)

export default LayoutCard
