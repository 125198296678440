import React, { Component } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"

import { loc } from "@/_services/localization"

class ButtonWithTooltip extends Component {
  render() {
    let {
      id,
      fill,
      tooltip,
      bsStyle,
      bsSize,
      linkTo,
      onClick,
      className,
      pullRight,
      simple = true,
      placement = "top",
      btnClassName = "",
      loading = false,
      disabled,
      style,
      children,
      hidden,
      tabIndex,
      statusIndicator,
      elementTag,
      ariaLabel,
    } = this.props
    const dataTest = this.props["data-test"]

    if (!tooltip && className) {
      tooltip =
        className.indexOf("icn-edit") > -1
          ? `Edit`
          : className.indexOf("icn-check") > -1
          ? `Save`
          : className.indexOf("icn-search") > -1
          ? `Search`
          : className.indexOf("icn-copy") > -1
          ? `Copy`
          : className.indexOf("icn-paste") > -1
          ? `Paste`
          : className.indexOf("icn-duplicate") > -1
          ? `Duplicate`
          : className.indexOf("icn-xmark") > -1
          ? `Delete`
          : className.indexOf("icn-plus") > -1
          ? `Add`
          : className.indexOf("icn-map-marker") > -1
          ? `Geolocalization`
          : className === "icn-history"
          ? `History`
          : className.indexOf("icn-upload") > -1
          ? `Upload`
          : className.indexOf("icn-download") > -1
          ? `Download`
          : className === "icn-qr-code"
          ? `QR`
          : className.indexOf("icn-arrow-up") > -1
          ? `Up`
          : className.indexOf("icn-arrow-down") > -1
          ? `Down`
          : className === "icn-eye"
          ? `View`
          : "??"
    }

    if (!bsStyle && className) {
      bsStyle =
        className.indexOf("icn-edit") > -1
          ? "success"
          : className.indexOf("icn-check") > -1
          ? "success"
          : className.indexOf("icn-plus") > -1
          ? "primary"
          : className.indexOf("icn-search") > -1
          ? "info"
          : className.indexOf("icn-copy") > -1
          ? "info"
          : className.indexOf("icn-duplicate") > -1
          ? "info"
          : className.indexOf("icn-xmark") > -1
          ? "danger"
          : className === "icn-exclamation-triangle"
          ? "warning"
          : className === "icn-history"
          ? "primary"
          : className.indexOf("icn-download") > -1
          ? "success"
          : className === "icn-qr-code"
          ? "success"
          : "info"
    }

    const locToolTip = loc(tooltip)

    return (
      <OverlayTrigger placement={placement} trigger={["hover"]} overlay={<Tooltip id={locToolTip || id}>{locToolTip}</Tooltip>}>
        <CustomButton
          tabIndex={tabIndex}
          style={style}
          id={id}
          fill={fill}
          linkTo={linkTo}
          simple={simple}
          bsStyle={bsStyle || "info"}
          bsSize={bsSize || "xs"}
          onClick={onClick ? onClick : null}
          pullRight={pullRight}
          className={btnClassName}
          loading={loading}
          iconClassName={className}
          disabled={disabled}
          data-test={dataTest}
          hidden={hidden}
          elementTag={elementTag}
          ariaLabel={ariaLabel || locToolTip}
        >
          {statusIndicator && <span className={`btn-status-indicator bg-${statusIndicator || "primary"}`}></span>}
          {children}
        </CustomButton>
      </OverlayTrigger>
    )
  }
}

export default ButtonWithTooltip
