import go from "gojs"
import React from "react"

import GojsComponent from "@/_components/GojsComponent"

const { make } = go.GraphObject

class Gojs extends React.Component {
  state = {}

  initDiagram = () => {
    if (this.gojsDiagram) {
      if (this.gojsDiagram.currentTool) this.gojsDiagram.currentTool.doCancel()
      this.gojsDiagram.clear()
      this.gojsDiagram.div = null
    }
    let gojsDiagram
    if (this.props.type === "hierarchy") {
      gojsDiagram = this.createHierarchyDiagram()
      gojsDiagram.model = new go.TreeModel(this.props.diagram)
    } else if (this.props.type === "relation") {
      gojsDiagram = this.createRelationDiagram()
      gojsDiagram.model = new go.GraphLinksModel(this.props.diagram.nodeDataArray, this.props.diagram.linkDataArray)
    }
    gojsDiagram.zoomToFit()
    this.gojsDiagram = gojsDiagram
  }

  componentDidMount = () => {
    this.initDiagram()
  }

  componentDidUpdate = prevProps => {
    if (this.props.diagram !== prevProps.diagram) {
      this.initDiagram()
    }
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  createHierarchyDiagram = () => {
    let props = this.props

    let myDiagram = make(
      go.Diagram,
      "myDiagramDiv", // must be the ID or reference to div
      {
        initialAutoScale: go.Diagram.Uniform, // an initial automatic zoom-to-fit
        contentAlignment: go.Spot.Center, // align diagram to the center of the viewport
        "toolManager.hoverDelay": 100, // 100 milliseconds instead of the default 850
        "toolManager.toolTipDuration": 20000, // 10s instead of the default 5000
        allowCopy: false,
        // create a TreeLayout for the family tree
        layout: make(go.TreeLayout, {
          angle: 0,
          nodeSpacing: 10,
          layerSpacing: 40,
          layerStyle: go.TreeLayout.LayerUniform,
        }),
      },
    )

    // var bluegrad = "#90CAF9"
    // var pinkgrad = "#F48FB1"

    // Set up a Part as a legend, and place it directly on the diagram
    // myDiagram.add(
    // make(
    // go.Part,
    // "Table",
    // { position: new go.Point(0, 250), selectable: false },
    // make(go.TextBlock, "Key", {
    // row: 0,
    // font: "700 14px Droid Serif, sans-serif",
    // }), // end row 0
    // make(
    // go.Panel,
    // "Horizontal",
    // { row: 1, alignment: go.Spot.Left },
    // make(go.Shape, "Rectangle", {
    // desiredSize: new go.Size(15, 15),
    // fill: bluegrad,
    // margin: 5,
    // }),
    // make(go.TextBlock, "Males", { font: "700 13px Droid Serif, sans-serif" })
    // ), // end row 1
    // make(
    // go.Panel,
    // "Horizontal",
    // { row: 2, alignment: go.Spot.Left },
    // make(go.Shape, "Rectangle", {
    // desiredSize: new go.Size(15, 15),
    // fill: pinkgrad,
    // margin: 5,
    // }),
    // make(go.TextBlock, "Females", {
    // font: "700 13px Droid Serif, sans-serif",
    // })
    // ), // end row 2
    // make(
    // go.Panel,
    // "Horizontal",
    // { row: 3, alignment: go.Spot.Left },
    // make(go.Shape, "Rectangle", {
    // desiredSize: new go.Size(15, 15),
    // fill: "lightgray",
    // margin: 5,
    // }),
    // make(go.TextBlock, "Company", {
    // font: "700 13px Droid Serif, sans-serif",
    // })
    // ) // end row 2
    // )
    // )

    // define each Node's appearance
    myDiagram.nodeTemplate = this.getNodeTemplate(props.onClick, props.onAddPerson)

    // define the Link template
    myDiagram.linkTemplate = make(
      go.Link, // the whole link panel
      { routing: go.Link.Orthogonal, corner: 5, selectable: false },
      make(go.Shape, { strokeWidth: 3, stroke: "#424242" }),
    ) // the gray link shape

    return myDiagram
  }

  getNodeTemplate(onClick, onAddPerson) {
    // define tooltips for nodes
    var tooltiptemplate = make(
      "ToolTip",
      { "Border.fill": "white" /*, "Border.stroke": "black"*/ },
      make(
        go.TextBlock,
        {
          font: "bold 8pt Helvetica, bold Arial, sans-serif",
          wrap: go.TextBlock.WrapFit,
          margin: 4,
        },
        new go.Binding("text", "", item => item.tooltip),
      ),
    )

    const handleNodeClick =
      onClick &&
      ((e, obj) => {
        if (obj.part.data.isMain) {
          if (onAddPerson) onAddPerson(e, obj.part)
        } else {
          onClick(e, obj.part)
        }
      })

    // define each Node's appearance
    let nodeTemplate = make(
      go.Node,
      make(
        go.Panel,
        "Spot",
        {
          click: handleNodeClick,
        },
        make(
          go.Panel,
          "Auto",
          {
            //click: onClick,
            toolTip: tooltiptemplate,
          },
          // define the node's outer shape, which will surround the TextBlock
          make(go.Shape, "RoundedRectangle", { strokeWidth: 0, fill: "transparent" }, new go.Binding("fill", "color")),
          make(
            go.TextBlock,
            {
              font: "bold 10pt Inter, sans-serif",
              margin: 4,
            },
            new go.Binding("text", "text"),
            new go.Binding("stroke", "textColor"),
          ),
        ),
        make(
          go.Shape,
          "Circle",
          { strokeWidth: 0, alignment: new go.Spot(0, 0, 1, 1), width: 20, height: 20 /*, stroke: "DarkRed"*/ },
          new go.Binding("fill", "topLeftCirclecolor"),
          new go.Binding("visible", item => (item.topLeftCirclecolor === "transparent" ? false : true)),
        ),
      ),
    )

    // function handleAddPerson(e, obj) {
    // var adornment = obj.part
    // var fromNode = adornment.adornedPart
    // onAddPerson(e, fromNode)
    // }

    // const handleClick =
    // onClick &&
    // ((e, obj) => {
    // var adornment = obj.part
    // var fromNode = adornment.adornedPart
    // onClick(e, fromNode)
    // })

    // let buttons = []
    // if (handleClick) {
    // buttons.push(
    // // the button to create a "next" node, at the top-right corner
    // make(
    // "Button",
    // {
    // alignment: new go.Spot(1, 0, 0, 4),
    // click: handleClick, // this function is defined below
    // },
    // make(go.Shape, "EditFigure", { width: 6, height: 6 })
    // )
    // )
    // }
    // if (onAddPerson) {
    // buttons.push(
    // // the button to create a "next" node, at the top-right corner
    // make(
    // "Button",
    // {
    // alignment: go.Spot.BottomRight,
    // click: handleAddPerson, // this function is defined below
    // },
    // make(go.Shape, "PlusLine", { width: 6, height: 6 }),
    // new go.Binding("visible", item => (item.isMain ? true : false))
    // )
    // )
    // }

    // // unlike the normal selection Adornment, this one includes a Button
    // nodeTemplate.selectionAdornmentTemplate = make(
    // go.Adornment,
    // "Spot",
    // make(
    // go.Panel,
    // "Auto",
    // //  make(go.Shape, { fill: null, stroke: "blue", strokeWidth: 2 }),
    // make(go.Placeholder) // a Placeholder sizes itself to the selected Node
    // ),
    // ...buttons
    // ) // end Adornment

    return nodeTemplate
  }

  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  ////////////////////////////////////////////////////////////////////////////////////
  createRelationDiagram = () => {
    let props = this.props

    let myDiagram = make(
      go.Diagram,
      "myDiagramDiv", // must name or refer to the DIV HTML element
      {
        //"animationManager.isInitial": false,
        //"animationManager.initialAnimationStyle": go.AnimationManager.AnimateLocations,
        "animationManager.initialAnimationStyle": go.AnimationManager.None,
        "toolManager.hoverDelay": 100, // 100 milliseconds instead of the default 850
        "toolManager.toolTipDuration": 20000, // 10s instead of the default 5000
        initialAutoScale: go.Diagram.Uniform, // an initial automatic zoom-to-fit
        contentAlignment: go.Spot.Center, // align diagram to the center of the viewport
        allowDelete: false,
        layout: make(
          go.ForceDirectedLayout, // automatically spread nodes apart
          {
            maxIterations: 200,
            defaultSpringLength: 30,
            defaultElectricalCharge: 100,
          },
        ),
      },
    )

    // define each Node's appearance
    myDiagram.nodeTemplate = this.getNodeTemplate(props.onClick, props.onAddPerson)

    // replace the default Link template in the linkTemplateMap
    myDiagram.linkTemplate = make(
      go.Link, // the whole link panel
      make(
        go.Shape, // the link shape
        {},
        new go.Binding("stroke", "color"),
      ),
      make(
        go.Shape, // the arrowhead
        { toArrow: "standard" },
        new go.Binding("stroke", "color"),
      ),
      make(
        go.Shape, // the arrowhead
        { fromArrow: "BackwardOpenTriangle", scale: 0.7 },
        new go.Binding("stroke", item => (item.backText ? item.color : "transparent")),
      ),
      make(
        go.Panel,
        "Auto",
        make(
          go.Shape, // the label background, which becomes transparent around the edges
          {
            fill: make(go.Brush, "Radial", {
              0: "rgb(240, 240, 240)",
              0.3: "rgb(240, 240, 240)",
              1: "rgba(240, 240, 240, 0)",
            }),
            stroke: null,
          },
        ),
        make(
          go.TextBlock, // the label text
          {
            textAlign: "center",
            font: "10pt helvetica, arial, sans-serif",
            stroke: "#555555",
            margin: 4,
          },
          new go.Binding("text", "text"),
          new go.Binding("stroke", "textColor"),
        ),
      ),
    )

    return myDiagram
  }

  handleDiagramEdit = e => {
    if (this.props.onEdit) this.props.onEdit(e, this.gojsDiagram.model)
  }

  handleDiagramChanged = e => {
    if (this.props.onChange) this.props.onChange(e, this.gojsDiagram.model)
  }

  render() {
    const { size } = this.props
    return <GojsComponent id="myDiagramDiv" className={`go-js-person ${size === "large" ? " go-js-person-large" : ""}`} />
  }
}

export default Gojs
