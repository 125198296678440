import React from "react"

import FormInputExtended from "@/_components/FormInputExtended"

import { getValues } from "@/_services/lists"

class PersonRoleComponent extends React.Component {
  handleSetPersonRole = patch => {
    const { handleSetPersonState } = this.props
    const { roles } = patch
    handleSetPersonState({ roles: roles.map(role => ({ role })) })
  }

  render() {
    const { person = {}, readOnly, personRoleListName = "personRole", colProps } = this.props
    const { roles = [] } = person
    const personRolesList = getValues(personRoleListName, () => this.setState({ loaded: true }))
    const personSelectedRoles = roles.map(personSelectedRole => {
      const role = personRolesList.find(personRoleListItem => personRoleListItem.value === personSelectedRole.role)
      return role || { value: personSelectedRole.role, label: `${personSelectedRole.role} (???)` }
    })

    return (
      <FormInputExtended
        obj={person}
        value={personSelectedRoles}
        field="roles"
        readOnly={readOnly}
        multiple
        label="Roles"
        select={personRoleListName}
        onSetState={patch => this.handleSetPersonRole(patch)}
        colProps={colProps}
      />
    )
  }
}

export default PersonRoleComponent
