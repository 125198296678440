import axios from "axios"

const configs = {}
let formInputOnChange
let currentTarget
let keyboardRef
let endCursorPosition

const BACKSPACE_KEY = "{bksp}"
const SPACE_KEY = "{space}"

function setRef(ref) {
  keyboardRef = ref
  if (currentTarget) keyboardRef.setInput(currentTarget.value)
}

function setFormInputChangeHandler(event, formInputChangeHandler) {
  formInputOnChange = formInputChangeHandler
  currentTarget = event.target
  endCursorPosition = currentTarget.selectionEnd

  if (keyboardRef) keyboardRef.setInput(event.target.value)
}

function unsetChangeHandler() {
  formInputOnChange = undefined
  currentTarget = undefined
}

function onKeyPress(keyPressed) {
  if (typeof formInputOnChange !== "function" || !keyboardRef || !currentTarget) return

  const hasPressedBackspace = keyPressed === BACKSPACE_KEY
  const hasPressedSpace = keyPressed === SPACE_KEY
  if (keyPressed.startsWith("{") && !hasPressedBackspace && !hasPressedSpace) return
  if (hasPressedSpace) keyPressed = " "

  const keyboardValue = keyboardRef.getInput()
  const targetValue = currentTarget.value

  const currentValue = keyboardValue === targetValue ? keyboardValue : targetValue

  // the user has typed alternatively with the real and virtual keyboards
  const usedHasTypedAlternatively = keyboardValue !== targetValue
  if (usedHasTypedAlternatively) {
    const numberOfCharsTyped = targetValue.length - keyboardValue.length
    endCursorPosition += numberOfCharsTyped
    keyboardRef.setInput(targetValue)
  }

  const cursorIsAtEnd = targetValue.length === endCursorPosition
  let value
  if (cursorIsAtEnd) {
    if (hasPressedBackspace) {
      value = currentValue.slice(0, -1)
      endCursorPosition -= 1
    } else {
      value = currentValue + keyPressed
      endCursorPosition += 1
    }
  } else {
    if (hasPressedBackspace) {
      value = targetValue.slice(0, endCursorPosition - 1) + targetValue.slice(endCursorPosition)
      endCursorPosition -= 1
    } else {
      value = targetValue.slice(0, endCursorPosition) + keyPressed + targetValue.slice(endCursorPosition)
      endCursorPosition += 1
    }
  }

  if (endCursorPosition < 0) endCursorPosition = 0

  formInputOnChange({ target: { value } })
}

async function getConfig(locale) {
  if (configs[locale]) return configs[locale]
  const config = (await axios.get(`/api/script/runs/virtual-keyboards?locale=${locale}`)).data
  configs[locale] = config
  return config
}

export { getConfig, onKeyPress, setFormInputChangeHandler, setRef, unsetChangeHandler }
