import React from "react"
import { Col, Row } from "react-bootstrap"

import PersonWeb from "@/person/PersonWeb"
import PersonLegal from "@/person/PersonLegal"
import FormInput from "@/_components/FormInput"
import AddressPanel from "@/person/AddressPanel"
import LayoutCard from "@/_components/LayoutCard"
import PanelInner from "@/_components/PanelInner"
import PersonContacts from "@/person/PersonContacts"
import AddressesPanel from "@/person/AddressesPanel"
import FormInputExtended from "@/_components/FormInputExtended"
import EntityBankAccountsPanel from "@/_components/EntityBankAccountsPanel"
import PersonRegistrationComponent from "@/person/PersonRegistrationComponent"
import { loc } from "@/_services/localization"

export default class PersonProfessional extends React.Component {
  render() {
    const {
      person = {},
      tabPerson,
      detailed,
      addressExtraInfo,
      addressLanguage,
      addressRegion,
      readOnly,
      layout = {},
      modelPath,
      tabPersonModelPath,
      allowDuplicates,
      usePersonLayout,
      useProspectLayout,
      handleSetPersonState,
      handleSetTabPersonState,
      handleSetPersonAddressState,
      isProspect,
      registrationProps = {},
      showSepaMandates,
      fromProspectOrPersonPage,
      showEdit,
      showAddressOpenModalButton,
      showBankOpenModalButton = true,
      personRegistrationsModal,
    } = this.props

    const address = person.addresses?.[0] || {}
    const showFamilyEmploymentIncomeExpenditure =
      !person.props_familyEmploymentIncomeExpenditure || !person.props_familyEmploymentIncomeExpenditure.hidden // tmp until moved to extension
    const showPersonFamily = !person.props_family || !person.props_family.hidden
    const showPersonEmployment = !person.props_employment || !person.props_employment.hidden
    const showPersonIncome = !person.props_income || !person.props_income.hidden
    const showPersonExpenditure = !person.props_expenditure || !person.props_expenditure.hidden
    const showPersonAddress = !person.props_address || !person.props_address.hidden
    const showPersonAddresses = person.props_addresses && person.props_addresses.hidden === false
    const showContact = person.props_proContact && person.props_proContact.hidden === false
    const showPersonWeb = person.props_web && person.props_web.hidden === false
    const showLegal = !person.props_legal || !person.props_legal.hidden
    const showSecondName = !person.props_secondName || !person.props_secondName.hidden
    const showPhone = !person.props_phone || !person.props_phone.hidden
    const showRegistration = person.props_registration?.hidden !== true
    const showAddressLine = !person.props_addressLine || !person.props_addressLine.hidden
    const showLastName = !person.props_lastName || !person.props_lastName.hidden
    const registrationColProps = person.props_registration?.colProps || { xs: 12, sm: 6, md: 2 }

    let personTitleMd = 1
    if (!showSecondName) personTitleMd += 1

    let firstOrLastNameMd = 3
    if (!showSecondName) firstOrLastNameMd += 1
    if (!showRegistration) firstOrLastNameMd += 1

    const layoutCards =
      usePersonLayout || useProspectLayout
        ? layout.cards
            ?.filter(card => !card.props?.hidden)
            .map((card, index) => {
              const { props = {} } = card
              const { colProps, useParentEntityPerson } = props
              const { xs = 12, sm, md, lg } = colProps || {}
              props.noCard = true
              return (
                <Col {...{ xs, sm, md, lg }} key={index}>
                  <LayoutCard
                    key={index}
                    card={card}
                    serverRoute="/api/person/persons"
                    readOnly={readOnly}
                    onSetState={useParentEntityPerson ? handleSetTabPersonState : handleSetPersonState}
                    {...(props || {})}
                    entity={useParentEntityPerson ? tabPerson : person}
                    noCard
                    modelPath={useParentEntityPerson ? tabPersonModelPath : modelPath}
                  />
                </Col>
              )
            })
        : []

    return (
      <div className="person-professional">
        <Row>
          {showRegistration && (
            <PersonRegistrationComponent
              readOnly={readOnly}
              person={person}
              personType={person.type}
              label="Registration number"
              mandatory
              onSetState={handleSetPersonState}
              modelPath={modelPath}
              allowDuplicates={allowDuplicates}
              colProps={registrationColProps}
              isProspect={isProspect}
              {...registrationProps}
              {...(person?.props_registration || {})}
              fromProspectOrPersonPage={fromProspectOrPersonPage}
              showEdit={showEdit}
              personRegistrationsModal={personRegistrationsModal}
            />
          )}

          <FormInput
            readOnly={readOnly}
            obj={person}
            field="title"
            select="personTitle"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, md: personTitleMd }}
          />
          <FormInput
            debounce
            obj={person}
            field="firstName"
            readOnly={readOnly}
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, md: firstOrLastNameMd }}
          />
          {showLastName && (
            <FormInput
              debounce
              mandatory
              obj={person}
              field="lastName"
              readOnly={readOnly}
              modelPath={modelPath}
              onSetState={handleSetPersonState}
              colProps={{ xs: 12, md: firstOrLastNameMd }}
            />
          )}
          {showSecondName && (
            <FormInput
              debounce
              obj={person}
              field="secondName"
              readOnly={readOnly}
              modelPath={modelPath}
              onSetState={handleSetPersonState}
              colProps={{ xs: 12, md: firstOrLastNameMd }}
            />
          )}
          {showPhone && (
            <FormInput
              debounce
              type="phone"
              obj={person}
              field="phone"
              readOnly={readOnly}
              modelPath={modelPath}
              colProps={{ xs: 12, sm: 3 }}
              onSetState={handleSetPersonState}
            />
          )}
          <FormInput
            debounce
            type="phone"
            obj={person}
            field="mobile"
            readOnly={readOnly}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 3 }}
            onSetState={handleSetPersonState}
          />
          <FormInput
            debounce
            type="email"
            obj={person}
            field="email"
            readOnly={readOnly}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 3 }}
            onSetState={handleSetPersonState}
          />
          <FormInput
            obj={person}
            field="legalForm"
            select="legalForm"
            readOnly={readOnly}
            colProps={{ xs: 12, sm: 3 }}
            onSetState={handleSetPersonState}
            modelPath={modelPath}
          />
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="preferredLanguage"
            select="preferredLanguage"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 3 }}
          />

          {showAddressLine && !detailed && (
            <FormInputExtended
              obj={address}
              type="address"
              readOnly={readOnly}
              extraInfo={addressExtraInfo}
              language={addressLanguage}
              region={addressRegion}
              onSetState={handleSetPersonAddressState}
              colProps={{ xs: 12 }}
              modelPath={modelPath}
            />
          )}
        </Row>

        <Row className={detailed ? "" : "d-none"}>
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="nationality"
            select="country"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 4 }}
          />
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="birthDate"
            isBirthDate
            type="date"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 4 }}
          />
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="birthPlace"
            onSetState={handleSetPersonState}
            modelPath={modelPath}
            colProps={{ xs: 12, sm: 4 }}
          />
        </Row>

        {showPersonWeb && (
          <PersonWeb
            person={person}
            readOnly={readOnly}
            modelPath={(modelPath ? `${modelPath}.` : "") + "web"}
            handleSetPersonState={handleSetPersonState}
            show={detailed}
          />
        )}

        {showContact && (
          <PersonContacts
            mandatory
            show={detailed}
            person={person}
            collapse={false}
            readOnly={readOnly}
            handleSetPersonState={handleSetPersonState}
          />
        )}

        {showPersonAddress && (
          <AddressPanel
            entity={person}
            handleSetEntityState={handleSetPersonState}
            readOnly={readOnly}
            extraInfo={addressExtraInfo}
            language={addressLanguage}
            region={addressRegion}
            modelPath={modelPath}
            show={detailed}
            showOpenModalButton={showAddressOpenModalButton}
          />
        )}

        {showPersonAddresses && (
          <AddressesPanel
            entity={person}
            handleSetEntityState={handleSetPersonState}
            readOnly={readOnly}
            extraInfo={addressExtraInfo}
            language={addressLanguage}
            region={addressRegion}
            modelPath={modelPath}
            show={detailed}
            showOpenModalButton={showAddressOpenModalButton}
          />
        )}

        <EntityBankAccountsPanel
          entity={person}
          hidden={!detailed}
          readOnly={readOnly}
          entityName="Person"
          modelPath={modelPath}
          showModal={showBankOpenModalButton}
          showSepaMandates={showSepaMandates}
          handleSetEntityState={handleSetPersonState}
        />

        {showFamilyEmploymentIncomeExpenditure && (
          <Row className={detailed ? "" : "d-none"}>
            {showPersonFamily && (
              <Col xs={12} sm={6}>
                <PanelInner collapse={false} title="Family">
                  <Row>
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="familyMaritalStatus"
                      label={loc`Marital status`}
                      select="maritalStatus"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="familyMaritalContract"
                      label={loc`Marital contract`}
                      select="maritalContract"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="familyNumberOfDependents"
                      label={loc`Number of dependents`}
                      type="number"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                  </Row>
                </PanelInner>
              </Col>
            )}

            {showPersonEmployment && (
              <Col xs={12} sm={6}>
                <PanelInner collapse={false} title="Employment">
                  <Row>
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="employmentJobDescription"
                      label={loc`Job description`}
                      select="jobDescription"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: person?.props_employmentSector?.hidden ? 12 : 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="employmentSector"
                      select="employmentSector"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: person?.props_employmentJobDescription?.hidden ? 12 : 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="employmentContractType"
                      select="employmentContractType"
                      label={loc`Contract type`}
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: person?.props_employmentStartDate?.hidden ? 12 : 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="employmentStartDate"
                      type="date"
                      onSetState={handleSetPersonState}
                      colProps={{ xs: 12, sm: person?.props_employmentContractType?.hidden ? 12 : 6 }}
                    />
                  </Row>
                </PanelInner>
              </Col>
            )}
          </Row>
        )}

        {showFamilyEmploymentIncomeExpenditure && (
          <Row className={detailed ? "" : "d-none"}>
            {showPersonIncome && (
              <Col xs={12} sm={6}>
                <PanelInner collapse={false} title="Income (monthly net)">
                  <Row>
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeSource"
                      label={loc`Source of income`}
                      select="incomeSource"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeSalary"
                      label={loc`Salary`}
                      type="currency"
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeHousing"
                      label={loc`Housing income`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeAllocation"
                      label={loc`Allocation received`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeOther"
                      label={loc`Other income`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="incomeTotal"
                      label={loc`Total income`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                  </Row>
                </PanelInner>
              </Col>
            )}

            {showPersonExpenditure && (
              <Col xs={12} sm={6}>
                <PanelInner collapse={false} title="Expenditure (monthly net)">
                  <Row>
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureHousingMonthlyRental"
                      label={loc`Rental`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureHousingMortgageRepayment"
                      label={loc`Mortgage repayment`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureOtherLoanRepayment"
                      label={loc`Other loans`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureAlimony"
                      label={loc`Child maintenance`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureTaxes"
                      label={loc`Taxes (monthly)`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                    <FormInput
                      readOnly={readOnly}
                      obj={person}
                      field="expenditureTotal"
                      label={loc`Total expenditure`}
                      type="currency"
                      //TODO add currency props
                      onSetState={handleSetPersonState}
                      modelPath={modelPath}
                      colProps={{ xs: 12, sm: 6 }}
                    />
                  </Row>
                </PanelInner>
              </Col>
            )}
          </Row>
        )}

        {showLegal && (
          <PersonLegal person={person} modelPath={modelPath} readOnly={readOnly} handleSetPersonState={handleSetPersonState} show={detailed} />
        )}

        {detailed && layoutCards?.length > 0 && <Row>{layoutCards}</Row>}
      </div>
    )
  }
}
