import { getFieldProps, getFilterFunction } from "basikon-common-utils"
import debounce from "lodash.debounce"
import get from "lodash.get"
import set from "lodash/set"
import React, { Suspense } from "react"
import { Col, ControlLabel, FormGroup, InputGroup, Row } from "react-bootstrap"

import FormInput from "@/_components/FormInput"
import FormInputExtended from "@/_components/FormInputExtended"
const MapMarkerModal = React.lazy(() => import("@/_components/MapMarkerModal.jsx"))

import {
  canGetAddressFromCoordinates,
  getAddressDetails,
  getAddressFromCoordinates,
  getAddressOptions,
  normalizeAddress,
  parseAddress,
} from "@/_services/address"
import { getLeafFieldName, getNestedFieldName } from "@/_services/inputUtils"
import { getLabel, getList, getValues } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { getOptions } from "@/_services/userConfiguration"
import { DEFAULT_DEBOUNCE, labelFromName } from "@/_services/utils"

class FormInputAddress extends React.Component {
  state = {
    showMapMarkerModal: false,
    streetViewLinkProvider: getOptions("streetViewLinkProvider") || "",
  }

  componentDidMount() {
    getList("country", () => this.setState({ countryListLoaded: true }))
    getList("addressType", () => this.setState({ addressTypeListLoaded: true }))
  }

  getAddresses = async search => {
    const { defaultAddresses = [], creatable = true, provider, language, region, country } = this.props

    let options = []
    if (search?.trim()) {
      options = toOptions(filterAddresses(defaultAddresses, search))

      if (options.length === 0 && creatable) {
        const addresses = await getAddressOptions({ search, provider, language, region, country })
        if (Array.isArray(addresses)) options = addresses
      }
    } else {
      options = toOptions(defaultAddresses)
    }
    return Promise.resolve(options)
  }

  handleBlur = async () => {
    const { obj, onSetState } = this.props
    if (obj && onSetState) onSetState(await normalizeAddress(obj)) // Format address line
  }

  handleSetStateSync = async (patch, fieldName) => {
    const { defaultAddresses = [], onSetState, obj, field, addressType, provider, language, region, country, extraInfo } = this.props
    patch = patch || {}

    // ex: "addresses[x].addressLine" (defined mainly in layout cards)
    let leafFieldName
    if (field?.includes(".")) {
      const value = get(patch, fieldName)
      leafFieldName = getLeafFieldName(fieldName)
      patch = { [leafFieldName]: value }
    }

    if ("addressLine" in patch) {
      // TODO fix bug in personEditAddressModal: clear address, enter new address line then save, error - fileType is required
      if (!patch.addressLine) {
        patch._id = null
        patch.type = null
        patch.addressLine = null
        patch.city = null
        patch.state = null
        patch.zipcode = null
        patch.cityCode = null
        patch.country = null
        patch.address1 = null
        patch.address2 = null
        patch.location = null
        patch.streetName = null
        patch.streetType = null
        patch.streetNumber = null
        patch.door = null
        patch.floor = null
        patch.block = null
        patch.division = null
        patch.subDivision = null
        patch.zones = null
        patch.epciCode = null
        patch.epciLabel = null
        // patch.taxAreaId = null
        // patch.asOfDate = null
        // patch.confidenceIndicator = null
        // patch.invalidAddress = null
        // patch.invalidAddressDate = null
        const addressConfig = getOptions("address")
        if (addressConfig?.fields?.length)
          addressConfig.fields.forEach(f => {
            patch[f.name] = null
          })
      } else if (fieldName !== "coordinates") {
        // This flags comes from the react-select creatable component
        if (patch._isNew) {
          patch = await parseAddress({ addressLine: patch.addressLine, countries: getValues("country"), defaultType: addressType })
        } else {
          let address = defaultAddresses.find(a => a.addressLine === patch.addressLine)
          if (address) patch = await normalizeAddress(address, addressType)
          else {
            address = await getAddressDetails({ placeId: patch.addressLine, provider, language, region, extraInfo, country })
            if (address) patch = await normalizeAddress(address, addressType)
            else patch = await parseAddress({ addressLine: patch.addressLine, countries: getValues("country"), defaultType: addressType })
          }
        }
      }
      for (const key in patch) {
        patch[`props_${key}`] = { ...(patch[`props_${key}`] || {}), touched: true }
      }
    }

    // ex: "addresses[x].addressLine" (defined mainly in layout cards)
    if (field?.includes(".")) {
      let nestedFieldName = getNestedFieldName(field) // "addresses[x].addressLine" => "addresses[x]"
      const isMultipleFieldsPatch = Object.keys(patch).length > 1

      // nestedField is an Array (ex: "addresses[x]")
      if (nestedFieldName.endsWith("]")) {
        nestedFieldName = nestedFieldName.replace(`[${nestedFieldName.split("[").pop()}`, "") // "addresses[x]" => "addresses"

        // "addresses[x].addressLine" => ["addresses", "x", "addressLine"]
        let setPath = field.replace(/\[/g, " ").replace(/\]/g, "").replace(/\./g, " ").split(" ")
        setPath.shift() // ["addresses", "x", "addressLine"] => ["x", "addressLine"]
        setPath = setPath.slice(0, -1) // ["x", "addressLine"] => ["x"]
        setPath = setPath.filter(p => p)
        if (leafFieldName && !isMultipleFieldsPatch) setPath.push(leafFieldName) // ["x", "streetType"]

        const nestedArray = get(obj, nestedFieldName)
        const copyNestedArray = nestedArray?.map(item => ({ ...(item || {}) })) // copy nested array to keep prevState

        if (isMultipleFieldsPatch) set(copyNestedArray, setPath, patch)
        else if (leafFieldName) set(copyNestedArray, setPath, patch[leafFieldName])

        patch = { [nestedFieldName]: copyNestedArray }
      } else {
        // it is up to the parent components to merge nested fields with their data
        patch = { [nestedFieldName]: patch }
      }
    }

    // TODO: Support address details field if field name is a path (fields?.includes(".") === true)
    if (onSetState) onSetState(patch, fieldName)
  }

  handleSetState = debounce(this.handleSetStateSync, DEFAULT_DEBOUNCE)

  handleMapMarkerModalClose = async coordinates => {
    const { onSetState, obj, addressType, extraInfo, language, country } = this.props
    if (coordinates && onSetState) {
      if (!obj?.addressLine) {
        const address = await getAddressFromCoordinates({ coordinates, extraInfo, language, country })
        if (address) this.handleSetStateSync(await normalizeAddress(address, addressType), "coordinates")
      } else this.handleSetStateSync({ location: { type: "POINT", coordinates } }, "coordinates")
    }
    this.setState({ showMapMarkerModal: false })
  }

  computeFieldName = addressField => {
    const { field = "addressLine" } = this.props

    if (field?.includes(".")) {
      const nestedFieldName = getNestedFieldName(field)
      if (nestedFieldName) return `${nestedFieldName}.${addressField}`
    }

    return addressField
  }

  render() {
    const { showMapMarkerModal, streetViewLinkProvider } = this.state
    let {
      obj,
      readOnly,
      showDetails,
      singleRow,
      actionClassName,
      colProps,
      fieldsColProps,
      field = "addressLine",
      postField,
      creatable = true,
      label: fieldLabel,
      modelPath,
      extraInfo,
      provider,
      ...props
    } = this.props

    const _canGetAddressFromCoordinates = canGetAddressFromCoordinates({ provider })

    let coordinates = obj?.location?.coordinates
    if (field?.includes(".")) {
      let nestedFieldName = getNestedFieldName(field)
      const nestedObj = get(obj, nestedFieldName)
      coordinates = nestedObj?.location?.coordinates
    }
    if (!actionClassName && coordinates) actionClassName = "text-primary"

    let mapMarkerModal
    if (showMapMarkerModal) {
      const fieldProps = getFieldProps(obj, field)
      const disabled = fieldProps?.disabled === true

      mapMarkerModal = (
        <Suspense fallback={null}>
          <MapMarkerModal
            disabled={disabled || readOnly}
            onClose={this.handleMapMarkerModalClose}
            coordinates={coordinates}
            markers={
              coordinates && [
                {
                  color: "green",
                  location: { coordinates },
                  description: get(obj, field),
                },
              ]
            }
          />
        </Suspense>
      )
    }

    let addressFields = ["address1", "address2", "zipcode", "city", "country"]
    let fieldsLabels = {}
    const selectFields = { country: "country", streetType: "streetType" }
    const multipleSelectFields = ["zones"]
    const fieldTypes = {}

    if (showDetails) {
      const defaultColProps = {
        address1: singleRow ? { xs: 12, sm: 6, md: 3 } : { xs: 12 },
        address2: singleRow ? { xs: 12, sm: 6, md: 3 } : { xs: 12 },
        zipcode: singleRow ? { xs: 12, sm: 4, md: 2 } : { xs: 12, sm: 2 },
        city: singleRow ? { xs: 12, sm: 4, md: 2 } : { xs: 12, sm: 5 },
        country: singleRow ? { xs: 12, sm: 4, md: 2 } : { xs: 12, sm: 5 },
      }
      fieldsColProps = fieldsColProps || {}
      for (const field of addressFields) fieldsColProps[field] = { ...defaultColProps[field], ...(fieldsColProps[field] || {}) }

      const addressConfig = getOptions("address")
      if (addressConfig?.fields?.length) {
        addressFields = addressConfig.fields.map(f => f.name)
        for (const field of addressConfig.fields) {
          fieldsColProps[field.name] = field.colProps
          if (field.label) fieldsLabels[field.name] = field.label
          else fieldsLabels[field.name] = labelFromName(field.name)
          if (field.type) fieldTypes[field.name] = field.type
          if (field.select) selectFields[field.name] = field.select
        }
      }
    }

    if (readOnly) {
      const value = get(obj, field)
      const addressLineField = (
        <Col {...(colProps || { xs: 12 })}>
          <FormGroup>
            <ControlLabel>{loc`Address`}</ControlLabel>
            <InputGroup data-disabled>
              {_canGetAddressFromCoordinates && (
                <InputGroup.Addon className="pdr-0 c-pointer" onClick={() => this.setState({ showMapMarkerModal: true })}>
                  <i className="icn-map-marker icn-xs text-primary" />
                </InputGroup.Addon>
              )}
              <div className="form-control" disabled>
                {value}
              </div>
            </InputGroup>
          </FormGroup>
        </Col>
      )

      if (!showDetails)
        return (
          <>
            {addressLineField}
            {postField}
            {mapMarkerModal}
          </>
        )

      return (
        <>
          {addressLineField}
          {postField}
          {addressFields.map(field => {
            const fieldName = this.computeFieldName(field)

            return (
              <FormInput
                readOnly
                obj={obj}
                key={field}
                field={fieldName}
                modelPath={modelPath}
                label={fieldsLabels[field]}
                colProps={fieldsColProps?.[field]}
                select={selectFields[field]}
              />
            )
          })}
          {mapMarkerModal}
        </>
      )
    }

    // Embeed street view link in the label
    const streetViewProps = {}
    if (field === "addressLine" && obj.location?.coordinates?.length === 2 && streetViewLinkProvider === "Google") {
      streetViewProps.labelLinkTo = `http://maps.google.com/?cbll=${obj.location.coordinates[0]},${obj.location.coordinates[1]}&cbp=12,20.09,,0,5&layer=c`
      streetViewProps.labelLinkToIcon = "icn-street-view-light"
    }

    const addressLineField = (
      <FormInput
        showCopy
        creatable={creatable}
        obj={obj}
        field={field}
        label={fieldLabel || "Address line"}
        readOnly={readOnly}
        placeholder="Type for quick address"
        colProps={colProps || { xs: 12 }}
        actionIcon="icn-map-marker icn-xs"
        actionClassName={actionClassName}
        action={creatable && _canGetAddressFromCoordinates && (() => this.setState({ showMapMarkerModal: true }))}
        {...streetViewProps}
        {...props} // we shouldn't do this, this is bad, we don't know which props are required now
        select={this.getAddresses}
        extraInfo={extraInfo}
        // debounce
        onSetState={patch => this.handleSetState(patch, field)}
        modelPath={modelPath}
      />
    )
    if (!showDetails)
      return (
        <>
          {addressLineField}
          {postField}
          {mapMarkerModal}
        </>
      )

    const addressInputs = addressFields.map(field => {
      const fieldName = this.computeFieldName(field)
      if (multipleSelectFields.includes(field)) {
        return (
          <FormInputExtended
            obj={obj}
            key={field}
            field={fieldName}
            select={multipleSelectFields.includes(field) ? field : undefined}
            multiple={true}
            readOnly={readOnly}
            onBlur={this.handleBlur}
            onSetState={patch => this.handleSetState(patch, fieldName)}
            colProps={fieldsColProps?.[field]}
            modelPath={modelPath}
            label={fieldsLabels[field]}
            type={
              fieldTypes[field] ? fieldTypes[field] : field === "invalidAddress" ? "checkbox" : field === "invalidAddressDate" ? "date" : undefined
            }
          />
        )
      }
      return (
        <FormInput
          obj={obj}
          key={field}
          field={fieldName}
          select={selectFields[field]}
          readOnly={readOnly}
          onBlur={this.handleBlur}
          onSetState={patch => this.handleSetState(patch, fieldName)}
          colProps={fieldsColProps?.[field]}
          modelPath={modelPath}
          label={fieldsLabels[field]}
          type={fieldTypes[field] ? fieldTypes[field] : field === "invalidAddress" ? "checkbox" : field === "invalidAddressDate" ? "date" : undefined}
        />
      )
    })

    return (
      <>
        {addressLineField}
        {postField}
        {singleRow ? (
          <Col md={12}>
            <Row>{addressInputs}</Row>
          </Col>
        ) : (
          addressInputs
        )}
        {mapMarkerModal}
      </>
    )
  }
}

export default FormInputAddress

function filterAddresses(addresses, filter) {
  if (!filter || !filter?.trim()) return addresses
  const includes = getFilterFunction(filter, getLocale())

  return (addresses || []).filter(address => {
    return (
      includes(address.address1) ||
      includes(address.address2) ||
      includes(address.city) ||
      includes(address.zipcode) ||
      includes(address.state) ||
      includes(address.country) ||
      includes(address.addressLine) ||
      includes(address.division) ||
      includes(address.subDivision) ||
      includes(address.streetNumber) ||
      includes(address.streetType) ||
      includes(address.streetName)
    )
  })
}

function toOptions(addresses) {
  return (addresses || []).map(address => ({
    value: address.addressLine,
    label: `${address.addressLine} ${address.type ? `(${getLabel("addressType", address.type)})` : ""}`,
  }))
}
