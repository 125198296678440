import { genericErrors } from "./errorUtils.mjs"

// Standard amortizing loans (Excel)
function FV(rate, nper, pmt, pv, type) {
  const term = Math.pow(1 + rate, nper)
  type = type ? 1 : 0
  if (!pv) pv = 0
  const fv = -((pmt * (1 + rate * type) * (term - 1)) / rate + pv * term)
  return fv
}

function PV(rate, nper, pmt, fv, type) {
  const term = Math.pow(1 + rate, nper)
  type = type ? 1 : 0
  if (!fv) fv = 0
  const pv = (fv - (pmt * (1 + rate * type) * (term - 1)) / rate) / term
  return pv
}

function NPV(rate, payments) {
  return payments.reduce((acc, payment, index) => acc + PVCalc(payment, rate, index + 1), 0)
}

function IPMT(rate, per /* 1 based */, nper, pv, fv, type) {
  type = type ? 1 : 0
  if (rate === 0) return 0
  const pmt = PMT(rate, nper, pv, fv, type)
  let ipmt = FV(rate, per - 1, pmt, pv, type) * rate
  if (type === 1) ipmt /= 1 + rate
  return ipmt
}

function PPMT(rate, per /* 1 based */, nper, pv, fv, type) {
  return PMT(rate, nper, pv, fv, type) - IPMT(rate, per, nper, pv, fv, type)
}

function PMT(rate, nper, pv, fv, type = 0) {
  type = type ? 1 : 0
  if (!fv) fv = 0
  if (rate === 0) {
    const pmt = (-pv - fv) / nper
    return pmt
  } else {
    const term = Math.pow(1 + rate, nper)
    let pmt = (-pv - fv / term) / ((1 - 1 / term) / rate) // http://www.tvmcalcs.com/index.php/calculators/apps/lease_payments
    if (type === 1) {
      pmt = pmt / (1 + rate)
    }
    return pmt
  }
}

// this function is equivalent to PMT when nb is 1
// note that if nb=3 the last payments will be 0
// compare with http://www.tvmcalcs.com/index.php/calculators/apps/lease_payments
// {
// const PV = -3500
// const FV = 1000
// const rate = 0.0075
// const nper = 24
// const nb = 3

// const pmt = finance.PMT(rate, nper, PV, FV, 1)
// const pmt2 = finance.PMTWithPaymentsInAdvance(rate, nper, PV, FV, nb)

// console.log(pmt, pmt2)
// }
export function PMTWithPaymentsInAdvance(rate, nper, pv, fv, nb) {
  if (!fv) fv = 0
  if (!nb) nb = 0
  if (rate === 0) {
    const pmt = (-pv - fv) / (nper + nb - 1)
    return pmt
  } else {
    const term = Math.pow(1 + rate, nper)
    const term2 = Math.pow(1 + rate, nper - nb)
    const pmt = (-pv - fv / term) / ((1 - 1 / term2) / rate + nb) // http://www.tvmcalcs.com/index.php/calculators/apps/lease_payments
    return pmt
  }
}

// linear loans (term loans)
function linearLoanIPMT(rate, per, nper, pv) {
  const ppmt = linearLoanPPMT(rate, nper, pv)
  const ipmt = (-pv - ppmt * (per - 1)) * rate
  return ipmt
}

function linearLoanPPMT(rate, nper, pv) {
  const ppmt = -pv / nper
  return ppmt
}

function linearLoanPMT(rate, per, nper, pv) {
  return linearLoanPPMT(rate, nper, pv) + linearLoanIPMT(rate, per, nper, pv)
}

// weightedLinearLoan terms (ex: 40-30-20-10)
function _getFunctionweight(nper, weights) {
  if (Array.isArray(weights)) {
    return i => weights[i] || 0
  } else if (typeof weights === "function") {
    return weights
  } else if (!weights) {
    return () => 1 / nper
  }
  throw Error(genericErrors.INVALID_ARRAY_OR_FUNCTION_FIELD + "'weights'")
}

function weightedLinearLoanIPMT(rate, per, nper, pv, weights) {
  const weightFunction = _getFunctionweight(nper, weights)
  let sumweight = 0
  let totalweight = 0
  for (let i = 0; i < nper; i++) {
    totalweight += weightFunction(i)
    if (i < per - 1) sumweight += weightFunction(i)
  }
  const sumppmt = (-pv * sumweight) / totalweight
  const ipmt = (-pv - sumppmt) * rate
  return ipmt
}

function weightedLinearLoanPPMT(rate, per, nper, pv, weights) {
  const weightFunction = _getFunctionweight(nper, weights)
  let totalweight = 0
  for (let i = 0; i < nper; i++) {
    totalweight += weightFunction(i)
  }
  const ppmt = (-pv * weightFunction(per - 1)) / totalweight
  return ppmt
}

function weightedLinearLoanPMT(rate, per, nper, pv, weights) {
  return weightedLinearLoanPPMT(rate, per, nper, pv, weights) + weightedLinearLoanIPMT(rate, per, nper, pv, weights)
}

// constant weighted instalments
function solveConstantInstallement(weights, rate, nper, pv, fv = 0, type = 0) {
  const nweights = [...weights]
  let sum = 0
  for (let i = 0; i < nweights.length; i++) sum += nweights[i]
  for (let i = 0; i < nweights.length; i++) nweights[i] *= nper / sum // normalize

  const pmt = PMT(rate, nper, pv, fv)
  let min = 0
  let max = pmt * 10
  let guess
  let lastGuess = max
  const notSame = true
  let iter = 0
  //console.log("xxx", sum, nbNotnull, min, max, ...nweights)

  const guessTimesItem = item => guess * item

  do {
    guess = (min + max) / 2
    if (iter > 1 && Math.abs(lastGuess - guess) < 0.0000000000000000001) break
    lastGuess = guess

    // compute cashflows
    const cashflows = []
    if (!type) cashflows.push(pv)
    cashflows.push(...nweights.map(guessTimesItem))
    if (type) cashflows[0] += pv
    if (type) cashflows.push(fv)
    else cashflows[cashflows.length - 1] += fv

    const irr = IRR(cashflows)

    //console.log("N°" + iter, min.toFixed(13), "<", guess.toFixed(13), "<", max.toFixed(13), irr, ...(cashflows.map(i => i.toFixed(2))))

    if (irr < rate) min = guess
    else max = guess

    if (isNaN(Math.abs(irr - rate)) || Math.abs(irr - rate) < 0.0000000000000000001) break

    iter++
  } while (notSame)
  console.log("Iter instalments", iter)
  const weightedinstalments = nweights.map(item => guess * item)
  return weightedinstalments
}

// constant weighted instalments
function solveConstantInstallementWithFirstPayment(first, rate, nper, pv, fv = 0, type = 0) {
  const pmt = PMT(rate, nper, pv - first, fv)
  let min = 0
  let max = pmt * 10
  let guess
  let lastGuess = max
  const notSame = true
  let iter = 0
  //console.log("xxx", sum, nbNotnull, min, max, ...nweights)

  do {
    guess = (min + max) / 2
    if (iter > 1 && Math.abs(lastGuess - guess) < 0.0000000000000000001) break
    lastGuess = guess

    // compute cashflows
    const cashflows = []
    if (!type) cashflows.push(pv)
    cashflows.push(first)
    for (let i = 1; i < nper; i++) cashflows.push(guess)
    if (type) cashflows[0] += pv
    if (type) cashflows.push(fv)
    else cashflows[cashflows.length - 1] += fv

    // let irr = IRR(cashflows)
    // console.log("N°" + iter, min.toFixed(13), "<", guess.toFixed(13), "<", max.toFixed(13), irr, ...(cashflows.map(i => i.toFixed(2))))
    // if (irr < rate) min = guess
    // else max = guess
    // if (Math.abs(irr - rate) < 0.0000000000000000001) break

    let npv = 0
    cashflows.forEach((cashflow, i) => (npv += PVCalc(cashflow, rate, cashflows.length - i)))

    if (npv < 0) min = guess
    else max = guess

    if (Math.abs(npv) < 0.0000000000000000001) break

    iter++
  } while (notSame)
  console.log("Iter instalments", iter, guess)
  return guess
}

function weightedConstantLoanIPMT(rate, per, nper, pv, weightedinstalments) {
  let sum = 0
  for (let i = 0; i < per - 1; i++) {
    sum += weightedinstalments[i]
  }
  const ipmt = (-pv - sum) * rate
  return ipmt
}

function weightedConstantLoanPPMT(rate, per, nper, pv, weightedinstalments) {
  const ppmt = weightedinstalments[per - 1] - weightedConstantLoanIPMT(rate, per, nper, pv, weightedinstalments)
  return ppmt
}

// IRR
// function NPVCalc(moneys, interest) {
// let res = 0
// moneys.forEach(function(money, n) {
// res += PVCalc(money, interest, n + 1)
// })
// return res
// }

function PVCalc(money, interest, n) {
  return money / Math.pow(1 + interest, n)
}

function IRR(CArray) {
  // this is an attempt to simulate Excel behavior e.g:
  // =RATE(10;110;-1000) => 1,77154269%
  // =RATE(10;-110;1000) => 1,77154269% so same value
  // therefore if first cashflow is positive, change all signs
  if (CArray && CArray[0] && CArray[0] > 0) CArray = CArray.map(it => -it)

  let min = -1.0
  let max = 1.0
  let guess
  let lastGuess = max
  let notSame = true
  let NPV = 0
  do {
    NPV = 0
    guess = (min + max) / 2
    if (Math.abs(lastGuess - guess) < 0.0000000000000000001) notSame = false
    lastGuess = guess
    for (let j = 0; j < CArray.length; j++) {
      NPV += PVCalc(CArray[j], guess, j)
    }
    if (NPV > 0) {
      min = guess
    } else {
      max = guess
    }
  } while (notSame && Math.abs(NPV) > 0.0000000000000000001)
  return guess
}

function RATE(nper, pmt, pv, fv, type) {
  fv = fv || 0
  const cashflows = []
  if (!type) cashflows.push(pv)
  for (let i = 0; i < nper; i++) cashflows.push(pmt)
  if (type) cashflows[0] += pv
  if (type) cashflows.push(fv)
  else cashflows[cashflows.length - 1] += fv
  const irr = IRR(cashflows)
  return irr
}

const xirrErrors = {
  MISSING_OR_INVALID_CASHFLOWS_AND_DATES: "MISSING_OR_INVALID_CASHFLOWS_AND_DATES|Cashflows and dates arrays are invalid|",
  INVALID_TRANSACTIONS_SAME_DAY: "INVALID_TRANSACTIONS_SAME_DAY|Transactions must not all be on the same day|",
  INVALID_TRANSACTIONS_ALL_NEGATIVE: "INVALID_TRANSACTIONS_ALL_NEGATIVE|Transactions must not all be positive|",
  INVALID_TRANSACTIONS_ALL_POSITIVE: "INVALID_TRANSACTIONS_ALL_POSITIVE|Transactions must not all be negative|",
  NEWTON_RAPHSON_FAILED_TO_CONVERGE: "NEWTON_RAPHSON_FAILED_TO_CONVERGE|Newton-Raphson algorithm did not converge|",
}

// https://github.com/RayDeCampo/nodejs-xirr
function XIRR(cashflows, dates, guess) {
  if (!cashflows || !dates || !cashflows.length || cashflows.length !== dates.length) {
    throw Error(xirrErrors.MISSING_OR_INVALID_CASHFLOWS_AND_DATES)
  }

  // https://github.com/scijs/newton-raphson-method
  function newtonRaphson(f, fp, x0) {
    let x1, y, yp, yph, ymh, yp2h, ym2h
    const h = 1e-4
    let iter = 0
    while (iter++ < 20) {
      // Compute the value of the function
      y = f(x0)
      if (fp) {
        yp = fp(x0)
      } else {
        // Needs numerical derivatives
        yph = f(x0 + h)
        ymh = f(x0 - h)
        yp2h = f(x0 + 2 * h)
        ym2h = f(x0 - 2 * h)
        yp = ((ym2h - yp2h + 8 * (yph - ymh)) * (1 / h)) / 12
      }
      // Check for badly conditioned update (extremely small first deriv relative to function)
      if (Math.abs(yp) <= 2.220446049250313e-16 * Math.abs(y)) {
        return false
      }
      // Update the guess
      x1 = x0 - y / yp
      // Check for convergence
      if (Math.abs(x1 - x0) <= 1e-7 * Math.abs(x1)) {
        return x1
      }
      // Transfer update to the new guess
      x0 = x1
    }
    return false
  }

  const MILLIS_PER_DAY = 1000 * 60 * 60 * 24
  const DAYS_IN_YEAR = 365
  const investments = []
  let start = Math.floor(dates[0] / MILLIS_PER_DAY)
  let end = start
  let minAmount = Number.POSITIVE_INFINITY
  let maxAmount = Number.NEGATIVE_INFINITY
  let total = 0
  let deposits = 0
  for (let i = 0; i < cashflows.length; i++) {
    total += cashflows[i]
    if (cashflows[i] < 0) {
      deposits += -cashflows[i]
    }
    const epochDays = Math.floor(dates[i] / MILLIS_PER_DAY)
    start = Math.min(start, epochDays)
    end = Math.max(end, epochDays)
    minAmount = Math.min(minAmount, cashflows[i])
    maxAmount = Math.max(maxAmount, cashflows[i])
    investments.push({
      amount: cashflows[i],
      epochDays: epochDays,
    })
  }

  if (start === end) {
    throw Error(xirrErrors.INVALID_TRANSACTIONS_SAME_DAY)
  }

  if (minAmount >= 0) {
    throw Error(xirrErrors.INVALID_TRANSACTIONS_ALL_NEGATIVE)
  }

  if (maxAmount < 0) {
    throw Error(xirrErrors.INVALID_TRANSACTIONS_ALL_POSITIVE)
  }

  investments.forEach(function (investment) {
    // Number of years (including fraction) this item applies
    investment.years = (end - investment.epochDays) / DAYS_IN_YEAR
  })

  const days = end - start
  if (maxAmount === 0) {
    return -1
  }

  function value(rate) {
    return investments.reduce(function (sum, investment) {
      // Make the vars more Math-y, makes the derivative easier to see
      const A = investment.amount
      const Y = investment.years
      if (-1 < rate) {
        return sum + A * Math.pow(1 + rate, Y)
      } else if (rate < -1) {
        // Extend the function into the range where the rate is less
        // than -100%.  Even though this does not make practical sense,
        // it allows the algorithm to converge in the cases where the
        // candidate values enter this range

        // We cannot use the same formula as before, since the base of
        // the exponent (1+rate) is negative, this yields imaginary
        // values for fractional years.
        // E.g. if rate=-1.5 and years=.5, it would be (-.5)^.5,
        // i.e. the square root of negative one half.

        // Ensure the values are always negative so there can never
        // be a zero (as long as some amount is non-zero).
        // This formula also ensures that the derivative is positive
        // (when rate < -1) so that Newton's method is encouraged to
        // move the candidate values towards the proper range

        return sum - Math.abs(A) * Math.pow(-1 - rate, Y)
      } else if (Y === 0) {
        return sum + A // Treat 0^0 as 1
      } else {
        return sum
      }
    }, 0)
  }

  function derivative(rate) {
    return investments.reduce(function (sum, investment) {
      // Make the vars more Math-y, makes the derivative easier to see
      const A = investment.amount
      const Y = investment.years
      if (Y === 0) {
        return sum
      } else if (-1 < rate) {
        return sum + A * Y * Math.pow(1 + rate, Y - 1)
      } else if (rate < -1) {
        return sum + Math.abs(A) * Y * Math.pow(-1 - rate, Y - 1)
      } else {
        return sum
      }
    }, 0)
  }

  if (guess && isNaN(guess)) {
    throw Error(genericErrors.INVALID_NUMBER_FIELD + "'guess'")
  }

  if (!guess) {
    guess = total / deposits / (days / DAYS_IN_YEAR)
  }

  const rate = newtonRaphson(value, derivative, guess)
  if (rate === false) {
    // truthiness strikes again, !rate is true when rate is zero
    throw Error(xirrErrors.NEWTON_RAPHSON_FAILED_TO_CONVERGE)
  }

  return rate
}

/**
 * Same behavior as in Excel.
 * https://support.microsoft.com/en-us/office/nominal-function-7f1ae29b-6b92-435e-b950-ad8b190ddd2b
 * @param {Number} effectiveRate The effective interest rate.
 * @param {Number} nbPerYear The number of compounding periods per year.
 * @returns {Number} Returns the nominal annual interest rate, given the effective rate and the number of compounding periods per year.
 */
function NOMINAL(effectiveRate, nbPerYear) {
  return ((1 + effectiveRate) ** (1 / nbPerYear) - 1) * nbPerYear
}

/**
 * Same behavior as in Excel.
 * https://support.microsoft.com/en-us/office/effect-function-910d4e4c-79e2-4009-95e6-507e04f11bc4
 * @param {Number} nominalRate The nominal interest rate.
 * @param {Number} nbPerYear The number of compounding periods per year.
 * @returns {Number} Returns the effective annual interest rate, given the nominal annual interest rate and the number of compounding periods per year.
 */
function EFFECT(nominalRate, nbPerYear) {
  return (1 + nominalRate / nbPerYear) ** nbPerYear - 1
}

/**
 * Same behavior as in Excel, except for the decimals arguments that has a default value.
 * https://support.microsoft.com/en-us/office/round-function-c018c5d8-40fb-4053-90b1-b3e7f61a213c
 *
 * Explanation: the rule is
 *    0.0049 rounded at 2 decimals => 0.00
 *    0.005  rounded at 2 decimals => 0.01
 *
 * But javascript way of handling float numbers makes 0.0049999999999999999 like 0.05
 * However Math.round(x / 100) * 100 will return 0
 *
 * In order to avoid the issue we add a small 0.0000001 to make sur we will round correctly:
 * ```
 * const x1 = 0.004999999999999999 // one less "9"
 * const math1 = Math.round(x1 * 100) / 100
 * const fina1 = finance.ROUND(x1, 2)
 * console.log(x1, math1, fina1, math1 === fina1) // true
 *
 * const x2 = 0.0049999999999999999 // one more "9"
 * const math2 = Math.round(x2 * 100) / 100
 * const fina2 = finance.ROUND(x2, 2)
 * console.log(x2, math2, fina2, math2 === fina2) // false
 * ```
 * @param {Number} number The number that you want to round.
 * @param {Number} [decimals=2] The number of digits to which you want to round the number argument. Default is 2.
 * @returns {Number} The rounded number.
 */
function ROUND(number, decimals = 2) {
  if (!number) return number
  const ratio = decimals === 2 ? 100 : Math.pow(10, decimals)
  const isNegative = number < 0
  const roundedNumber = Math.round(Math.abs(number) * ratio + 0.00001) / ratio
  return isNegative ? roundedNumber * -1 : roundedNumber
}

/**
 * Same comment as the ROUND function.
 *
 * @param {Number} number The number that you want to round.
 * @param {Number} [decimals=2] The number of digits to which you want to round the number argument. Default is 2.
 * @returns {Number} The rounded number.
 */
function ROUNDDOWN(number, decimals = 2) {
  if (!number) return number
  const ratio = decimals === 2 ? 100 : Math.pow(10, decimals)
  // Added 0.00001 to fix decimal problem in floating point par example 299.9 * 100 = 29989.999999999996
  return Math.floor(number * ratio + +0.00001) / ratio
}

/**
 * Same comment as the ROUND function.
 *
 * @param {Number} number The number that you want to round.
 * @param {Number} [decimals=2] The number of digits to which you want to round the number argument. Default is 2.
 * @returns {Number} The rounded number.
 */
function ROUNDUP(number, decimals = 2) {
  if (!number) return number
  const ratio = decimals === 2 ? 100 : Math.pow(10, decimals)
  return Math.ceil(number * ratio) / ratio
}

/**
 * Same comment as the ROUND function.
 *
 * @param {Number} number The number that you want to round.
 * @param {Number} [decimals=2] The number of digits to which you want to round the number argument. Default is 2.
 * @returns {Number} The rounded number.
 */
function TRUNC(number, decimals = 2) {
  if (!number) return number
  const ratio = decimals === 2 ? 100 : Math.pow(10, decimals)
  // Added 0.00001 to fix decimal problem in floating point par example 299.9 * 100 = 29989.999999999996
  return Math.trunc(number * ratio + +0.00001) / ratio
}

/**
 * This should be deprecated as it does not match the behavior of Excel.
 *
 * @param {Number} annualRate
 * @param {Number} nbPerYear
 * @returns {Number}
 */
function nominalYearlyToActuarial(annualRate, nbPerYear) {
  //nbPerYear : nb periode per year ( Monthly = 12 Quarterly 4 yearly 1 )
  return (1 + annualRate) ** (1 / nbPerYear) - 1
}

/**
 * This should be deprecated as it does not match the behavior of Excel.
 *
 * @param {Number} actuarialRate
 * @param {Number} nbPerYear
 * @returns {Number}
 */
function actuarialToNominalYearly(actuarialRate, nbPerYear) {
  //nbPerYear : nb periode per year ( Monthly = 12 Quarterly 4 yearly 1 )
  return (1 + actuarialRate) ** nbPerYear - 1
}

function getPMT(req, res) {
  const pmt = PMT(
    parseFloat(req.query.rate),
    parseInt(req.query.nper),
    parseFloat(req.query.pv),
    req.query.fv ? parseFloat(req.query.fv) : 0,
    req.query.type ? parseInt(req.query.type) : 0,
  )
  res.json({ pmt: pmt })
}

function postPMT(req, res) {
  const pmt = PMT(req.body.rate, req.body.nper, req.body.pv)
  res.json({ pmt: pmt })
}

function getRATE(req, res) {
  const rate = RATE(
    parseInt(req.query.nper),
    parseFloat(req.query.pmt),
    parseFloat(req.query.pv),
    req.query.fv && parseFloat(req.query.fv),
    req.query.type && parseInt(req.query.type),
  )
  res.json({ rate: rate })
}

function computePaymentPlan(startDate, frequency = "30", rate, nper, pv, fv = 0, type = 0) {
  type = type ? 1 : 0
  const pmt = PMT(rate, nper, pv, fv, type)
  const pp = {
    params: {
      rate,
      nper,
      pv,
      fv,
      type,
      pmt,
    },
    plan: [],
  }
  for (let i = 1; i <= nper; i++) {
    const ipmt = IPMT(rate, i, nper, pv, fv, type)
    const ppmt = pmt - ipmt
    const dt = startDate ? new Date(startDate) : undefined
    if (frequency === "90") {
      if (dt) dt.setMonth(dt.getMonth() + 3 * (i - type))
    } else if (frequency === "365") {
      if (dt) dt.setMonth(dt.getMonth() + 12 * (i - type))
    } else {
      if (dt) dt.setMonth(dt.getMonth() + i - type)
    }
    const item = {
      index: i,
      date: dt ? dt : undefined,
      principal: ppmt,
      interest: ipmt,
      payment: pmt,
    }
    pp.plan.push(item)
  }
  return pp
}

function getPaymentPlan(req, res) {
  const rate = parseFloat(req.query.rate)
  const nper = parseInt(req.query.nper)
  const pv = parseFloat(req.query.pv)
  const fv = req.query.fv ? parseFloat(req.query.fv) : 0
  const type = req.query.type ? 1 : 0
  const pp = computePaymentPlan(new Date(), "30", rate, nper, pv, fv, type)
  res.json(pp)
}

function getIRR(req, res) {
  const cashflows = req.query.cashflows
  if (!cashflows) throw Error(400)

  const carray = cashflows.split(";")
  const irr = IRR(carray)
  res.json({ cashflows: carray, irr: irr })
}

function getXIRR(req, res) {
  if (!req.query.cashflows || !req.query.dates) throw Error(400)

  const cashflows = req.query.cashflows.split(";").reduce((acc, val) => {
    acc.push(+val)
    return acc
  }, [])
  const dates = req.query.dates.split(";").reduce((acc, val) => {
    acc.push(new Date(val))
    return acc
  }, [])
  res.json({ cashflows, dates, xirr: XIRR(cashflows, dates) })
}

const goalSeekErrors = {
  NO_ACCEPTABLE_RESULT: "NO_ACCEPTABLE_RESULT|Could not find acceptable result.",
}

/**
 * WARNING - GOALSEEK requires increasing function, it will not work with decreasing function
 */
function GOALSEEK(func, targetValue, funcArgs, initialValue) {
  let min = 0
  let max = 10_000_000
  if (initialValue) {
    min = 0
    max = initialValue * 2
  } else if (initialValue === 0) {
    min = -1
    max = 1
  }
  let prevY = 0
  for (let i = 0; i < 300; i++) {
    const guess = (min + max) / 2
    const y = func(guess, funcArgs, false)[0]
    if (targetValue < y) {
      max = guess
    } else {
      min = guess
    }
    let diff = y - prevY
    if (diff < 0) diff = -diff
    if (diff < 0.000001) {
      const outputData = func(guess, funcArgs, true)[1]
      return [guess, outputData]
    }
    prevY = y
  }
  throw Error(goalSeekErrors.NO_ACCEPTABLE_RESULT)
}

export {
  actuarialToNominalYearly,
  computePaymentPlan,
  EFFECT,
  FV,
  getIRR,
  getPaymentPlan,
  getPMT,
  getRATE,
  getXIRR,
  GOALSEEK,
  IPMT,
  IRR,
  linearLoanIPMT,
  linearLoanPMT,
  linearLoanPPMT,
  NOMINAL,
  nominalYearlyToActuarial,
  NPV,
  PMT,
  postPMT,
  PPMT,
  PV,
  RATE,
  ROUND,
  ROUNDDOWN,
  ROUNDUP,
  solveConstantInstallement,
  solveConstantInstallementWithFirstPayment,
  TRUNC,
  weightedConstantLoanIPMT,
  weightedConstantLoanPPMT,
  weightedLinearLoanIPMT,
  weightedLinearLoanPMT,
  weightedLinearLoanPPMT,
  XIRR,
}
