import enI18n from "./i18n/en.mjs"
import esI18n from "./i18n/es.mjs"
import arI18n from "./i18n/ar.mjs"
import azI18n from "./i18n/az.mjs"
import ptI18n from "./i18n/pt.mjs"
import ptPTI18n from "./i18n/pt-PT.mjs"
import frI18n from "./i18n/fr.mjs"
import eoI18n from "./i18n/eo.mjs"
import itI18n from "./i18n/it.mjs"
import viI18n from "./i18n/vi.mjs"
import trI18n from "./i18n/tr.mjs"
import huI18n from "./i18n/hu.mjs"
import enIndianI18n from "./i18n/en-indian.mjs"
import ukI18n from "./i18n/uk.mjs"
import ruI18n from "./i18n/ru.mjs"
import idI18n from "./i18n/id.mjs"
import ltI18n from "./i18n/lt.mjs"

const languages = ["en", "es", "ar", "az", "pt", "fr", "eo", "it", "vi", "tr", "uk", "ru", "id", "lt"];
export const i18n = {
  en: enI18n,
  es: esI18n,
  ar: arI18n,
  az: azI18n,
  pt: ptI18n,
  ptPT: ptPTI18n,
  fr: frI18n,
  eo: eoI18n,
  it: itI18n,
  vi: viI18n,
  tr: trI18n,
  hu: huI18n,
  enIndian: enIndianI18n,
  uk: ukI18n,
  ru: ruI18n,
  id: idI18n,
  lt: ltI18n
};

const shortScale = [100];
for (let i = 1; i <= 16; i++) {
  shortScale.push(Math.pow(10, i * 3));
}

const longScale = [100, 1000];
for (let i = 1; i <= 15; i++) {
  longScale.push(Math.pow(10, i * 6));
}

writtenNumber.defaults = {
  noAnd: false,
  alternativeBase: null,
  lang: "en",
  currency: false,
  writtenDecimals: true,
  decimalSeparator: ", ",
};

/**
 * Converts numbers to their written form.
 *
 * @param {Number} n The number to convert
 * @param {Object} [options] An object representation of the options
 * @return {String} writtenN The written form of `n`
 */

export default function writtenNumber(n, options) {
  options = getOptions(options || {}, writtenNumber.defaults);

  if (n < 0) {
    return "";
  }

  const decimals = Math.round((n % 1) * 100)
  n = decimals ? Math.floor(+n) : Math.round(+n);

  let language = typeof options.lang === "string"
    ? i18n[options.lang]
    : options.lang;

  if (!language) {
    if (languages.indexOf(writtenNumber.defaults.lang) < 0) {
      writtenNumber.defaults.lang = "en";
    }

    language = i18n[writtenNumber.defaults.lang];
  }

  let result = _writtenNumber(n, options, language)
  let resultDecimals = decimals && (options.writtenDecimals ? _writtenNumber(decimals, options, language) : decimals)
  if (options?.currency && language.currencies?.[options.currency]) {
    const currency = language.currencies[options.currency]
    result += " " + (n >= 2 ? currency.plural : currency.singular)
    if (decimals) result += options.decimalSeparator + resultDecimals + " " + currency.cents
  } else if (decimals) result += options.decimalSeparator + resultDecimals
  return result
}

function _writtenNumber(n, options, language) {
  let scale = language.useLongScale ? longScale : shortScale;
  let units = language.units;
  let unit;

  if (!(units instanceof Array)) {
    let rawUnits = units;

    units = [];
    scale = Object.keys(rawUnits);

    for (let i in scale) {
      units.push(rawUnits[scale[i]]);
      scale[i] = Math.pow(10, parseInt(scale[i]));
    }
  }

  const baseCardinals = language.base;
  const alternativeBaseCardinals = options.alternativeBase
    ? language.alternativeBase[options.alternativeBase]
    : {};

  if (language.unitExceptions[n]) return language.unitExceptions[n];
  if (alternativeBaseCardinals[n]) return alternativeBaseCardinals[n];
  if (baseCardinals[n]) return baseCardinals[n];
  if (n < 100)
    return handleSmallerThan100(n, language, unit, baseCardinals, alternativeBaseCardinals, options);

  const m = n % 100;
  let ret = [];

  if (m) {
    if (
      options.noAnd &&
      !(language.andException && language.andException[10])
    ) {
      ret.push(_writtenNumber(m, options, language));
    } else {
      ret.push(language.unitSeparator + _writtenNumber(m, options, language));
    }
  }

  let firstSignificant;

  for (let i = 0, len = units.length; i < len; i++) {
    let r = Math.floor(n / scale[i]);
    let divideBy;

    if (i === len - 1) divideBy = 1000000;
    else divideBy = scale[i + 1] / scale[i];

    r %= divideBy;

    unit = units[i];

    if (!r) continue;
    firstSignificant = scale[i];

    if (unit.useBaseInstead) {
      const shouldUseBaseException =
        unit.useBaseException.indexOf(r) > -1 &&
        (unit.useBaseExceptionWhenNoTrailingNumbers
          ? i === 0 && ret.length
          : true);
      if (!shouldUseBaseException) {
        ret.push(alternativeBaseCardinals[r * scale[i]] || baseCardinals[r * scale[i]]);
      } else {
        ret.push(r > 1 && unit.plural ? unit.plural : unit.singular);
      }
      continue;
    }

    let str;
    if (typeof unit === "string") {
      str = unit;
    } else if (unit.alternativeUnit && unit.useAlternativeUnit.includes(r)) {
      str = unit.alternativeUnit;
    } else if (r === 1 || unit.useSingularEnding && r % 10 === 1
      && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0)) {
      str = unit.singular;
    } else if (unit.few && (r > 1 && r < 5 || unit.useFewEnding && r % 10 > 1 && r % 10 < 5
      && (!unit.avoidEndingRules || unit.avoidEndingRules.indexOf(r) < 0))) {
      str = unit.few;
    } else {
      str = unit.plural && (!unit.avoidInNumberPlural || !m)
        ? unit.plural
        : unit.singular;

      // Languages with dual
      str = (r === 2 && unit.dual) ? unit.dual : str;

      // "restrictedPlural" : use plural only for 3 to 10
      str = (r > 10 && unit.restrictedPlural) ? unit.singular : str;
    }

    if (
      unit.avoidPrefixException &&
      unit.avoidPrefixException.indexOf(r) > -1
    ) {
      ret.push(str);
      continue;
    }

    const exception = language.unitExceptions[r];
    const number =
      exception ||
      _writtenNumber(
        r,
        getOptions(
          {
            // Languages with and exceptions need to set `noAnd` to false
            noAnd: !((language.andException && language.andException[r]) ||
              unit.andException) && true,
            alternativeBase: unit.useAlternativeBase
          },
          options
        ),
        language
      );
    n -= r * scale[i];
    ret.push(number + " " + str);
  }

  const firstSignificantN = firstSignificant * Math.floor(n / firstSignificant);
  const rest = n - firstSignificantN;

  if (
    language.andWhenTrailing &&
    firstSignificant &&
    0 < rest &&
    ret[0].indexOf(language.unitSeparator) !== 0
  ) {
    ret = [ret[0], language.unitSeparator.replace(/\s+$/, "")].concat(
      ret.slice(1)
    );
  }

  // Languages that have separators for all cardinals.
  if (language.allSeparator) {
    for (let j = 0; j < ret.length - 1; j++) {
      ret[j] = language.allSeparator + ret[j];
    }
  }
  return ret.reverse().join(" ");
}

function handleSmallerThan100(n, language, unit, baseCardinals, alternativeBaseCardinals, options) {
  const dec = Math.floor(n / 10) * 10;
  unit = n - dec;
  if (unit) {
    return (
      alternativeBaseCardinals[dec] || baseCardinals[dec] + language.baseSeparator + _writtenNumber(unit, options, language)
    );
  }
  return alternativeBaseCardinals[dec] || baseCardinals[dec];
}

/**
 * Merges a set of default keys with a target object
 * (Like _.defaults, but will also extend onto null/undefined)
 *
 * @param {Object} [target] The object to extend
 * @param {Object} defaults The object to default to
 * @return {Object} extendedTarget
 */

function getOptions(target, defs) {
  if (target == null) target = {};
  var ret = {};
  var keys = Object.keys(defs);
  for (var i = 0, len = keys.length; i < len; i++) {
    var key = keys[i];
    ret[key] = target[key] ?? defs[key];
  }
  return ret;
}
