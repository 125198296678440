import { isEqual } from "basikon-common-utils"
import noUiSlider from "nouislider"
import React from "react"
import { v4 as uuidv4 } from "uuid"

// https://refreshless.com/nouislider
// when using several sliders in the same page you must provide different ids (props.id) otherwise the nouislider lib will mix them
class Slider extends React.Component {
  state = {
    sliderId: null,
  }

  componentDidMount() {
    const { id, start } = this.props

    this.setState(
      {
        id,
        sliderId: `slider${id === undefined ? "" : `-${id}`}-${uuidv4()}`,
        singleHandle: !Array.isArray(start) || start.length === 1,
      },
      () => this.createSlider(),
    )
  }

  componentDidUpdate(prevProps) {
    const { sliderId } = this.state
    const slider = document.getElementById(sliderId)
    if (slider?.noUiSlider && !isEqual(prevProps.range, this.props.range)) {
      this.createSlider({ destroy: true })
    }
  }

  createSlider = ({ destroy } = {}) => {
    const { id, sliderId } = this.state
    const { start, connect, tooltips = true, step = 1, range, snap = false, pips, labels, onSlide } = this.props
    const slider = document.getElementById(sliderId)
    const halfRangeValue = (range.max - range.min) / 2 + range.min

    if (destroy) slider.noUiSlider.destroy()

    noUiSlider.create(slider, {
      start,
      connect,
      tooltips,
      step,
      range,
      snap,
      pips,
      format: {
        to: key => {
          for (let i = 0; i < start.length; i++) {
            if (start[i] === key) return labels[i]
          }
          return "<unknown>"
        },
        from: key => {
          for (let i = 0; i < start.length; i++) {
            if (labels[i] === key) return start[i]
          }
          return Number(key)
        },
      },
    })

    slider.noUiSlider.on("start", () => slider.setAttribute("data-drag-start", true))
    slider.noUiSlider.on("end", () => slider.removeAttribute("data-drag-start"))
    slider.noUiSlider.on("update", (values, handle, unencoded) => {
      slider.setAttribute("data-handle-on-left", unencoded[0] < halfRangeValue)
    })
    slider.noUiSlider.on("set", (values, handle, unencoded) => {
      if (typeof onSlide === "function") onSlide(unencoded)
    })

    const sliderHandler = document.querySelector(`#${sliderId} .noUi-handle`)
    if (sliderHandler) {
      sliderHandler.setAttribute("id", id)
      sliderHandler.setAttribute("aria-labelledby", id)
    }
  }

  render() {
    const { sliderId, singleHandle } = this.state
    const { disabled, pips, className, multiHandle, hidePipsValues = {} } = this.props

    return (
      <div
        id={sliderId}
        disabled={disabled}
        data-has-pips={!!pips}
        data-hide-pips-values-xs={hidePipsValues.xs}
        data-hide-pips-values-sm={hidePipsValues.sm}
        data-single-handle={!multiHandle && singleHandle}
        className={"slider " + (className || "")}
      />
    )
  }
}

export default Slider
