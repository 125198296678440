import React from "react"
import { Row, Col } from "react-bootstrap"

class LazySkeletonUi extends React.Component {
  render() {
    const { noPadding } = this.props

    return (
      <div className="lazy-skeleton-ui" data-no-padding={noPadding}>
        <Row>
          {[1, 2, 3, 4].map(key => (
            <Col key={key} xs={6} sm={3}>
              <div className="card skeleton-placeholder-card"></div>
            </Col>
          ))}
        </Row>
        <Row>
          <Col xs={12}>
            <div className="card skeleton-placeholder-card"></div>
          </Col>
        </Row>
      </div>
    )
  }
}

export default LazySkeletonUi
