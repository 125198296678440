import React from "react"
import { Col, Row } from "react-bootstrap"

import PersonWeb from "@/person/PersonWeb"
import PersonLegal from "@/person/PersonLegal"
import FormInput from "@/_components/FormInput"
import AddressPanel from "@/person/AddressPanel"
import LayoutCard from "@/_components/LayoutCard"
import PersonContacts from "@/person/PersonContacts"
import AddressesPanel from "@/person/AddressesPanel"
import FormInputExtended from "@/_components/FormInputExtended"
import EntityBankAccountsPanel from "@/_components/EntityBankAccountsPanel"
import PersonRegistrationComponent from "@/person/PersonRegistrationComponent"

class PersonCompany extends React.Component {
  render() {
    const {
      person = {},
      tabPerson,
      detailed,
      addressExtraInfo,
      addressLanguage,
      addressRegion,
      readOnly,
      layout = {},
      modelPath,
      tabPersonModelPath,
      showRegistrationConfirmModal,
      allowDuplicates,
      usePersonLayout,
      useProspectLayout,
      handleSetPersonState,
      handleSetTabPersonState,
      handleSetPersonAddressState,
      isProspect,
      registrationProps = {},
      showSepaMandates,
      fromProspectOrPersonPage,
      showEdit,
      showAddressOpenModalButton,
      showBankOpenModalButton = true,
      personRegistrationsModal,
    } = this.props

    const address = person.addresses?.[0] || {}
    const showLegal = !person.props_legal || !person.props_legal.hidden
    const showPhone = (!readOnly || person.phone) && (!person.props_phone || !person.props_phone.hidden)
    const showMobile = !readOnly || person.mobile
    const showEmail = !readOnly || person.email
    const showPersonAddress = !person.props_address || !person.props_address.hidden
    const showPersonWeb = !person.props_web || !person.props_web.hidden
    const showPersonAddresses = person.props_addresses && person.props_addresses.hidden === false
    const showRegistration = person.props_registration?.hidden !== true
    const showContact = !person.props_companyContact || !person.props_companyContact.hidden
    const isRegistrationDisabled = person.props_registration?.disabled === true
    const showAddressLine = !person.props_addressLine || !person.props_addressLine.hidden
    const registrationColProps = person.props_registration?.colProps || { xs: 12, sm: 6, md: 3 }
    const showCurrency = person.props_currency?.hidden === false && this.props.currencies?.length

    const layoutCards =
      usePersonLayout || useProspectLayout
        ? layout.cards
            ?.filter(card => !card.props?.hidden)
            .map((card, index) => {
              const { props = {} } = card
              const { colProps, useParentEntityPerson } = props
              const { xs = 12, sm, md, lg } = colProps || {}
              props.noCard = true
              return (
                <Col {...{ xs, sm, md, lg }} key={index}>
                  <LayoutCard
                    key={index}
                    card={card}
                    serverRoute="/api/person/persons"
                    readOnly={readOnly}
                    onSetState={useParentEntityPerson ? handleSetTabPersonState : handleSetPersonState}
                    {...(props || {})}
                    entity={useParentEntityPerson ? tabPerson : person}
                    noCard
                    modelPath={useParentEntityPerson ? tabPersonModelPath : modelPath}
                  />
                </Col>
              )
            })
        : []

    return (
      <div className="person-company">
        <Row>
          {showRegistration && (
            <PersonRegistrationComponent
              person={person}
              readOnly={readOnly}
              personType={person.type}
              label="Registration number"
              disabled={isRegistrationDisabled}
              onSetState={handleSetPersonState}
              modelPath={modelPath}
              showRegistrationConfirmModal={showRegistrationConfirmModal}
              allowDuplicates={allowDuplicates}
              colProps={registrationColProps}
              isProspect={isProspect}
              {...registrationProps}
              {...(person?.props_registration || {})}
              fromProspectOrPersonPage={fromProspectOrPersonPage}
              showEdit={showEdit}
              personRegistrationsModal={personRegistrationsModal}
            />
          )}
          <FormInput
            debounce
            mandatory
            field="name"
            obj={person}
            readOnly={readOnly}
            modelPath={modelPath}
            onSetState={handleSetPersonState}
            colProps={{ xs: 12, sm: 6, md: 3 }}
          />
          <FormInput
            debounce
            field="commercialName"
            obj={person}
            readOnly={readOnly}
            onSetState={handleSetPersonState}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            modelPath={modelPath}
          />
          <FormInput
            obj={person}
            field="legalForm"
            select="legalForm"
            readOnly={readOnly}
            colProps={{ xs: 12, sm: 6, md: 3 }}
            onSetState={handleSetPersonState}
            modelPath={modelPath}
          />
          <FormInput
            readOnly={readOnly}
            obj={person}
            field="preferredLanguage"
            select="preferredLanguage"
            colProps={{ xs: 12, sm: 3 }}
            onSetState={handleSetPersonState}
            modelPath={modelPath}
          />
          {showPhone && (
            <FormInput
              debounce
              type="phone"
              obj={person}
              field="phone"
              readOnly={readOnly}
              modelPath={modelPath}
              colProps={{ xs: 12, sm: 3 }}
              onSetState={handleSetPersonState}
            />
          )}

          {showMobile && (
            <FormInput
              debounce
              type="phone"
              obj={person}
              field="mobile"
              readOnly={readOnly}
              modelPath={modelPath}
              colProps={{ xs: 12, sm: 3 }}
              onSetState={handleSetPersonState}
            />
          )}

          {showEmail && (
            <FormInput
              debounce
              type="email"
              obj={person}
              field="email"
              readOnly={readOnly}
              modelPath={modelPath}
              colProps={{ xs: 12, sm: 3 }}
              onSetState={handleSetPersonState}
            />
          )}
          {showCurrency && (
            <FormInput
              colProps={{ xs: 12, sm: 3 }}
              readOnly={readOnly}
              obj={person}
              field="currency"
              select={this.props.currencies}
              onSetState={handleSetPersonState}
              modelPath={modelPath}
            />
          )}
          {showAddressLine && !detailed && (
            <FormInputExtended
              obj={address}
              type="address"
              readOnly={readOnly}
              extraInfo={addressExtraInfo}
              language={addressLanguage}
              region={addressRegion}
              onSetState={handleSetPersonAddressState}
              colProps={{ xs: 12 }}
              modelPath={modelPath}
            />
          )}
        </Row>

        {showPersonWeb && (
          <PersonWeb
            person={person}
            readOnly={readOnly}
            handleSetPersonState={handleSetPersonState}
            modelPath={(modelPath ? `${modelPath}.` : "") + "web"}
            show={detailed}
          />
        )}

        {showContact && (
          <PersonContacts show={detailed} person={person} collapse={false} readOnly={readOnly} handleSetPersonState={handleSetPersonState} />
        )}

        {showPersonAddress && (
          <AddressPanel
            entity={person}
            handleSetEntityState={handleSetPersonState}
            readOnly={readOnly}
            extraInfo={addressExtraInfo}
            language={addressLanguage}
            region={addressRegion}
            modelPath={modelPath}
            show={detailed}
            showOpenModalButton={showAddressOpenModalButton}
          />
        )}

        {showPersonAddresses && (
          <AddressesPanel
            entity={person}
            handleSetEntityState={handleSetPersonState}
            readOnly={readOnly}
            extraInfo={addressExtraInfo}
            language={addressLanguage}
            region={addressRegion}
            modelPath={modelPath}
            show={detailed}
            showOpenModalButton={showAddressOpenModalButton}
          />
        )}

        <EntityBankAccountsPanel
          entity={person}
          hidden={!detailed}
          readOnly={readOnly}
          entityName="Person"
          modelPath={modelPath}
          showModal={showBankOpenModalButton}
          showSepaMandates={showSepaMandates}
          handleSetEntityState={handleSetPersonState}
        />

        {showLegal && (
          <PersonLegal person={person} modelPath={modelPath} readOnly={readOnly} handleSetPersonState={handleSetPersonState} show={detailed} />
        )}

        {detailed && layoutCards?.length > 0 && <Row>{layoutCards}</Row>}
      </div>
    )
  }
}

export default PersonCompany
