import { checkErrors } from "basikon-common-utils"
import debounce from "lodash.debounce"
import React from "react"
import { Col, Modal, Row } from "react-bootstrap"

import CustomButton from "@/_components/CustomButton"
import FormInput from "@/_components/FormInput"

import { loc } from "@/_services/localization"
import { generateRows } from "@/_services/utils"

export class PersonRegistrationModal extends React.Component {
  constructor(props) {
    super(props)

    const { person, registrationType } = this.props
    const registrations = [...(person.registrations || [])]
    if (registrations.length === 0) registrations.push({ type: registrationType })

    this.state = {
      registrations,
    }
  }

  handleSave = async () => {
    const { registrations } = this.state

    const onSetState = registrations => this.setState({ registrations })
    if (checkErrors(registrations, onSetState)) return

    const { isAutomaticRegistrationType, onClose } = this.props
    const filteredRegistrations = registrations.filter(registration => {
      return isAutomaticRegistrationType(registration.type) ? true : !!registration.number
    })

    onClose({
      registrations: registrations.length === 1 ? registrations : filteredRegistrations.length === 0 ? [registrations[0]] : filteredRegistrations,
    })
  }

  handleSetItemState = async (index, patch) => {
    const { getSelectedIdData, isAutomaticRegistrationType, validateRegistration } = this.props
    let registrations = [...(this.state.registrations || [{}])]

    // the patch contains at its root potentially info to start creating a person
    // when calling for an external registration type
    // it may also contain the keys registration and / or registrations
    // lastly it can also be a simple field update
    if (patch) {
      if (patch.registrations) {
        registrations = patch.registrations
        delete patch.registrations
        delete patch.type
      }

      registrations[index] = { ...(registrations[index] || {}), ...patch }

      if (registrations[index].id) {
        try {
          const { data: selectedIdData, value: selectedIdValue } = await getSelectedIdData(registrations[index].type, registrations[index].id)
          if (selectedIdData) {
            // this flag serves to avoid fetching the selected id data again when closing the modal
            selectedIdData.hasFetchedData = true
            Object.assign(registrations[index], selectedIdData)
            registrations[index].number = selectedIdValue
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }

      let error
      if (isAutomaticRegistrationType(registrations[index].type)) {
        registrations[index].number = null
      } else {
        error = await validateRegistration(registrations[index].type, registrations[index].number, registrations)
      }

      this.setState({ registrations, error })
    }
  }

  handleAddItem = () => {
    const { registrationTypes } = this.props
    const registrations = [...this.state.registrations]
    registrations.push({ type: registrationTypes?.map(it => it.value)[0] })
    this.setState({ registrations })
  }

  handleDeleteItem = index => {
    const registrations = [...this.state.registrations]
    registrations.splice(index, 1)
    this.setState({ registrations })
  }

  handleSetFirst = index => {
    const registrations = [...this.state.registrations]
    const tmp = registrations[0]
    registrations[0] = registrations[index]
    registrations[index] = tmp
    this.setState({ registrations })
  }

  getField = (col, registrationsItem = {}, onSetState) => {
    const { name, colProps, formInputProps, hidden } = col
    if (hidden) return

    const {
      readOnly,
      person,
      getLoadOptions,
      getPersonRegistrationRichValues,
      getPersonRegistrationItemLabel,
      getPersonRegistrationItem,
      isAutomaticRegistrationType,
    } = this.props
    const personRegistrationType = person.registration?.substring(0, 3)
    const personRegistrationNumber = person.registration?.substring(3)
    const registrationType = registrationsItem.type
    const personRegistrationItem = getPersonRegistrationItem(registrationType)
    const loadOptions = getLoadOptions(registrationType)
    const loadOptionsWithoutType =
      loadOptions &&
      (async query => {
        const persons = await loadOptions(query)
        // we remove the type (if any - there is "I" or "C" with the "PER" case) as it will replace the registration type upon line selection
        return persons && persons.map(per => ({ ...per, data: { ...per.data, type: registrationType } }))
      })
    const personRegistrationRichValues = getPersonRegistrationRichValues()
    const itemProps = {
      key: name,
      onSetState,
      obj: registrationsItem,
      readOnly,
      field: name,
      ...formInputProps,
    }
    const disabled =
      registrationsItem.type &&
      registrationsItem.type === personRegistrationType &&
      registrationsItem.number &&
      registrationsItem.number === personRegistrationNumber

    if (name === "type") {
      return <FormInput mandatory colProps={colProps ?? { xs: 12, sm: 6 }} disabled={disabled} select={personRegistrationRichValues} {...itemProps} />
    }

    if (name === "number") {
      return (
        <FormInput
          showCopy
          type={personRegistrationItem?.formInputType?.toUpperCase() !== "SPANISHID" ? personRegistrationItem?.formInputType : undefined}
          regExp={personRegistrationItem?.regExp}
          label={(registrationType && getPersonRegistrationItemLabel(registrationType)) || loc("Registration")}
          select={
            loadOptionsWithoutType &&
            debounce((query, callback) => {
              loadOptionsWithoutType(query).then(resp => callback(resp))
            }, 300)
          }
          colProps={colProps ?? { xs: 12, sm: 6 }}
          disabled={readOnly || isAutomaticRegistrationType(registrationsItem.type) || disabled}
          {...itemProps}
        />
      )
    }
    if (name === "deliveredBy") {
      return personRegistrationItem?.showInformation && <FormInput colProps={colProps ?? { xs: 4 }} {...itemProps} />
    }

    if (name === "deliveryDate") {
      return personRegistrationItem?.showInformation && <FormInput type="date" colProps={colProps ?? { xs: 4 }} {...itemProps} />
    }

    if (name === "validUntil") {
      return personRegistrationItem?.showInformation && <FormInput type="date" colProps={colProps ?? { xs: 4 }} {...itemProps} />
    }

    if (name === "verified") {
      return personRegistrationItem?.showVerification && <FormInput type="checkbox" colProps={colProps ?? { xs: 4 }} {...itemProps} />
    }

    if (name === "verificationDate") {
      return personRegistrationItem?.showVerification && <FormInput type="date" colProps={colProps ?? { xs: 4 }} {...itemProps} />
    }

    if (name === "verificationType") {
      return personRegistrationItem?.showVerification && <FormInput colProps={colProps ?? { xs: 4 }} select="verificationType" {...itemProps} />
    }

    if (name) {
      return <FormInput colProps={colProps ?? { xs: 12 }} {...itemProps} />
    }
  }

  render() {
    const { registrations, error } = this.state
    const { person, onClose, getPersonRegistrationRichValues, rows, personRegistrationsModal = {} } = this.props
    const { showSortButton = null, showDeleteButton = true, saveButtonLabel = "Save" } = personRegistrationsModal
    const readOnly = person.props_registrations?.readOnly || person.props_registrations?.disabled || person.readOnly || this.props.readOnly
    const personExists = !!person._id
    const personRegistrationRichValues = getPersonRegistrationRichValues()
    const defaultRows = [
      ["type", "number"],
      ["deliveredBy", "deliveryDate", "validUntil"],
      ["verified", "verificationDate", "verificationType"],
    ]

    return (
      <Modal show={true} onHide={() => onClose()} bsSize="large" backdrop="static" className="person-registration-modal">
        <Modal.Header closeButton>
          <Modal.Title>{loc`Registrations`}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {registrations?.map((registrationsItem, index) => {
            const registrationType = registrationsItem.type
            if (registrationType && !personRegistrationRichValues.find(it => it.value === registrationType)) return

            const handleSetItemState = patch => this.handleSetItemState(index, patch)

            return (
              <React.Fragment key={index}>
                {index > 0 && !readOnly && (
                  <Row className="text-right">
                    <Col xs={12}>
                      {((!personExists && showSortButton === null) || showSortButton) && (
                        <CustomButton bsSize="xs" className="pull-right flex-center" simple pullRight onClick={() => this.handleSetFirst(index)}>
                          <i className="icn-arrow-up icn-xs mr-5px" />
                          {!personExists ? loc`Set as main registration` : loc`Set as first registration`}
                        </CustomButton>
                      )}
                      {showDeleteButton && (
                        <CustomButton bsSize="xs" className="pull-right" simple pullRight onClick={() => this.handleDeleteItem(index)}>
                          <i className="icn-xmark icn-xs" />
                        </CustomButton>
                      )}
                    </Col>
                  </Row>
                )}

                {generateRows({
                  getField: this.getField,
                  rows: rows?.length ? rows : defaultRows,
                  entity: registrationsItem,
                  handleSetState: handleSetItemState,
                  index,
                  readOnly,
                })}
              </React.Fragment>
            )
          })}

          {!readOnly && (
            <CustomButton bsStyle="primary" bsSize="small" className="flex-center" fill onClick={this.handleAddItem}>
              <i className="icn-plus icn-xs mr-5px" />
              {loc`Add`}
            </CustomButton>
          )}
        </Modal.Body>

        <Modal.Footer>
          <CustomButton bsSize="sm" onClick={() => onClose()}>
            {loc`Close`}
          </CustomButton>

          {!readOnly && (
            <CustomButton bsStyle="primary" bsSize="sm" fill onClick={() => this.handleSave()} disabled={error}>
              {loc(`${saveButtonLabel}`)}
            </CustomButton>
          )}

          {error && <div className="mt-theme text-danger font-1-2">{loc(error)}</div>}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default PersonRegistrationModal
