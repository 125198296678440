import axios from "axios"
import {
  formatAddress,
  formatBytes,
  formatMilliseconds,
  formatPercentage,
  getEntityClientRoute,
  getEntityServerRoute,
  getEntityStatusListName,
  getFilterFunction,
} from "basikon-common-utils"
import get from "lodash.get"
import set from "lodash/set"

import { invoiceSearchFields } from "@/billing/InvoicesPage"
import { agreementSearchFields } from "@/financing/AgreementsPage"
import { amendmentSearchFields } from "@/financing/AmendmentsPage"
import { creditLineSearchFields } from "@/financing/CreditLinesPage"
import { productSearchFields } from "@/product/ProductsPage"
import { committeeSearchFields } from "@/support/CommitteesPage"
import { userSearchFields } from "@/user/UsersPage"

import { getLabel } from "@/_services/lists"
import { getLocale, loc } from "@/_services/localization"
import { addOops } from "@/_services/notification"
import { formatCurrency, formatDate, formatDateTime } from "@/_services/utils"

export function getEntitySearchFields(entityName) {
  if (!entityName) return

  const lowerEntityName = entityName.toLowerCase()
  if (lowerEntityName === "agreement") return agreementSearchFields
  if (lowerEntityName === "asset") return assetSearchFields
  if (lowerEntityName === "assetcatalogitem") return assetCatalogItemSearchFields
  if (lowerEntityName === "assetlot") return assetLotSearchFields
  if (lowerEntityName === "amendment") return amendmentSearchFields
  if (lowerEntityName === "bankAccount") return bankAccountSearchFields
  if (lowerEntityName === "case") return caseSearchFields
  if (lowerEntityName === "collateral") return collateralSearchFields
  if (lowerEntityName === "campaign") return campaignSearchFields
  if (lowerEntityName === "committee") return committeeSearchFields
  if (lowerEntityName === "communication") return communicationSearchFields
  if (lowerEntityName === "contract") return contractSearchFields
  if (lowerEntityName === "contractlot") return contractLotSearchFields
  if (lowerEntityName === "creditline") return creditLineSearchFields
  if (lowerEntityName === "deal") return dealSearchFields
  if (lowerEntityName === "featureflag") return featureFlagSearchFields
  if (lowerEntityName === "invoice") return invoiceSearchFields
  if (lowerEntityName === "maintenance") return maintenanceSearchFields
  if (lowerEntityName === "person") return personSearchFields
  if (lowerEntityName === "privateequityasset") return privateEquityAssetSearchFields
  if (lowerEntityName === "posting") return postingSearchFields
  if (lowerEntityName === "postingevent") return postingEventSearchFields
  if (lowerEntityName === "product") return productSearchFields
  if (lowerEntityName === "project") return projectSearchFields
  if (lowerEntityName === "prospect") return personSearchFields
  if (lowerEntityName === "question") return questionSearchFields
  if (lowerEntityName === "score") return scoreSearchFields
  if (lowerEntityName === "scriptlog") return scriptLogSearchFields
  if (lowerEntityName === "sepa") return sepaSearchFields
  if (lowerEntityName === "task") return taskSearchFields
  if (lowerEntityName === "user") return userSearchFields

  // Default fields
  return [
    [
      { field: "registration", colProps: { xs: 12, md: 3 }, regex: true },
      { field: "name", colProps: { xs: 12, md: 9 }, regex: true },
    ],
  ]
}

export function getEntitySearchFieldNames(entityName) {
  const { searchFields } = getEntitySearchFields(entityName)
  return toFieldNames(searchFields)
}

export function getEntityDefaultConfig(entityName) {
  if (!entityName) return

  const lowerEntityName = entityName.toLowerCase()
  if (lowerEntityName === "agreement") return { columns: agreementColumns }
  if (lowerEntityName === "asset") return { columns: assetColumns }
  if (lowerEntityName === "assetcatalogitem") return { columns: assetCatalogItemColumns }
  if (lowerEntityName === "assetlot") return { columns: assetLotColumns }
  if (lowerEntityName === "campaign") return { columns: campaignColumns }
  if (lowerEntityName === "case") return { columns: caseColumns }
  if (lowerEntityName === "collateral") return { columns: collateralColumns }
  if (lowerEntityName === "committee") return { columns: committeeColumns }
  if (lowerEntityName === "communication") return { columns: communicationColumns }
  if (lowerEntityName === "contract") return { columns: contractColumns }
  if (lowerEntityName === "contractelement") return { columns: contractElementColumns }
  if (lowerEntityName === "contractlot") return { columns: contractLotColumns }
  if (lowerEntityName === "creditline") return { columns: creditLineColumns }
  if (lowerEntityName === "deal") return { columns: contractColumns }
  if (lowerEntityName === "featureflag") return { columns: featureFlagColumns }
  if (lowerEntityName === "fund") return { columns: fundColumns }
  if (lowerEntityName === "invoice") return { columns: invoiceColumns }
  if (lowerEntityName === "maintenance") return { columns: maintenanceColumns, fields: maintenanceFields }
  if (lowerEntityName === "paymentfile") return { columns: paymentFileColumns }
  if (lowerEntityName === "person") return { columns: personColumns }
  if (lowerEntityName === "posting") return { columns: postingColumns }
  if (lowerEntityName === "postingevent") return { columns: postingEventColumns }
  if (lowerEntityName === "privateequityasset") return { columns: privateEquityAssetColumns }
  if (lowerEntityName === "product") return { columns: productColumns }
  if (lowerEntityName === "project") return { columns: projectColumns }
  if (lowerEntityName === "prospect") return { columns: prospectColumns }
  if (lowerEntityName === "question") return { columns: questionColumns }
  if (lowerEntityName === "score") return { columns: scoreColumns }
  if (lowerEntityName === "scriptlog") return { columns: scriptLogColumns }
  if (lowerEntityName === "user") return { columns: userColumns }

  const entityClientRoute = getEntityClientRoute(entityName) // "AssetLot" => "/asset-lot"
  const entityStatusListName = getEntityStatusListName(entityName) // "AssetLot" => "assetLotStatus"
  return {
    columns: [
      { title: "Registration", name: "registration", linkTo: `${entityClientRoute}/{registration}` },
      { title: "Organization", name: "organization.name", hidden: true },
      { title: "Status", name: "status", select: entityStatusListName, badge: true },
      { title: "Name", name: "name" },
    ],
  }
}

export function getEntityDisplay(entity) {
  if (!entity) return
  if (!entity.name || entity.name === entity.registration) return entity.registration
  if (!entity.registration) return entity.name
  return `${entity.name} (${entity.registration})`
}

export function toFieldNames(fields = []) {
  const fieldNames = fields
    .flat()
    .map(({ field }) => field)
    .filter(field => field)
  return Array.from(new Set(fieldNames))
}

export function getEntityFilterFunction(entityName) {
  if (entityName.toLowerCase() === "asset") return filterAssets
  if (entityName.toLowerCase() === "collateral") return filterCollaterals
  if (entityName.toLowerCase() === "contractlot") return filterContractLots
  if (entityName.toLowerCase() === "postingevent") return filterPostingEvents
  if (entityName.toLowerCase() === "person") return filterPersons
  if (entityName.toLowerCase() === "prospect") return filterProspects
  if (entityName.toLowerCase() === "privateequityasset") return filterPrivateEquityAssets
  if (entityName.toLowerCase() === "question") return filterQuestions
  if (entityName.toLowerCase() === "score") return filterScores
  if (entityName.toLowerCase() === "posting") return filterPostings
  return filterEntities
}

function filterEntities(entities, filter, entityColumns) {
  if (filter) filter = filter.trim().toUpperCase()
  if (!filter) return entities
  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  // Filter entities based on "entityColumns"
  if (Array.isArray(entityColumns) && entityColumns.length) {
    return entities.filter(entity => {
      for (let entityColumn of entityColumns) {
        let name
        if (entityColumn?.name) name = entityColumn.name
        else if (typeof entityColumn === "string") name = entityColumn

        if (!name) continue

        let value = get(entity, name)
        // If it's a list check if it matches the label first
        if (entityColumn?.select) {
          const label = getLabel(entityColumn.select, value)
          if (label && includes(label)) return true
        }
        // If it's an object/array then stringify it
        if (entityColumn.type === "object" && (typeof value === "object" || Array.isArray(value))) value = JSON.stringify(value)
        // Match value
        if (includes(value)) return true
      }
      return false
    })
  }
  return entities.filter(entity => includes(entity.registration) || includes(entity.name) || includes(entity.status))
}

function filterPostingEvents(postingEvents, filter) {
  if (filter) filter = filter.trim().toUpperCase()
  if (!filter) return postingEvents

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return postingEvents.filter(postingEvent => {
    const dcLabel = getLabel("postingDc", postingEvent.dc)
    const codeLabel = getLabel("postingCode", postingEvent.code)
    const typeLabel = getLabel("postingEventType", postingEvent.type)
    const accountLabel = getLabel("ledgerAccount", postingEvent.account)
    const statusLabel = getLabel("postingEventStatus", postingEvent.status)
    const secondaryAccountLabel = getLabel("ledgerAccount", postingEvent.secondaryAccount)

    return (
      includes(dcLabel) ||
      includes(codeLabel) ||
      includes(postingEvent.dc) ||
      includes(statusLabel) ||
      includes(postingEvent.documentReference) ||
      includes(postingEvent.name) ||
      includes(postingEvent.code) ||
      includes(typeLabel) ||
      includes(postingEvent.type) ||
      includes(postingEvent.date) ||
      includes(accountLabel) ||
      includes(postingEvent.amount) ||
      includes(postingEvent.status) ||
      includes(postingEvent.account) ||
      includes(postingEvent.eventType) ||
      includes(postingEvent.description) ||
      includes(postingEvent.registration) ||
      includes(secondaryAccountLabel) ||
      includes(postingEvent.secondaryAccount) ||
      includes(getEntityDisplay(postingEvent)) ||
      includes(postingEvent.personRegistration) ||
      includes(postingEvent.partnerRegistration) ||
      includes(postingEvent.auditTrailReference) ||
      includes(postingEvent.invoiceRegistration) ||
      includes(postingEvent.contractRegistration) ||
      includes(postingEvent.cashflowRegistration) ||
      includes(postingEvent.creditLineRegistration)
    )
  })
}

function filterPrivateEquityAssets(privateEquitys = [], filter) {
  if (!filter) return privateEquitys
  const includes = getFilterFunction(filter, getLocale())

  return privateEquitys.filter(privateEquity => {
    const typeLabel = getLabel("privateEquityAssetType", privateEquity.type)
    const statusLabel = getLabel("privateEquityAssetStatus", privateEquity.status)

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(privateEquity.name) ||
      includes(privateEquity.type) ||
      includes(privateEquity.status) ||
      includes(privateEquity.registration) ||
      includes(getEntityDisplay(privateEquity)) ||
      includes(formatAddress(privateEquity.addresses?.[0])) ||
      includes((privateEquity.roles || []).map(r => getLabel("privateEquityAssetRole", r.role) || `${r.role} (???)`).join(", "))
    )
  })
}

function filterQuestions(questions, filter) {
  if (!filter) return questions
  const includes = getFilterFunction(filter, getLocale())

  return questions.filter(question => {
    const typeLabel = getLabel("questionType", question.type)
    const statusLabel = getLabel("questionStatus", question.status)

    return (
      includes(question.type) ||
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(question.status) ||
      includes(question.registration) ||
      includes(getEntityDisplay(question))
    )
  })
}

function filterScores(scores, filter) {
  if (!filter) return scores
  const includes = getFilterFunction(filter, getLocale())

  return scores.filter(score => {
    const statusLabel = getLabel("scoreStatus", score.status)

    return (
      includes(statusLabel) ||
      includes(score.name) ||
      includes(score.status) ||
      includes(score.registration) ||
      includes(getEntityDisplay(score)) ||
      includes(score.dealRegistration)
    )
  })
}

function filterPostings(postings = [], filter) {
  if (!filter) return postings
  const includes = getFilterFunction(filter, getLocale())

  return postings.filter(posting => {
    const dcLabel = getLabel("postingDc", posting.dc)
    const codeLabel = getLabel("postingCode", posting.code)
    const typeLabel = getLabel("postingType", posting.type)
    const statusLabel = getLabel("postingStatus", posting.status)
    const accountLabel = getLabel("ledgerAccount", posting.account)
    const secondaryAccountLabel = getLabel("ledgerAccount", posting.secondaryAccount)

    return (
      includes(dcLabel) ||
      includes(codeLabel) ||
      includes(posting.dc) ||
      includes(statusLabel) ||
      includes(posting.documentReference) ||
      includes(posting.name) ||
      includes(posting.code) ||
      includes(typeLabel) ||
      includes(posting.type) ||
      includes(posting.date) ||
      includes(accountLabel) ||
      includes(posting.amount) ||
      includes(posting.status) ||
      includes(posting.account) ||
      includes(posting.eventType) ||
      includes(posting.description) ||
      includes(posting.registration) ||
      includes(secondaryAccountLabel) ||
      includes(posting.secondaryAccount) ||
      includes(getEntityDisplay(posting)) ||
      includes(posting.personRegistration) ||
      includes(posting.partnerRegistration) ||
      includes(posting.auditTrailReference) ||
      includes(posting.invoiceRegistration) ||
      includes(posting.contractRegistration) ||
      includes(posting.cashflowRegistration) ||
      includes(posting.creditlineRegistration)
    )
  })
}

function filterPersons(persons = [], filter) {
  if (!filter) return persons
  const includes = getFilterFunction(filter, getLocale())

  return persons.filter(person => {
    const typeLabel = getLabel("personType", person.type)
    const statusLabel = getLabel("personStatus", person.status)
    const rolesLabels = (person.roles || []).map(r => getLabel("personRole", r.role)).join(", ")
    const registrations = (person.registrations || [])
      .map(r => r?.number)
      .filter(it => it)
      .join(", ")

    return (
      includes(typeLabel) ||
      includes(rolesLabels) ||
      includes(person.name) ||
      includes(statusLabel) ||
      includes(person.type) ||
      includes(person.email) ||
      includes(person.status) ||
      includes(person.lastName) ||
      includes(person.firstName) ||
      includes(person.secondName) ||
      includes(person.registration) ||
      includes(getEntityDisplay(person)) ||
      includes(registrations) ||
      includes(formatAddress(person.addresses?.[0]))
    )
  })
}

function filterProspects(prospects = [], filter) {
  if (!filter) return prospects
  const includes = getFilterFunction(filter, getLocale())

  return prospects.filter(prospect => {
    const typeLabel = getLabel("personType", prospect.type) // TODO: Rename to "prospectType"
    const statusLabel = getLabel("personStatus", prospect.status) // TODO: Rename to "prospectStatus"
    const rolesLabels = (prospect.roles || []).map(r => getLabel("personRole", r.role)).join(", ") // TODO: Rename to "prospectRole"
    const registrations = (prospect.registrations || [])
      .map(r => r?.number)
      .filter(it => it)
      .join(", ")

    return (
      includes(typeLabel) ||
      includes(rolesLabels) ||
      includes(prospect.name) ||
      includes(statusLabel) ||
      includes(prospect.type) ||
      includes(prospect.email) ||
      includes(prospect.status) ||
      includes(prospect.lastName) ||
      includes(prospect.firstName) ||
      includes(prospect.secondName) ||
      includes(prospect.registration) ||
      includes(getEntityDisplay(prospect)) ||
      includes(registrations) ||
      includes(formatAddress(prospect.addresses?.[0]))
    )
  })
}

function filterCollaterals(collaterals = [], filter) {
  if (!filter) return collaterals
  const includes = getFilterFunction(filter, getLocale())

  return collaterals.filter(collateral => {
    const typeLabel = getLabel("collateralType", collateral.type)
    const statusLabel = getLabel("collateralStatus", collateral.status)

    return (
      includes(typeLabel) ||
      includes(collateral.name) ||
      includes(collateral.type) ||
      includes(statusLabel) ||
      includes(collateral.status) ||
      includes(collateral.description) ||
      includes(collateral.registration) ||
      includes(getEntityDisplay(collateral))
    )
  })
}

function filterAssets(assets = [], filter) {
  if (!filter) return assets
  const includes = getFilterFunction(filter, getLocale())

  return assets.filter(asset => {
    const typeLabel = getLabel("assetType", asset.type)
    const statusLabel = getLabel("assetStatus", asset.status)

    return (
      includes(typeLabel) ||
      includes(asset.name) ||
      includes(asset.type) ||
      includes(statusLabel) ||
      includes(asset.status) ||
      includes(asset.description) ||
      includes(asset.registration) ||
      includes(getEntityDisplay(asset)) ||
      includes(asset.identificationNumber) ||
      includes(asset.registrationBookNumber) ||
      includes((asset.priceExclTax || 0).toString()) ||
      includes((asset.priceInclTax || 0).toString()) ||
      includes(asset.owner?.name) ||
      includes(asset.supplier?.name)
    )
  })
}

function filterContractLots(contractLots, filter) {
  if (filter) filter = filter.trim().toUpperCase()
  if (!filter) return contractLots

  const locale = getLocale()
  const includes = getFilterFunction(filter, locale)

  return contractLots.filter(contractLot => {
    const typeLabel = getLabel("contractLotType", contractLot.type)
    const statusLabel = getLabel("contractLotStatus", contractLot.status)

    return (
      includes(typeLabel) ||
      includes(statusLabel) ||
      includes(contractLot.code) ||
      includes(contractLot.name) ||
      includes(contractLot.type) ||
      includes(contractLot.status) ||
      includes(contractLot.cessionDate) ||
      includes(contractLot.registration) ||
      includes(contractLot.fundRegistration) ||
      includes(contractLot.externalReference) ||
      includes(contractLot.internalReference) ||
      includes(getEntityDisplay(contractLot)) ||
      includes(formatPercentage(contractLot.rate, locale))
    )
  })
}

// TODO: Move searchFields columns to their entities page when possible (if they don't use EntitiesPage)

const contractLotSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "type", type: "multiple", label: loc`Type`, select: "contractLotType", colProps: { xs: 12, sm: 12, md: 4 } },
    { field: "status", type: "multiple", label: loc`Status`, select: "contractLotStatus", colProps: { xs: 12, sm: 12, md: 8 } },
  ],
  [
    { field: "externalReference", regex: true, colProps: { xs: 12, md: 6 } },
    { field: "internalReference", regex: true, colProps: { xs: 12, md: 6 } },
  ],
  [
    { field: "contractRegistration", label: "Contract", searchEntityName: "Contract", actionHidden: true, colProps: { xs: 12, md: 6 } },
    { field: "fundingRegistration", label: "Funding", searchEntityName: "Funding", actionHidden: true, colProps: { xs: 12, md: 6 } },
  ],
]

const contractElementColumns = [
  { title: "Registration", name: "registration", linkTo: "/contract-element/{registration}" },
  { title: "Type", name: "type", select: "elementType" },
  { title: "Code", name: "code" },
  { title: "Name", name: "name" },
  { title: "Start date", name: "startDate" },
  { title: "End date", name: "endDate" },
  { title: "Contract registration", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  { title: "Payee registration", name: "payeeRegistration", linkTo: "/person/{payeeRegistration}" },
  { title: "Payer registration", name: "payerRegistration", linkTo: "/person/{payerRegistration}" },
  { title: "Total payment", name: "totalPayment", type: "currency" },
  { title: "Currency", name: "currency" },
  { title: "GroupKey", name: "groupKey" },
  { title: "Quotation registration", name: "quotationRegistration" },
  { title: "PR", name: "pr" },
  { title: "Status", name: "status", select: "contractElementStatus", badge: true },
]

const contractLotColumns = [
  { title: "Registration", name: "registration", linkTo: "/contract-lot/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  // { title: "Code", name: "code" },
  { title: "Type", name: "type", select: "contractLotType" },
  { title: "Name", name: "name" },
  { title: "Fund", name: "fundRegistration", linkTo: "/fund/{fundRegistration}" },
  { title: "Rate", name: "rate", type: "percentage" },
  { title: "Cession date", name: "cessionDate", type: "date" },
  { title: "Total amount", name: "totalAmount", type: "currency" },
  { title: "Submit date", name: "submitDate", type: "date" },
  { title: "Accept date", name: "acceptDate", type: "date" },
  { title: "Validation date", name: "validationDate", type: "date" },
  { title: "Status", name: "status", select: "contractLotStatus", badge: true },
  // { title: "Internal reference", name: "internalReference" },
  // { title: "External reference", name: "externalReference" },
]

const contractSearchFields = [
  [
    { field: "type", type: "multiple", select: "contractType", colProps: { xs: 12, sm: 3 } },
    { field: "contractType", type: "multiple", select: "contractType", colProps: { xs: 12, sm: 3 }, hidden: true },
    { field: "registration", colProps: { xs: 12, sm: 3 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 6 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "contractStatus", colProps: { xs: 12, sm: 3 } },
    { field: "managementType", select: "managementType", type: "multiple", colProps: { xs: 12, sm: 3 } },
    { field: "purpose", select: "purpose", type: "multiple", colProps: { xs: 12, sm: 3 } },
    { field: "subPurpose", select: "subPurpose", type: "multiple", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "internalReference", colProps: { xs: 12, sm: 3 }, regex: true },
    { field: "externalReference", colProps: { xs: 12, sm: 3 }, regex: true },
  ],
  [{ legend: "Persons", field: "personsTitle" }], // Keep "field" to be able to hide legend by config
  [
    {
      field: "client",
      placeholder: "Type to search for a client",
      searchEntityName: "Person",
      query: { role: "CLIENT" },
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "partner",
      placeholder: "Type to search for a partner",
      searchEntityName: "Person",
      query: { role: "PARTNER" },
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "sales",
      placeholder: "Type to search for a sales",
      searchEntityName: "Person",
      query: { role: "SALES" },
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [{ legend: "Assets", field: "assetsTitle" }], // Keep "field" to be able to hide legend by config
  [
    {
      field: "assetRegistration",
      placeholder: "Type to search for an asset",
      searchEntityName: "Asset",
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [{ legend: "Quotation", field: "quotationTitle" }], // Keep "field" to be able to hide legend by config
  [
    { field: "financialProduct", type: "multiple", select: "financialProduct", colProps: { xs: 12, sm: 3 } },
    { field: "financialScheme", type: "multiple", select: "financialScheme", colProps: { xs: 12, sm: 3 } },
    { field: "frequency", select: "frequency", type: "multiple", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "minDuration", label: loc`Duration (min)`, type: "number", colProps: { xs: 12, sm: 3 } },
    { field: "maxDuration", label: loc`Duration (max)`, type: "number", colProps: { xs: 12, sm: 3 } },
    {
      field: "minFinancedAmountExclTax",
      label: loc`Financed amount (min)`,
      type: "currency",
      colProps: { xs: 12, sm: 3 },
    },
    {
      field: "maxFinancedAmountExclTax",
      label: loc`Financed amount (max)`,
      type: "currency",
      colProps: { xs: 12, sm: 3 },
    },
  ],
  [
    { field: "fromStartDate", label: loc`From date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "toStartDate", label: loc`To date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "fromEndDate", label: loc`From end date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "toEndDate", label: loc`To end date`, type: "date", colProps: { xs: 12, sm: 3 } },
  ],
]

const dealSearchFields = [
  [
    { field: "type", type: "multiple", select: "contractType", colProps: { xs: 12, sm: 3 } },
    { field: "contractType", type: "multiple", select: "contractType", colProps: { xs: 12, sm: 3 }, hidden: true },
    { field: "registration", colProps: { xs: 12, sm: 3 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 6 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "dealStatus", colProps: { xs: 12, sm: 3 } },
    { field: "managementType", select: "managementType", type: "multiple", colProps: { xs: 12, sm: 3 } },
    { field: "purpose", select: "purpose", type: "multiple", colProps: { xs: 12, sm: 3 } },
    { field: "subPurpose", select: "subPurpose", type: "multiple", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "internalReference", colProps: { xs: 12, sm: 3 }, regex: true },
    { field: "externalReference", colProps: { xs: 12, sm: 3 }, regex: true },
  ],
  [{ legend: "Persons", field: "personsTitle" }], // Keep "field" to be able to hide legend by config
  [
    {
      field: "client",
      placeholder: "Type to search for a client",
      searchEntityName: "Prospect",
      query: { role: "CLIENT" },
      multiple: true,
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "partner",
      placeholder: "Type to search for a partner",
      searchEntityName: "Person",
      query: { role: "PARTNER" },
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "sales",
      placeholder: "Type to search for a sales",
      searchEntityName: "Person",
      query: { role: "SALES" },
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [{ legend: "Assets", field: "assetsTitle" }], // Keep "field" to be able to hide legend by config
  [
    {
      field: "assetRegistration",
      placeholder: "Type to search for an asset",
      searchEntityName: "Asset",
      actionHidden: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [{ legend: "Quotation", field: "quotationTitle" }], // Keep "field" to be able to hide legend by config
  [
    { field: "financialProduct", type: "multiple", select: "financialProduct", colProps: { xs: 12, sm: 3 } },
    { field: "financialScheme", type: "multiple", select: "financialScheme", colProps: { xs: 12, sm: 3 } },
    { field: "frequency", select: "frequency", type: "multiple", colProps: { xs: 12, sm: 3 } },
  ],
  [
    { field: "minDuration", label: loc`Duration (min)`, type: "number", colProps: { xs: 12, sm: 3 } },
    { field: "maxDuration", label: loc`Duration (max)`, type: "number", colProps: { xs: 12, sm: 3 } },
    {
      field: "minFinancedAmountExclTax",
      label: loc`Financed amount (min)`,
      type: "currency",
      colProps: { xs: 12, sm: 3 },
    },
    {
      field: "maxFinancedAmountExclTax",
      label: loc`Financed amount (max)`,
      type: "currency",
      colProps: { xs: 12, sm: 3 },
    },
  ],
  [
    { field: "fromStartDate", label: loc`From date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "toStartDate", label: loc`To date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "fromEndDate", label: loc`From end date`, type: "date", colProps: { xs: 12, sm: 3 } },
    { field: "toEndDate", label: loc`To end date`, type: "date", colProps: { xs: 12, sm: 3 } },
  ],
]

const assetSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", label: loc`Status`, select: "assetStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", label: loc`Type`, select: "assetType", colProps: { xs: 12, sm: 8 } },
  ],
  [
    { field: "registrationBookNumber" },
    { field: "minPriceExclTax", type: "currency", label: loc`Price (min)` },
    { field: "maxPriceExclTax", type: "currency", label: loc`Price (max)` },
  ],
  [{ legend: "Supplier", field: "supplierTitle" }], // Keep "field" to be able to hide legend by config
  [
    {
      field: "supplierRegistration",
      searchEntityName: "Person",
      placeholder: "Type to search for a supplier",
      query: { type: "C", role: "SUPPLIER" },
    },
    { field: "invoiceNumber" },
    { field: "supplierCurrency", select: "currency" },
    { field: "deliveryDate", type: "date" },
    { field: "invoiceDate", type: "date" },
  ],
]

const sepaSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "person", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", label: loc`Status`, select: "sepaStatus", colProps: { xs: 12, sm: 4 } },
    { field: "bank", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "method", select: "paymentMode", colProps: { xs: 12, sm: 4 } },
  ],
]

const postingSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 3 } },
    { field: "name", colProps: { xs: 12, md: 3 } },
    { field: "type", colProps: { xs: 12, md: 3 }, select: "postingType" },
    { field: "dc", colProps: { xs: 12, md: 3 }, select: "postingDc", label: "Debit/Credit" },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      regex: true,
      colProps: { xs: 12, md: 4 },
    },
    { field: "code", colProps: { xs: 12, md: 8 }, type: "multiple", select: "postingCode" },
  ],
  [
    {
      field: "person",
      searchEntityName: "Person",
      placeholder: "Type to search for a person",
      actionHidden: true,
      colProps: { xs: 12, md: 4 },
      regex: true,
    },
    { field: "status", type: "multiple", select: "postingStatus", colProps: { xs: 12, md: 8 } },
  ],
  [
    {
      field: "invoice",
      searchEntityName: "Invoice",
      placeholder: "Type to search for an invoice",
      actionHidden: true,
      colProps: { xs: 12, md: 4 },
      regex: true,
    },
    { field: "account", type: "multiple", select: "ledgerAccount", colProps: { xs: 12, md: 8 } },
  ],
]

const postingEventSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 3 } },
    { field: "name", colProps: { xs: 12, md: 6 } },
    { field: "type", colProps: { xs: 12, md: 3 }, select: "postingEventType" },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      regex: true,
      colProps: { xs: 12, md: 4 },
    },
    { field: "code", colProps: { xs: 12, md: 8 }, type: "multiple", select: "postingEventCode" },
  ],
  [
    {
      field: "person",
      searchEntityName: "Person",
      placeholder: "Type to search for a person",
      actionHidden: true,
      colProps: { xs: 12, md: 4 },
      regex: true,
    },
    { field: "status", type: "multiple", select: "postingEventStatus", colProps: { xs: 12, md: 8 } },
  ],
  [
    {
      field: "invoice",
      searchEntityName: "Invoice",
      placeholder: "Type to search for an invoice",
      actionHidden: true,
      colProps: { xs: 12, md: 4 },
      regex: true,
    },
    { field: "account", type: "multiple", select: "ledgerAccount", colProps: { xs: 12, md: 8 } },
  ],
]

const postingColumns = [
  { title: "Registration", name: "registration", linkTo: "/posting/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Event type", name: "eventType" },
  { title: "Status", name: "status", select: "postingStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Date", name: "date", type: "datetime" },
  { title: "D/C", name: "dc", select: "postingDc" },
  { title: "Account", name: "account", select: "ledgerAccount" },
  { title: "Account details", name: "secondaryAccount", select: "ledgerAccount" },
  { title: "Amount", name: "amount", excelFormat: "currency", type: "currency" },
  { title: "Document Ref", name: "documentReference" },
  { title: "Ref", name: "auditTrailReference" },
  { title: "Type", name: "type", select: "postingType" },
  { title: "Code", name: "code", select: "postingCode" },
  { title: "Asset", name: "assetRegistration", linkTo: "/asset/{assetRegistration}" },
  { title: "Person", name: "personRegistration", linkTo: "/person/{personRegistration}" },
  { title: "Invoice", name: "invoiceRegistration", linkTo: "/invoice/{invoiceRegistration}" },
  { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  { title: "Cashflow", name: "cashflowRegistration", linkTo: "/cashflow/{cashflowRegistration}" },
  { title: "Creditline", name: "creditlineRegistration", linkTo: "/credit-line/{creditlineRegistration}" },
]

const campaignColumns = [
  { title: "Registration", name: "registration", linkTo: "/campaign/{registration}" },
  { title: "Name", name: "name" },
  { title: "Status", name: "status", select: "campaignStatus", badge: true },
  { title: "Type", name: "type", select: "campaignType" },
]

const postingEventColumns = [
  { title: "Registration", name: "registration", linkTo: "/posting-event/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Event type", name: "eventType" },
  { title: "Status", name: "status", select: "postingEventStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Date", name: "date", type: "datetime" },
  { title: "Type", name: "type", select: "postingEventType" },
  { title: "Code", name: "code", select: "postingEventCode" },
  { title: "Account", name: "account", select: "ledgerAccount" },
  { title: "Account details", name: "secondaryAccount", select: "ledgerAccount" },
  { title: "Amount", name: "amount", excelFormat: "currency" },
  { title: "Document Ref", name: "documentReference" },
  { title: "Ref", name: "auditTrailReference" },
  { title: "Asset", name: "assetRegistration", linkTo: "/asset/{assetRegistration}" },
  { title: "Person", name: "personRegistration", linkTo: "/person/{personRegistration}" },
  { title: "Invoice", name: "invoiceRegistration", linkTo: "/invoice/{invoiceRegistration}" },
  { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  { title: "Cashflow", name: "cashflowRegistration", linkTo: "/cashflow/{cashflowRegistration}" },
  { title: "Creditline", name: "creditlineRegistration", linkTo: "/credit-line/{creditlineRegistration}" },
]

const paymentFileColumns = [
  { title: "Registration", name: "registration", linkTo: "/payment-file/{registration}", className: "w-10" },
  { title: "External data", name: "externalDataRegistration", linkTo: "/external-data/{externalDataRegistration}", className: "w-10" },
  { title: "Organization", name: "organization.name", hidden: true, className: "w-10" },
  { title: "Status", name: "status", select: "paymentFileStatus", badge: true, className: "w-10" },
  { title: "File date", name: "fileDate", type: "datetime", className: "w-10" },
  { title: "Type", name: "type", select: "postingEventType" },
]

const assetCatalogItemColumns = [
  { title: "Registration", name: "registration", linkTo: "/asset-catalog-item/{registration}" },
  { title: "Type", name: "type", select: "assetCatalogItemType" },
  { title: "Code", name: "code", select: "assetCatalogItemCode" },
  { title: "Name", name: "name" },
  { title: "Status", name: "status", select: "assetCatalogItemStatus", badge: true },
  { title: "Catalog", name: "catalogName" },
  { title: "Start date", name: "startDate", type: "date" },
  { title: "End date", name: "endDate", type: "date" },
  { title: "Internal reference", name: "internalReference" },
  { title: "External reference", name: "externalReference" },
]

const projectColumns = [
  { title: "Registration", name: "registration", linkTo: "/project/{registration}" },
  { title: "Status", name: "status", select: "projectStatus", badge: true },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Owner", name: "owner.name", linkTo: "/person/{ownerRegistration}" },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "projectType", hidden: true },
  { title: "Client", name: "client", hidden: true },
  { title: "Organization", name: "orgaRegistration", hidden: true },
  { title: "Start date", name: "startDate", type: "date" },
  { title: "End date", name: "endDate", type: "date" },
  { title: "Insert date", name: "_insertDate", type: "date", hidden: true },
  { title: "Update date", name: "_updateDate", type: "date", hidden: true },
  { title: "Update user", name: "_updateUser", hidden: true },
]

const caseColumns = [
  { title: "Registration", name: "registration", linkTo: "/case/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Date", name: "date", type: "date" },
  { title: "Status", name: "status", select: "caseStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "caseType" },
  { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  { title: "Invoice", name: "invoiceRegistration", linkTo: "/invoice/{invoiceRegistration}" },
  { title: "Asset", name: "assetRegistration", linkTo: "/asset/{assetRegistration}" },
  { title: "Asset lot", name: "assetLotRegistration", linkTo: "/asset-lot/{assetLotRegistration}" },
  { title: "Amount", name: "amount", type: "currency" },
  { title: "Score", name: "score" },
  { title: "Last contact", name: "lastContact" },
  { title: "Last action", name: "lastAction" },
]

const assetLotSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 3 }, regex: true },
    { field: "name", colProps: { xs: 12, md: 6 }, regex: true },
    { field: "type", colProps: { xs: 12, md: 3 }, select: "assetLotType" },
    { field: "status", colProps: { xs: 12, md: 6 }, select: "assetLotStatus", multiple: true },
    { field: "internalReference", colProps: { xs: 12, md: 3 } },
    { field: "externalReference", colProps: { xs: 12, md: 3 } },
    { field: "owner", searchEntityName: "Person", query: { role: "OWNER" }, colProps: { xs: 12, md: 4 } },
    { field: "supplier", searchEntityName: "Person", query: { role: "SUPPLIER" }, colProps: { xs: 12, md: 4 } },
    { field: "transporter", searchEntityName: "Person", query: { role: "TRANSPORTER" }, colProps: { xs: 12, md: 4 } },
    { field: "minAmountExclTax", label: "Min. amount (excl. tax)", type: "currency", colProps: { xs: 12, md: 3 } },
    { field: "maxAmountExclTax", label: "Max. amount (excl. tax)", type: "currency", colProps: { xs: 12, md: 3 } },
    { field: "minAmountInclTax", label: "Min. amount (incl. tax)", type: "currency", colProps: { xs: 12, md: 3 } },
    { field: "maxAmountInclTax", label: "Max. amount (incl. tax)", type: "currency", colProps: { xs: 12, md: 3 } },
  ],
]

const assetCatalogItemSearchFields = [
  [
    { field: "type", colProps: { xs: 12, md: 3 }, select: "assetCatalogItemType" },
    { field: "code", colProps: { xs: 12, md: 3 }, select: "assetCatalogItemCode", creatable: true },
    { field: "name", colProps: { xs: 12, md: 6 }, regex: true },
    { field: "status", select: "assetCatalogItemStatus", multiple: true, colProps: { xs: 12, md: 6 } },
    { field: "catalogName", select: "assetCatalogItemCatalog", colProps: { xs: 12, md: 6 } },
    { field: "startDate", type: "date", colProps: { xs: 12, md: 6 } },
    { field: "endDate", type: "date", colProps: { xs: 12, md: 6 } },
    { field: "date", colProps: { xs: 12, md: 4 }, type: "date" },
    { field: "internalReference", colProps: { xs: 12, md: 4 }, regex: true },
    { field: "externalReference", colProps: { xs: 12, md: 4 }, regex: true },
  ],
]

const campaignSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 6 } },
    { field: "name", colProps: { xs: 12, md: 6 } },
  ],
  [
    { field: "status", select: "campaignStatus", multiple: true, colProps: { xs: 12, md: 6 } },
    { field: "type", select: "campaignType", multiple: true, colProps: { xs: 12, md: 6 } },
  ],
]

const taskSearchFields = [
  [
    { field: "subject", regex: true, colProps: { xs: 12, md: 8 } },
    { field: "project" },
    { field: "type", select: "taskType" },
    { field: "profiles", label: "Profiles", type: "multiple", select: "userProfile" },
    { field: "priority", select: "taskPriority" },
    { field: "ownerUsername", select: "/api/core/tasks/userNames?listMode=true" },
    { field: "fromStartDate", type: "date", path: "startDate" },
    { field: "toEndDate", type: "date", path: "endDate" },
    { field: "fromDueDate", type: "date", path: "dueDate" },
    { field: "toDueDate", type: "date", path: "dueDate" },
    { field: "done", type: "checkbox" },
  ],
]

const projectSearchFields = [
  [
    { field: "registration", searchEntityName: "Project", colProps: { xs: 12, md: 6, lg: 3 }, regex: true },
    { field: "name", colProps: { xs: 12, md: 6, lg: 3 }, regex: true },
    { field: "status", colProps: { xs: 12, md: 6, lg: 3 }, select: "projectStatus", multiple: true },
    { field: "type", colProps: { xs: 12, md: 6, lg: 3 }, select: "projectType", multiple: true },
  ],
  [
    { field: "client", colProps: { xs: 12, md: 6, lg: 3 }, searchEntityName: "Person", query: { role: "CLIENT" } },
    { field: "ownerRegistration", colProps: { xs: 12, md: 6, lg: 3 }, searchEntityName: "Person" },
    { field: "clientOwnerRegistration", colProps: { xs: 12, md: 6, lg: 3 }, searchEntityName: "Person" },
    {
      field: "orgaRegistration",
      label: "Organization",
      searchEntityName: "Person",
      query: { type: "O" },
      colProps: { xs: 12, md: 6, lg: 3 },
      regex: true,
    },
  ],
  [{ field: "addressLine", placeholder: "Type project location", regex: true, colProps: { xs: 12 } }],
  [
    { field: "startDate", colProps: { xs: 12, md: 6 }, type: "date", regex: true },
    { field: "endDate", colProps: { xs: 12, md: 6 }, type: "date", regex: true },
  ],
]

const caseSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 4 }, regex: true },
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      actionHidden: true,
      regex: true,
      colProps: { xs: 12, sm: 8 },
    },
    { field: "status", type: "multiple", select: "caseStatus", colProps: { xs: 12, md: 4 } },
    { field: "person", label: "Person registration", colProps: { xs: 12, md: 8 }, regex: true },
    { field: "score", colProps: { xs: 12, md: 4 } },
    { field: "type", type: "multiple", select: "caseType", colProps: { xs: 12, md: 8 } },
    { field: "currency", type: "multiple", select: "currency", colProps: { xs: 12, md: 4 } },
    { field: "supplier", searchEntityName: "Person", query: { role: "SUPPLIER" }, colProps: { xs: 12, md: 6 } },
    { field: "transporter", searchEntityName: "Person", query: { role: "TRANSPORTER" }, colProps: { xs: 12, md: 6 } },
    { field: "fromDate", label: loc`From date`, type: "date", colProps: { xs: 12, sm: 6 } },
    { field: "toDate", label: loc`To date`, type: "date", colProps: { xs: 12, sm: 6 } },
  ],
]

const scriptLogColumns = [
  { title: "Request id", name: "requestId", className: "w-5" },
  { title: "Script name", name: "scriptName", className: "w-10" },
  { title: "Username", name: "username", className: "w-10" },
  { title: "Level", name: "level", className: "w-5", select: "scriptLogLevel" },
  { title: "Insert date", name: "_insertDate", type: "datetime", formInputProps: { timeFormat: "HH:mm:ss.SSS" }, className: "w-10" },
  { title: "Expiration date", name: "expirationDate", type: "datetime", formInputProps: { timeFormat: "HH:mm:ss.SSS" }, className: "w-10" },
  { title: "Output", name: "output", type: "object", className: "w-50" },
]

const scriptLogSearchFields = [
  [
    { field: "scriptName", colProps: { xs: 12, md: 6 } },
    { field: "username", colProps: { xs: 12, md: 6 } },
    { field: "requestId", colProps: { xs: 12, md: 6 } },
    { field: "level", colProps: { xs: 12, md: 6 }, select: "scriptLogLevel" },
  ],
]

const personSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 5, md: 6 }, regex: true },
    { field: "birthDate", colProps: { xs: 12, sm: 3, md: 2 }, type: "date" },
  ],
  [
    { field: "type", type: "multiple", select: "personType", colProps: { xs: 12, sm: 4 } },
    { field: "address", colProps: { xs: 12, sm: 8 } },
  ],
  [
    { field: "role", type: "multiple", select: "personRole", colProps: { xs: 12, sm: 4 } },
    { field: "status", type: "multiple", select: "personStatus", colProps: { xs: 12, sm: 4 } },
    { field: "nationality", type: "multiple", select: "country", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "legalForm", select: "legalForm", colProps: { xs: 12, sm: 4 } },
    { field: "activityCode", select: "activityCode", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "accountingReference", colProps: { xs: 12, sm: 4 } },
    { field: "internalReference", colProps: { xs: 12, sm: 4 } },
    { field: "externalReference", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "mobile", type: "phone", colProps: { xs: 12, sm: 4 } },
    { field: "phone", type: "phone", colProps: { xs: 12, sm: 4 } },
    { field: "email", colProps: { xs: 12, sm: 4 }, regex: { fromStart: true, honorCase: true } },
  ],
]

const communicationSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 6 }, regex: true },
    { field: "status", type: "multiple", select: "communicationStatus", colProps: { xs: 12, sm: 6 } },
    { field: "type", type: "multiple", select: "communicationType", colProps: { xs: 12, sm: 6 } },
    { field: "category", type: "multiple", select: "communicationCategory", colProps: { xs: 12, sm: 6 } },
  ],
  [
    { field: "person", colProps: { xs: 12, md: 6 }, searchEntityName: "Person" },
    { field: "orgaRegistration", label: "Organization", searchEntityName: "Person", query: { type: "O" }, colProps: { xs: 12, md: 6 }, regex: true },
  ],
  [
    {
      field: "contract",
      searchEntityName: "Contract",
      placeholder: "Type to search for a contract",
      regex: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "project",
      searchEntityName: "Project",
      placeholder: "Type to search for a project",
      regex: true,
      colProps: { xs: 12, sm: 4 },
    },
    {
      field: "invoice",
      searchEntityName: "Invoice",
      placeholder: "Type to search for an invoice",
      regex: true,
      colProps: { xs: 12, sm: 4 },
    },
  ],
  [
    { field: "fromDate", label: loc`From date`, type: "date", colProps: { xs: 12, sm: 6 } },
    { field: "toDate", label: loc`To date`, type: "date", colProps: { xs: 12, sm: 6 } },
  ],
]

const communicationColumns = [
  { title: "Registration", name: "registration", linkTo: "/communication/{registration}" },
  { title: "Date", name: "date", type: "datetime" },
  { title: "Status", name: "status", select: "communicationStatus", badge: true },
  { title: "Subject", name: "subject" },
  { title: "Type", name: "type", select: "communicationType" },
  { title: "Category", name: "category", select: "communicationCategory" },
  { title: "Person", name: "persons.0.person.name", linkTo: "/person/{persons.0.personRegistration}" },
  { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  { title: "Invoice", name: "invoiceRegistration", linkTo: "/invoice/{invoiceRegistration}" },
]

const maintenanceColumns = [
  { title: "Registration", name: "registration", linkTo: "/maintenance/{registration}" },
  { title: "Organization", name: "organization", linkTo: "/person/{orgaRegistration}" },
  { title: "Asset", name: "items.0.assetRegistration", linkTo: "/asset/{items.0.assetRegistration}" },
  { title: "Status", name: "status", select: "maintenanceStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "maintenanceType" },
  { title: "Supplier", name: "supplier", linkTo: "/person/{supplier}" },
  { title: "Submit date", name: "submitDate", type: "date" },
  { title: "Return date", name: "returnDate", type: "date" },
  { title: "Validation date", name: "validationDate", type: "date" },
  { title: "Amount (excl. tax)", name: "amountExclTax", type: "currency" },
  { title: "Amount (incl. tax)", name: "amountInclTax", type: "currency" },
]

const maintenanceFields = [{ name: "supplier", path: "persons[role=SUPPLIER].personRegistration" }]

const maintenanceSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "status", type: "multiple", select: "maintenanceStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "maintenanceType", colProps: { xs: 12, sm: 4 } },
  ],
  [
    { field: "supplier", colProps: { xs: 12, md: 6 }, searchEntityName: "Person" },
    { field: "orgaRegistration", label: "Organization", searchEntityName: "Person", query: { type: "O" }, colProps: { xs: 12, md: 6 }, regex: true },
  ],
  [
    { field: "submitDate", label: loc`Submit date`, type: "date", colProps: { xs: 12, sm: 4 } },
    { field: "returnDate", label: loc`Return date`, type: "date", colProps: { xs: 12, sm: 4 } },
    { field: "validationDate", label: loc`Validation date`, type: "date", colProps: { xs: 12, sm: 4 } },
  ],
]

const collateralSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, md: 6, lg: 3 }, regex: true },
    { field: "name", colProps: { xs: 12, md: 6, lg: 3 }, regex: true },
    { field: "status", colProps: { xs: 12, md: 6, lg: 3 }, select: "collateralStatus", type: "multiple" },
    { field: "type", colProps: { xs: 12, md: 6, lg: 3 }, select: "collateralType", type: "multiple" },
  ],
]

const privateEquityAssetSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "contract", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", select: "postingStatus", colProps: { xs: 12, sm: 4 } },
    { field: "person", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "account", type: "multiple", select: "ledgerAccount", colProps: { xs: 12, sm: 4 } },
    { field: "invoice", colProps: { xs: 12, sm: 8 } },
  ],
]

const questionSearchFields = [
  [
    { field: "subject", colProps: { xs: 12 }, regex: true },
    { label: "Author", field: "_insertUser", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "status", type: "multiple", select: "questionStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", select: "questionType", colProps: { xs: 12, sm: 4 } },
    { field: "keywords", type: "multiple", select: "questionKeyword", colProps: { xs: 12, sm: 8 } },
    { field: "locale", select: "locale", colProps: { xs: 12, sm: 4 } },
  ],
]

const scoreSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 8 }, regex: true },
  ],
  [
    { field: "status", type: "multiple", label: loc`Status`, select: "scoreStatus", colProps: { xs: 12, sm: 4 } },
    { field: "type", type: "multiple", label: loc`Type`, select: "scoreType", colProps: { xs: 12, sm: 4 } },
  ],
]

const bankAccountSearchFields = [
  [
    { field: "registration", colProps: { xs: 12, sm: 4 }, regex: true },
    { field: "name", colProps: { xs: 12, sm: 4 }, regex: true },
  ],
  [{ field: "status", type: "multiple", select: "bankAccountStatus", colProps: { xs: 12, sm: 8 } }],
]

const featureFlagSearchFields = [
  [
    { field: "name", colProps: { xs: 12, md: 6 }, regex: true },
    { field: "status", type: "multiple", select: "featureFlagStatus", colProps: { xs: 12, md: 3 } },
    { field: "type", type: "multiple", select: "featureFlagType", colProps: { xs: 12, md: 3 } },
  ],
]

const collateralColumns = [
  { title: "Registration", name: "registration", linkTo: "/collateral/{registration}" },
  { title: "Status", name: "status", badge: true, select: "collateralStatus" },
  { title: "Name", name: "name" },
  { title: "Description", name: "description" },
  { title: "Start date", name: "startDate", type: "date" },
  { title: "End date", name: "endDate", type: "date" },
  { title: "Amount", name: "amount", type: "currency" },
  { title: "Percentage", name: "percentage" },
  { title: "Person registration", name: "personRegistration" },
  { title: "Type", name: "type", select: "collateralType" },
]

const contractColumns = [
  { title: "Registration", name: "registration", linkTo: "/contract/{registration}" },
  { title: "Status", name: "status", badge: true, select: "contractStatus" },
  { title: "Name", name: "name" },
  { title: "Product", name: "financialProduct", field: "quotations[0].financialProduct", select: "financialProduct" },
  { title: "Date", name: "startDate", field: "quotations[0].startDate", type: "date" },
  {
    title: "Amount",
    name: "financedAmountExclTax",
    field: "quotations[0].financedAmountExclTax",
    type: "currency",
    currencyPath: "persons[0].invoicing.principalCurrency",
  },
]

const agreementColumns = [
  { title: "Registration", name: "registration", linkTo: "/agreement/{registration}" },
  { title: "Status", name: "status", badge: true, select: "agreementStatus" },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "agreementType" },
]

const invoiceColumns = [
  { title: "Registration", name: "registration", linkTo: "/invoice/{registration}" },
  { title: "Status", name: "status", badge: true, select: "invoiceStatus" },
  { title: "Name", name: "name" },
  { title: "Contract", name: "contractRegistration", linkTo: "/contract/{contractRegistration}" },
  {
    title: "Payer",
    name: "payerRegistration",
    field: "name",
    findIn: "persons",
    findKey: "role",
    findValue: "PAYER",
    foundKey: "personRegistration",
    linkTo: "/person/{personRegistration}",
  },
  {
    title: "Payee",
    name: "payeeRegistration",
    field: "name",
    findIn: "persons",
    findKey: "role",
    findValue: "PAYEE",
    foundKey: "personRegistration",
    linkTo: "/person/{personRegistration}",
  },
  { title: "Amount", name: "amountExclTax", type: "currency", currencyPath: "currency" },
]

const personColumns = [
  { title: "Registration", name: "registration", linkTo: "/person/{registration}" },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "personType" },
  { title: "Address", name: "addresses[0].addressLine" },
]

const prospectColumns = [
  { title: "Registration", name: "registration", linkTo: "/prospect/{registration}" },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "personType" },
  { title: "Address", name: "addresses[0].addressLine" },
]

const creditLineColumns = [
  { title: "Registration", name: "registration", linkTo: "/credit-line/{registration}" },
  { title: "Status", name: "status", badge: true, select: "creditLineStatus" },
  { title: "Name", name: "name" },
  { title: "Limit", name: "creditLimit", type: "currency", currencyPath: "currency" },
  { title: "Outstanding", name: "outstanding", type: "currency", currencyPath: "currency" },
  { title: "Available", name: "available", type: "currency", currencyPath: "currency" },
]

const assetColumns = [
  { title: "Registration", name: "registration", linkTo: "/asset/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Status", name: "status", badge: true, select: "assetStatus" },
  { title: "Name", name: "name" },
  { title: "Identification number", name: "identificationNumber", hidden: true },
  { title: "Registration book number", name: "registrationBookNumber", hidden: true },
  { title: "Price", name: "priceExclTax", type: "currency", currencyPath: "currency", excelFormat: "currency" },
  { title: "Incl. tax", name: "priceInclTax", type: "currency", currencyPath: "currency", excelFormat: "currency" },
  { title: "Type", name: "type", select: "assetType" },
]

const productColumns = [
  { title: "Registration", name: "registration", linkTo: "/product/{registration}" },
  { title: "Name", name: "name" },
  { title: "Code", name: "code" },
  { title: "Status", name: "status", badge: true, select: "productStatus" },
]

const committeeColumns = [
  { title: "Registration", name: "registration", linkTo: "/committee/{registration}" },
  { title: "Name", name: "name" },
  { title: "Code", name: "code" },
  { title: "Status", name: "status", badge: true, select: "committeeStatus" },
]

const fundColumns = [
  { title: "Registration", name: "registration", linkTo: "/fund/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Status", name: "status", select: "fundStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "fundType" },
  { title: "Financing type", name: "financingType", select: "fundFinancingType" },
  { title: "Financing nature", name: "financingNature", select: "fundFinancingNature" },
  { title: "Financing finality", name: "financingFinality", select: "fundFinancingFinality" },
  { title: "Management type", name: "managementType", select: "managementType" },
  { title: "Credit limit", name: "creditLimit", type: "currency", excelFormat: "currency" },
  { title: "Outstanding", name: "outstanding", type: "currency", excelFormat: "currency" },
  { title: "Available", name: "available", type: "currency", excelFormat: "currency" },
]

const assetLotColumns = [
  { title: "Registration", name: "registration", linkTo: "/asset-lot/{registration}" },
  { title: "Name", name: "name" },
  { title: "Status", name: "status", badge: true, select: "assetLotStatus" },
]

const privateEquityAssetColumns = [
  { title: "Registration", name: "registration", linkTo: "/private-equity-asset/{registration}" },
  { title: "Status", name: "status", select: "privateEquityAssetStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Role", name: "role" },
  { title: "Type", name: "type", select: "privateEquityAssetType" },
  { title: "Address", name: "address" },
]

const questionColumns = [
  { title: "Registration", name: "registration", linkTo: "/question/{registration}" },
  { title: "Subject", name: "subject" },
  { title: "Status", name: "status", select: "questionStatus", badge: true },
  { title: "Type", name: "type", select: "questionType" },
  { title: "Locale", name: "locale" },
  { title: "Author", name: "_insertUser" },
  { title: "Keywords", name: "keywords", select: "questionKeyword", formInputProps: { multiple: true, displayMode: "raw" } },
]

const scoreColumns = [
  { title: "Registration", name: "registration", linkTo: "/score/{registration}" },
  { title: "Organization", name: "organization.name", hidden: true },
  { title: "Status", name: "status", select: "scoreStatus", badge: true },
  { title: "Name", name: "name" },
  { title: "Type", name: "type", select: "scoreType" },
]

const featureFlagColumns = [
  { title: "Name", name: "name", className: "w-20", linkTo: "/feature-flag/{name}" },
  { title: "Status", name: "status", select: "featureFlagStatus", className: "w-20", badge: true },
  { title: "Type", name: "type", select: "featureFlagType" },
]

const userColumns = [
  { title: "Username", name: "username" },
  { title: "Name", name: "name" },
  { title: "Email", name: "email" },
  { title: "Profiles", name: "profiles" },
  { title: "Locale", name: "locale", select: "locale" },
  { title: "Person", name: "person", linkTo: "/person/{personRegistration}" },
  { title: "Partner", name: "partner", linkTo: "/person/{partnerRegistration}" },
  { title: "Organization", name: "organization", linkTo: "/person/{orgaRegistration}" },
]

export async function handleEntitiesPageRowClick(entityName, component, row) {
  const { selectedEntities } = component.state

  const rowId = row?.id || row?.registration
  if (rowId) {
    if (selectedEntities.has(rowId)) selectedEntities.delete(rowId)
    else selectedEntities.add(rowId)
  }

  if (!selectedEntities.size) return component.setState({ transitions: [], selectedEntities })

  component.setState({ transitionsLoading: true })

  const transitions = await getEntitiesTransitions(entityName, Array.from(selectedEntities))

  component.setState({ transitions, selectedEntities, transitionsLoading: false })
}

export async function handleEntitiesPageSelectAll(entityName, component, selectedEntities) {
  if (!selectedEntities.size) return component.setState({ transitions: [], selectedEntities })

  component.setState({ transitionsLoading: true })

  const transitions = await getEntitiesTransitions(entityName, Array.from(selectedEntities))

  component.setState({ transitions, selectedEntities, transitionsLoading: false })
}

async function getEntitiesTransitions(entityName, entityRegistrations) {
  try {
    const serverRoute = getEntityServerRoute(entityName)
    const _transitions = (await axios.post(`${serverRoute}/transitions?exclude=modals,printings,documents`, entityRegistrations)).data

    return _transitions
      .map((tr, index) => {
        // invert pullRight order to respect workflow card order
        tr.orderIndex = tr.props?.pullRight ? 1000 - index : index
        return tr
      })
      .sort((tr1, tr2) => tr1.orderIndex - tr2.orderIndex)
  } catch (error) {
    addOops(error)
  }

  return []
}

export function setEntityFieldProps(entity, fieldProps, { isWorkflowTransitionError } = {}) {
  for (const key in fieldProps) {
    const props = fieldProps[key]
    if (key === "undefined") addOops(`undefined key found in "fieldProps"`)
    else {
      if (isWorkflowTransitionError) props.isWorkflowTransitionError = true
      set(entity, key, props)
    }
  }
}

/**
 * Creates a function for entities based on the configured columns.
 * This function detects if entity rows match hidden columns, allowing a message to be displayed to users to avoid confusing them.
 *
 * @returns {Function} - A function that map entities based on the provided filter, taking into account
 *                       different column types (e.g., currency, percentage, date, bytes and milliseconds)
 *                       and formats the values accordingly before applying the map.
 */
export function getEntityMapFunction(columns) {
  const locale = getLocale()

  return (entities = [], filter, options) => {
    const { isServerSearch } = options || {}
    if (!filter) return { entities }

    let hasSearchResultsOnHiddenFields = false
    const includes = getFilterFunction(filter, locale)
    const mappedEntities = entities.filter(entity => {
      for (const column of columns) {
        const fieldName = column?.filterFunctionField || column?.name || column
        if (!fieldName) {
          if (isServerSearch) {
            hasSearchResultsOnHiddenFields = true
            return true
          }

          continue
        }

        const value = get(entity, fieldName)
        if (!value) {
          if (isServerSearch) {
            hasSearchResultsOnHiddenFields = true
            return true
          }

          continue
        }

        let formattedValue = value
        if (column.select) formattedValue = getLabel(column.select, value)
        else if (column.type === "currency") {
          if (includes(value)) return true // Support raw numbers also (€1,000 <> 1000)

          let currency = column.currency
          if (!currency && column.currencyPath) currency = get(entity, column.currencyPath)

          formattedValue = formatCurrency(value, locale, { currency })
        } else if (column.type === "percentage") {
          formattedValue = formatPercentage(value, locale)
        } else if (["date", "datetime"].includes(column.type)) {
          if (includes(value)) return true // Support raw dates also
          formattedValue = column.type === "date" ? formatDate(value, locale) : formatDateTime(value, locale)
        } else if (column.type === "bytes") {
          formattedValue = formatBytes(value)
        } else if (column.type === "milliseconds") {
          formattedValue = formatMilliseconds(value)
        }

        if (includes(formattedValue)) return true
      }

      hasSearchResultsOnHiddenFields = isServerSearch
      return isServerSearch
    })

    return { entities: mappedEntities, hasSearchResultsOnHiddenFields }
  }
}
