export default {
  "useLongScale": false,
  "baseSeparator": " ",
  "unitSeparator": "",
  "base": {
    "0": "zero",
    "1": "vienas",
    "2": "du",
    "3": "trys",
    "4": "keturi",
    "5": "penki",
    "6": "šeši",
    "7": "septyni",
    "8": "aštuoni",
    "9": "devyni",
    "10": "dešimt",
    "11": "vienuolika",
    "12": "dvylika",
    "13": "trylika",
    "14": "keturiolika",
    "15": "penkiolika",
    "16": "šešiolika",
    "17": "deptyniolika",
    "18": "aštoniolika",
    "19": "devyniolika",
    "20": "dvidešimt",
    "30": "trisdešimt",
    "40": "keturiasdešimt",
    "50": "penkiasdešimt",
    "60": "šešiasdešimt",
    "70": "deptyniasdešimt",
    "80": "aštuoniasdešimt",
    "90": "devyniasdešimt"
  },
  "units": [
    {
      "singular": "šimtas",
      "plural": "šimtai"
    },
    {
      "singular": "tūkstantis",
      "plural": "tūkstančiai",
      "useAlternativeUnit": [
        10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 30, 40, 50, 60, 70, 80, 90,
        110, 111, 112, 113, 114, 115, 116, 117, 118, 119, 120, 130, 140, 150, 160, 170, 180, 190,
        210, 211, 212, 213, 214, 215, 216, 217, 218, 219, 220, 230, 240, 250, 260, 270, 280, 290,
        310, 311, 312, 313, 314, 315, 316, 317, 318, 319, 320, 330, 340, 350, 360, 370, 380, 390,
        410, 411, 412, 413, 414, 415, 416, 417, 418, 419, 420, 430, 440, 450, 460, 470, 480, 490,
        510, 511, 512, 513, 514, 515, 516, 517, 518, 519, 520, 530, 540, 550, 560, 570, 580, 590,
        610, 611, 612, 613, 614, 615, 616, 617, 618, 619, 620, 630, 640, 650, 660, 670, 680, 690,
        710, 711, 712, 713, 714, 715, 716, 717, 718, 719, 720, 730, 740, 750, 760, 770, 780, 790,
        810, 811, 812, 813, 814, 815, 816, 817, 818, 819, 820, 830, 840, 850, 860, 870, 880, 890,
        910, 911, 912, 913, 914, 915, 916, 917, 918, 919, 920, 930, 940, 950, 960, 970, 980, 990,
      ],
      "alternativeUnit": "tūkstančių",
      "useSingularEnding": true
    },
    {
      "singular": "milijonas",
      "plural": "milionai"
    }
  ],
  "unitExceptions": [],
  "currencies": {
    "EUR": {
      "singular": "eurų",
      "plural": "eurai",
      "cents": "euro ct."
    }
  }
}